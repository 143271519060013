import { del, get, post, put } from "../client";
import {
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_EMPLOYEES,
  UPDATE_EMPLOYEE,
} from "../routes";

export default {
  get: async (id) => await get(GET_EMPLOYEES(id)),

  update: async (data) => await put(UPDATE_EMPLOYEE, data),

  create: async (data) => await post(CREATE_EMPLOYEE, data),

  del: async (id) => await del(DELETE_EMPLOYEE(id)),
};

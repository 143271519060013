export const generateFirstLetterBox = (name) => {
  // Function to get the first letter or a fallback character
  const getFirstLetter = (name) => {
    if (name) {
      return name.charAt(0);  // Simply return the first character
    }
    return '?'; // Fallback character
  };

  const firstLetter = getFirstLetter(name);

  // Styling for the div
  const divStyle = {
    background: "#DE1468",
    color: "white",
    borderRadius: "9px",
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: '32px'
  };

  return (
    <div style={divStyle}>
      {firstLetter}
    </div>
  );
};

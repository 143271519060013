import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/he"; 
import { calculateEndTimeOfAppointment } from "../../utils/calculateEndTimeOfAppointment";
import AppointmentDetailsModal from "../../components/appointmentDetailsModal";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format } from "date-fns";
import { ClockIcon1Svg } from "../../svgs/clockIcon1Svg";
import { UserIcon1Svg } from "../../svgs/userIcon1Svg";
import * as dates from "../../utils/dates";
import CreateAppointmentModal from "../../components/createAppointmentModal";
import styles from "../../styles/_containers/_appointments/bigCalendar.module.scss";

moment.locale("he");

const localizer = momentLocalizer(moment, {
  formats: {
    timeGutterFormat: "HH:mm", // Format for time gutter
    dayHeaderFormat: (date, culture, localizer) =>
      localizer.format(date, "dddd", culture), // Translate day headers
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, "D", culture)} - ${localizer.format(
        end,
        "D",
        culture
      )}`,
  },
});

const BigCalendar = ({ appointments, setSuccess }) => {
  const clickRef = useRef(null);

  useEffect(() => {
    return () => {
      window.clearTimeout(clickRef?.current);
    };
  }, []);

  const [currentView, setCurrentView] = useState("month");

  const [isModalOneOpen, setIsModalOneOpen] = useState(null);

  const [isModalTwoOpen, setIsModalTwoOpen] = useState(null);

  const [selected, setSelected] = useState();

  const calculateTime = (date, slot) => {
    let inputDate = date;
    const [hours, minutes] = slot.split(":").map(Number);
    inputDate.setHours(hours);
    inputDate.setMinutes(minutes);

    return inputDate;
  };

  let events = appointments?.map((i) => {
    return {
      id: i._id,
      title: i.serviceId?.name,
      start: calculateTime(new Date(i.date), i.timeSlot),
      end: calculateTime(
        new Date(i.date),
        calculateEndTimeOfAppointment(i.timeSlot, i.serviceId?.time)
      ),
      phone: i.userId?.phone,
      userId: i.userId?._id,
      customerName: i.userId?.name,
      type: i.serviceId?.type,
      createdAt: i.createdAt,
    };
  });

  // HANDLE EVENT CLICK
  const handleSelectEvent = (e) => {
    setIsModalOneOpen(e);
  };

  // HANDLE EMPTY SLOT CLICK
  const onSelectSlot = useCallback((slotInfo) => {
    console.log(slotInfo, "SLOT INFO")
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      setSelected(slotInfo);
      setIsModalTwoOpen(slotInfo);
    }, 250);
  }, []);

  const components = useMemo(
    () => ({
      event: CustomEvent,
      eventWrapper: MyEventWrapper,
      eventContainerWrapper: MyEventContainerWrapper,
      localizer: format(new Date(), "HH:mm"),
      day: {
        event: CustomEvent,
      },
      week: {
        event: CustomEvent,
      },
      month: {
        event: CustomEvent,
      },
    }),
    []
  );

  const handleOnShowMore = (e) => {
    console.log(e);
  };

  const defaultDate = useMemo(() => new Date(), []);

  const handleMonthChange = (date) => {
    // This function will be called when the month changes
    console.log('Month changed to', date);
    // You can perform your desired action here
  };

  return (
    <div className={styles.container}>
      <Calendar
        defaultDate={defaultDate}
        localizer={localizer}
        messages={{
          allDay: "כל היום",
          previous: "<",
          next: ">",
          today: "היום",
          month: "חודש",
          week: "שבוע",
          day: "יום",
          agenda: "סדר היום",
        }}
        formats={{
          timeGutterFormat: (date, culture, localizer) =>
            localizer.format(date, "HH:mm", culture),
        }}
        onView={(view) => setCurrentView(view)}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 950 }}
        selectable={true}
        onSelectSlot={onSelectSlot}
        onSelectEvent={handleSelectEvent}
        components={components}
        onShowMore={handleOnShowMore}
        step={30}
        showAllEvents={true}
        selected={selected}
        onNavigate={handleMonthChange}
      />

      {isModalOneOpen && (
        <AppointmentDetailsModal
          data={isModalOneOpen}
          setIsOpen={setIsModalOneOpen}
          setSuccess={setSuccess}
        />
      )}

      {isModalTwoOpen && (
        <CreateAppointmentModal
          data={isModalTwoOpen}
          setIsOpen={setIsModalTwoOpen}
          setSuccess={setSuccess}
          currentView={currentView}
        />
      )}
    </div>
  );
};

export default BigCalendar;

// MONTH EVENT CONTAINER WRAPPER
const MyEventContainerWrapper = ({ event, children }) => {
  return (
    <div
      className={styles.customEventContainerWrapper}
      style={{ border: "5px solid black", padding: "0px" }}
    >
      {children}
    </div>
  );
};

// MONTH EVENT WRAPPER
const MyEventWrapper = ({ event, children }) => {
  return (
    <div
      className={styles.customEventWrapper}
      style={{ border: "0px", padding: "0px" }}
    >
      {children}
    </div>
  );
};

// MONTH CUSTOM EVENT
const CustomEvent = ({ event }) => {
  return (
    <div
      className={styles.customEvent}
      style={{
        backgroundColor: event.type === "CLASS" ? "#DE1468" : "",
      }}
    >
      <h4 className={styles.title}>{event.title}</h4>

      <div className={styles.hours}>
        <p>{format(new Date(event.end), "HH:mm")}</p>
        <span>-</span>
        <p>{format(new Date(event.start), "HH:mm")}</p>
        <ClockIcon1Svg fill="#fff" />
      </div>
      <div className={styles.customer}>
        <p>{event.customerName}</p>
        <UserIcon1Svg fill="#fff" />
      </div>
    </div>
  );
};

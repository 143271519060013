import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { paths } from "../routes/paths";
import APPOINTMENTS from "../api/services/APPOINTMENTS";
import BigCalendar from "../containers/_appointments/bigCalendar";
import Layout from "../layouts/layout";
import SideMenu from "../components/sideMenu";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import { CalendarIcon1Svg } from "../svgs/calendarIcon1Svg";
import { ClockIcon1Svg } from "../svgs/clockIcon1Svg";
import { SettingsIcon1Svg } from "../svgs/settingsIcon1Svg";
import SectionOne from "../containers/_appointments/sectionOne";
import SectionTwo from "../containers/_appointments/sectionTwo";
import CommentModal from "../components/commentModal";
import { ListIconSvg } from "../svgs/listIconSvg";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
import { ConfirmModal } from "../components/confirmModal";
import EMPLOYEES from "../api/services/EMPLOYEES";
import styles from "../styles/_screens/appointments.module.scss";

const Appointments = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  let user_id = localStorage.getItem("user_id");
  user_id = JSON.parse(user_id);

  let user_type = localStorage.getItem("user_type");
  user_type = JSON.parse(user_type);

  const [view, setView] = useState(1);

  const [appointments, setAppointments] = useState(null);

  const [appointmentsLoading, setAppointmentsLoading] = useState(false);

  const [appointmentsByDate, setAppointmentsByDate] = useState(null);

  const [appointmentsByDateLoading, setAppointmentsByDateLoading] =
    useState(false);

  const [success, setSuccess] = useState(null);

  const [dateValue, setDateValue] = useState(new Date());

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("all");

  // redirect if token does not exists
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  // ========= FETCH EMPLOYEES =========
  useEffect(() => {
    setSuccess(null);
    const fetchData = async () => {
      try {
        const res = await EMPLOYEES.get(user_id);
        setEmployees(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // ========= FETCH APPOINTMENTS =========
  useEffect(() => {
    setSuccess(null);
    const fetchData = async () => {
      setAppointmentsLoading(true);
      try {
        const payload = {
          date: format(new Date(), "yyyy-MM-dd"),
        };
        if (employee === "all") {
          payload.id = user_id;
        }
        if (employee && employee !== "all") {
          payload.id = employee;
        }
        const res = await APPOINTMENTS.get_appointments(payload);

        let arr = [];

        for (let index = 0; index < res.data.data.length; index++) {
          const element = res.data.data[index];
          arr = [...arr, ...element.appoitnemts];
        }

        setAppointments(arr);

      } catch (error) {
        console.log(error);
      }
      setAppointmentsLoading(false);
    };

    fetchData();
  }, [success, employee]);

  // ========= FETCH APPOINTMENTS BY DATE =========
  useEffect(() => {
    setSuccess(null);
    const fetchData = async () => {
      setAppointmentsByDateLoading(true);
      try {
        const payload = {
          id: user_id,
          date: format(dateValue, "yyyy-MM-dd"),
          // size: 200,
          // page: 1,
        };
        const res = await APPOINTMENTS.get_appointments(payload);

        let appt = res.data.data.filter((i) => i.id === user_id);

        let currentDateTime = new Date().toISOString();

        appt[0]?.appoitnemts?.sort((a, b) => {
          let timeDifferenceA = Math.abs(
            new Date(a.combinedDateTime) - new Date(currentDateTime)
          );
          let timeDifferenceB = Math.abs(
            new Date(b.combinedDateTime) - new Date(currentDateTime)
          );

          return timeDifferenceA - timeDifferenceB;
        });

        setAppointmentsByDate(appt[0].appoitnemts);
      } catch (error) {
        console.log(error);
      }
      setAppointmentsByDateLoading(false);
    };

    fetchData();
  }, [success, dateValue]);

  const sideMenuList = [
    {
      id: 1,
      svg: <AddPlusSvg fill="#000000" />,
      text: "הוספת תור חדש",
      active: false,
      onClick: () => navigate(paths.createAppointment),
    },
    {
      id: 2,
      svg: <CalendarIcon1Svg fill="#DE1468" />,
      text: "יומן שבועי",
      active: true,
    },
    {
      id: 3,
      svg: <SettingsIcon1Svg fill="#000000" />,
      text: "הגדרות יומן ותורים",
      active: false,
      onClick: () => navigate(paths.settings),
    },
    {
      id: 4,
      svg: <ClockIcon1Svg fill="#000000" />,
      text: "הגדרות קבלת קהל",
      active: false,
      onClick: () => navigate(paths.workingHours(user_id)),
    },
  ];

  // CANCEL APPOINTMENT
  const cancelAppointment = async () => {
    let payload = {
      id: isConfirmModalOpen,
      status: 3,
    };
    setConfirmModalLoading(true);
    try {
      const res = await APPOINTMENTS.cancel_appointment(payload);
      setSuccess(true);
      setIsConfirmModalOpen(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.messsage);
    }
    setConfirmModalLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.toggleView}>
          <div className={styles.box} onClick={() => setView(0)}>
            <CalendarIcon1Svg fill={view === 0 ? "#DE1468" : "#000000"} />
            <p style={{ color: view === 0 ? "#DE1468" : "#000000" }}>
              תצוגת לוח שנה
            </p>
          </div>
          <div className={styles.box} onClick={() => setView(1)}>
            <ListIconSvg fill={view === 1 ? "#DE1468" : "#000000"} />
            <p style={{ color: view === 1 ? "#DE1468" : "#000000" }}>
              תצוגה חכמה
            </p>
          </div>
        </div>

        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.dropdown}>
            <select
              name="employee"
              value={employee}
              onChange={(e) => setEmployee(e.target.value)}
            >
              <option value="" disabled selected>
                Select an Employee
              </option>
              <option value="all">All</option>
              {employees.map((i) => (
                <option value={i.id}>{i.name}</option>
              ))}
            </select>
          </div>

          {view === 1 ? (
            <div className={styles.sections}>
              {/* SECTION 1 */}
              <SectionOne
                appointments={appointments}
                appointmentsLoading={appointmentsLoading}
                setIsCommentModalOpen={setIsCommentModalOpen}
                setIsConfirmModalOpen={setIsConfirmModalOpen}
                user_id={user_id}
              />

              {/* SECTION 2 */}
              <SectionTwo
                dateValue={dateValue}
                setDateValue={setDateValue}
                appointmentsByDate={appointmentsByDate}
                appointmentsByDateLoading={appointmentsByDateLoading}
                setIsCommentModalOpen={setIsCommentModalOpen}
                setIsConfirmModalOpen={setIsConfirmModalOpen}
              />
            </div>
          ) : (
            // BIG CALENDAR
            <BigCalendar appointments={appointments} setSuccess={setSuccess} />
          )}
        </div>

        {/* MODALS */}
        {isCommentModalOpen && (
          <CommentModal
            setIsOpen={setIsCommentModalOpen}
            appointmentId={isCommentModalOpen}
          />
        )}

        {isConfirmModalOpen && (
          <ConfirmModal
            setIsOpen={setIsConfirmModalOpen}
            handleClick={cancelAppointment}
            loading={confirmModalLoading}
          />
        )}
      </div>
    </Layout>
  );
};

export default Appointments;

export const BubbleIconSvg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_61_5314)">
        <mask
          id="mask0_61_5314"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white" />
        </mask>
        <g mask="url(#mask0_61_5314)">
          <path
            d="M10 0.781251C4.89676 0.781251 0.732578 4.97832 0.78168 10.0905L0.78125 19.2187L0.934063 19.0659C2.22605 17.7739 4.2034 17.4334 5.83691 18.2521C7.07324 18.8717 8.47223 19.2195 9.95883 19.2187C15.0333 19.2211 19.1696 15.1648 19.2183 10.0905C19.2674 4.97832 15.1855 0.781251 10 0.781251Z"
            stroke={fill}
            stroke-width="1.5625"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.65625 10C7.65625 10.5393 7.21902 10.9766 6.67969 10.9766C6.14035 10.9766 5.70312 10.5393 5.70312 10C5.70312 9.46066 6.14035 9.02344 6.67969 9.02344C7.21902 9.02344 7.65625 9.46066 7.65625 10Z"
            fill={fill}
          />
          <path
            d="M11.0156 10C11.0156 10.5393 10.5784 10.9766 10.0391 10.9766C9.49973 10.9766 9.0625 10.5393 9.0625 10C9.0625 9.46066 9.49973 9.02344 10.0391 9.02344C10.5784 9.02344 11.0156 9.46066 11.0156 10Z"
            fill={fill}
          />
          <path
            d="M14.375 10C14.375 10.5393 13.9378 10.9766 13.3984 10.9766C12.8591 10.9766 12.4219 10.5393 12.4219 10C12.4219 9.46066 12.8591 9.02344 13.3984 9.02344C13.9378 9.02344 14.375 9.46066 14.375 10Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_61_5314">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { generateFirstLetterBox } from "../../utils/generateFirstLetterBox";
import styles from "../../styles/_containers/_employees/items.module.scss";

const Items = ({
  data,
  setIsOpen,
  setSelectedEmployee,
  setIsConfirmModalOpen,
}) => {
  return (
    <div className={styles.container}>
      {data.map((i) => (
        <Employee
          key={i.id}
          id={i.id}
          name={i.name}
          description={i.description}
          employee={i}
          setSelectedEmployee={setSelectedEmployee}
          setIsOpen={setIsOpen}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
        />
      ))}
    </div>
  );
};

export default Items;

const Employee = ({
  id,
  name,
  description,
  employee,
  setSelectedEmployee,
  setIsOpen,
  setIsConfirmModalOpen,
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.right}>
        {generateFirstLetterBox(name)}

        <div className={styles.name}>
          <h4>{name}</h4>
          <p>{description}</p>
        </div>

        {/* <div className={styles.role}>
          <p>תפקיד: מסירת שער בליזר</p>
        </div> */}
      </div>

      <div className={styles.left}>
        <div className={styles.actions}>
          <div
            className={styles.block}
            onClick={() => {
              setIsOpen("edit");
              setSelectedEmployee(employee);
            }}
          >
            <img src="/assets/edit-icon-1.svg" alt="edit" />
            <p>עריכה</p>
          </div>
          <div
            className={styles.block}
            onClick={() => setIsConfirmModalOpen(id)}
          >
            <img src="/assets/remove-icon-1.svg" alt="remove" />
            <p>מחיקה</p>
          </div>
        </div>
      </div>
      {/* <div className={styles.tag}>
        <p>עובד חדש!</p>
      </div> */}
    </div>
  );
};

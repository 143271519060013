export const DiscordIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_5138)">
        <mask
          id="mask0_61_5138"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="21"
          height="20"
        >
          <path
            d="M-0.00244141 1.90735e-06H19.4976V19.5H-0.00244141V1.90735e-06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_61_5138)">
          <path
            d="M14.3555 16.8338L16.2577 18.5077C16.8459 19.0371 17.7832 18.6197 17.7832 17.8283V4.57013C17.7832 2.46668 16.0781 0.761539 13.9746 0.761539H5.51953C3.41608 0.761539 1.71094 2.46668 1.71094 4.57013V13.0252C1.71094 15.1287 3.41608 16.8338 5.51953 16.8338H11.3848"
            stroke={fill}
            strokeWidth="1.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.9463 10.7012C12.9463 10.7012 11.783 12.5293 9.74707 12.5293C7.71119 12.5293 6.54785 10.7012 6.54785 10.7012"
            stroke={fill}
            strokeWidth="1.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.7178 5.21777V7.3125"
            stroke={fill}
            strokeWidth="1.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.81445 5.21777V7.3125"
            stroke={fill}
            strokeWidth="1.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_61_5138">
          <rect width="19.5" height="19.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

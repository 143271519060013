import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { CalendarIcon1Svg } from "../svgs/calendarIcon1Svg";
import { ManagementIconSvg } from "../svgs/managementIconSvg";
import { DiscordIcon1Svg } from "../svgs/discordIcon1Svg";
import { RemindersIconSvg } from "../svgs/remindersIconSvg";
import { ServicesIconSvg } from "../svgs/servicesIconSvg";
import { EmployeesIconSvg } from "../svgs/employeesIconSvg";
import { StatsIconSvg } from "../svgs/statsIconSvg";
import { HelpIconSvg } from "../svgs/helpIconSvg";
import PROFILE from "../api/services/PROFILE";
import { BASE_URL } from "../config";
import { Helmet } from "react-helmet";
import styles from "../styles/_layouts/header.module.scss";
import SUBSCRIPTION from "../api/services/SUBSCRIPTION";

const Header = ({ setIsOpen }) => {
  const [businessImg, setBusinessImg] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [pro, setPro] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let path = useLocation();
  path = path.pathname;

  const navigate = useNavigate();

  // FETCH PROFILE DETAILS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await PROFILE.get();
        let busImg =
          res.data.profile &&
          (res.data.profile.includes("https://")
            ? res.data.profile
            : `${BASE_URL}/uploads/${res.data.profile}`);
        setBusinessImg(busImg);
        setBusinessName(res.data.business_name);
      } catch (error) {
        console.log(error);
        if (
          error.response.data.message === "Invalid Token" ||
          error.response.data.message ===
            "A token is required for authentication"
        ) {
          localStorage.clear();
          navigate(paths.login);
        }
      }
    };

    fetchData();
  }, []);

  // CHECK SUBSCRIPTION
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await SUBSCRIPTION.check_subscription();
  //       if (res.isHavingPlan) {
  //         setPro(true);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const activeState = {
    management: path === paths.management ? true : false,
    appointments: path === paths.appointments ? true : false,
    clients: path === paths.clients ? true : false,
    notifications: path === paths.notifications ? true : false,
    createNotifications: path === paths.createNotifications ? true : false,
    services: path === paths.services ? true : false,
    employees: path === paths.employees ? true : false,
    stats: path === paths.stats ? true : false,
    contact: path === paths.contact ? true : false,
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{businessName && businessName} - מערכת ניהול</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <header className={styles.header}>
        <div className={styles.wrapper}>
          {/* LOGO */}
          <img
            src="/assets/logo.svg"
            alt="logo"
            className={styles.logo}
            onClick={() => navigate(paths.management)}
          />
          <div>
            <span style={{ fontSize: "22px" }}>מערכת ניהול</span>
          </div>
          {/* NAV ITEMS */}
          <nav>
            {/* management */}
            <li
              className={activeState.management ? styles.active : ""}
              onClick={() => navigate(paths.management)}
            >
              <ManagementIconSvg
                fill={activeState.management ? "#de1468" : "#000000"}
              />
              <p>מערכת ניהול</p>
            </li>

            {/* create appointment */}
            <li
              className={activeState.appointments ? styles.active : ""}
              onClick={() => navigate(paths.appointments)}
            >
              <CalendarIcon1Svg
                fill={activeState.appointments ? "#de1468" : "#000000"}
              />
              <p>ניהול תורים</p>
            </li>

            {/* clients */}
            <li
              className={activeState.clients ? styles.active : ""}
              onClick={() => navigate(paths.clients)}
            >
              <DiscordIcon1Svg
                fill={activeState.clients ? "#de1468" : "#000000"}
              />
              <p>ניהול הלקוחות</p>
            </li>

            {/* notifications */}
            <li
              className={activeState.createNotifications ? styles.active : ""}
              onClick={() => navigate(paths.createNotifications)}
            >
              <RemindersIconSvg
                fill={activeState.createNotifications ? "#de1468" : "#000000"}
              />
              <p>עדכונים ללקוח</p>
            </li>

            {/* services */}
            <li
              className={activeState.services ? styles.active : ""}
              onClick={() => navigate(paths.services)}
            >
              <ServicesIconSvg
                fill={activeState.services ? "#de1468" : "#000000"}
              />
              <p>השירותים שלי</p>
            </li>

            {/* employees */}
            <li
              className={activeState.employees ? styles.active : ""}
              onClick={() => navigate(paths.employees)}
            >
              <EmployeesIconSvg
                fill={activeState.employees ? "#de1468" : "#000000"}
              />
              <p>ניהול עובדים</p>
            </li>

            {/* stats */}
            <li
              className={activeState.stats ? styles.active : ""}
              onClick={() => navigate(paths.stats)}
            >
              <StatsIconSvg fill={activeState.stats ? "#de1468" : "#000000"} />
              <p>דוחות ומידע</p>
            </li>

            {/* help */}
            <li
              className={activeState.contact ? styles.active : ""}
              onClick={() => navigate(paths.contact)}
            >
              <HelpIconSvg fill={activeState.contact ? "#de1468" : "#000000"} />
              <p>עזרה ושירות לקוחות</p>
            </li>

            {/* search */}
            {/* <li>
            <SearchIconSvg fill="#000000" />
            <p>חיפוש</p>
          </li> */}

            {/* profile */}
            <li onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              {businessImg ? (
                <img
                  src={businessImg}
                  alt="business-admin-pic"
                  className={styles.userImg}
                />
              ) : (
                <img
                  src="/assets/placeholder.png"
                  alt="placeholder"
                  className={styles.userImg}
                />
              )}
              {isDropdownOpen && (
                <div className={styles.userDropdown}>
                  <ul>
                    <li
                      onClick={() => {
                        navigate(paths.profile);
                      }}
                    >
                      <img src="/assets/user-icon-1.svg" alt="profile" />
                      <p>פרופיל</p>
                    </li>
                    {/* {pro && (
                      <li
                        onClick={() => {
                          navigate(paths.coupons);
                        }}
                      >
                        <img src="/assets/tag.svg" alt="coupon" />
                        <p>coupon</p>
                      </li>
                    )}
                    {pro && (
                      <li
                        onClick={() => {
                          navigate(paths.giftCards);
                        }}
                      >
                        <img src="/assets/gift.svg" alt="gift-card" />
                        <p>gifts</p>
                      </li>
                    )} */}
                    <li
                      onClick={() => {
                        localStorage.clear();
                        navigate(paths.login);
                      }}
                    >
                      <img src="/assets/logout.svg" alt="logout" />
                      <p>התנתקות</p>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </nav>

          {/* HAMBURGER ICON FOR MOBILE */}
          <img
            src="/assets/menu.svg"
            alt="menu"
            className={styles.menu}
            onClick={() => setIsOpen(true)}
          />
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;

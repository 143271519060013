import { useState, useEffect } from "react";
import CustomizedCard from "../containers/_subscription/customizedCard";
import DeleteConfirmation from "../containers/_subscription/deleteConfirmation";
import HelpCard from "../containers/_subscription/helpCard";
import OtherDetails from "../containers/_subscription/otherDetails";
import SmallCard from "../containers/_subscription/smallCard";
import SubDetails from "../containers/_subscription/subDetails";
import Layout from "../layouts/layout";
import SUBSCRIPTION from "../api/services/SUBSCRIPTION";
import styles from "../styles/_screens/subscription.module.scss";
import Loader from "../components/loader";
import SelectPlan from "../components/selectPlan";
import Payment from "../components/payment";
import { paths } from "../routes/paths";

const Subscription = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [data, setData] = useState(null);

  const [selectedPlan, setSelectedPlan] = useState(null);

  // FETCH BUSINESS SUBSCRIPTION DETAILS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SUBSCRIPTION.get_sub_details();
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.main}>
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  let { appointments, clients, currentPlan, subscription, messages } = data;

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.main}>
            <div className={styles.right}>
              <SubDetails
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                setIsPlanModalOpen={setIsPlanModalOpen}
                currentPlan={currentPlan}
                subscription={subscription}
              />
              <OtherDetails
                appointments={appointments}
                clients={clients}
                currentPlan={currentPlan}
                subscription={subscription}
                messages={messages}
              />

              <div className={styles.row}>
                <HelpCard />
                <CustomizedCard />
              </div>
            </div>
            <div className={styles.left}>
              <SmallCard heading="חיובים וחשבוניות" text="מעבר לצפייה" link={paths.invoices} />
              <SmallCard heading="היסטוריית פעולות" text="מעבר לצפייה" link={paths.notifications} />
            </div>
          </div>
        </div>
      </div>

      {isDeleteModalOpen && (
        <DeleteConfirmation setIsOpen={setIsDeleteModalOpen} />
      )}

      {isPlanModalOpen && (
        <SelectPlan
          setIsOpen={setIsPlanModalOpen}
          setIsPaymentModalOpen={setIsPaymentModalOpen}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      )}

      {isPaymentModalOpen && (
        <Payment
          setIsOpen={setIsPaymentModalOpen}
          selectedPlan={selectedPlan}
        />
      )}
    </Layout>
  );
};

export default Subscription;

export const CalendarIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_4820)">
        <mask
          id="mask0_61_4820"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="21"
        >
          <path
            d="M0.000976562 1.90735e-06H20.251V20.25H0.000976562V1.90735e-06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_61_4820)">
          <path
            d="M6.05273 12.6958C6.05273 13.2419 5.61004 13.6846 5.06396 13.6846C4.51789 13.6846 4.0752 13.2419 4.0752 12.6958C4.0752 12.1497 4.51789 11.707 5.06396 11.707C5.61004 11.707 6.05273 12.1497 6.05273 12.6958Z"
            fill={fill}
          />
          <path
            d="M9.4541 12.6958C9.4541 13.2419 9.01141 13.6846 8.46533 13.6846C7.91925 13.6846 7.47656 13.2419 7.47656 12.6958C7.47656 12.1497 7.91925 11.707 8.46533 11.707C9.01141 11.707 9.4541 12.1497 9.4541 12.6958Z"
            fill={fill}
          />
          <path
            d="M12.8555 12.6958C12.8555 13.2419 12.4128 13.6846 11.8667 13.6846C11.3206 13.6846 10.8779 13.2419 10.8779 12.6958C10.8779 12.1497 11.3206 11.707 11.8667 11.707C12.4128 11.707 12.8555 12.1497 12.8555 12.6958Z"
            fill={fill}
          />
          <path
            d="M6.05273 16.0972C6.05273 16.6432 5.61004 17.0859 5.06396 17.0859C4.51789 17.0859 4.0752 16.6432 4.0752 16.0972C4.0752 15.5511 4.51789 15.1084 5.06396 15.1084C5.61004 15.1084 6.05273 15.5511 6.05273 16.0972Z"
            fill={fill}
          />
          <path
            d="M9.4541 16.0972C9.4541 16.6432 9.01141 17.0859 8.46533 17.0859C7.91925 17.0859 7.47656 16.6432 7.47656 16.0972C7.47656 15.5511 7.91925 15.1084 8.46533 15.1084C9.01141 15.1084 9.4541 15.5511 9.4541 16.0972Z"
            fill={fill}
          />
          <path
            d="M12.8555 16.0972C12.8555 16.6432 12.4128 17.0859 11.8667 17.0859C11.3206 17.0859 10.8779 16.6432 10.8779 16.0972C10.8779 15.5511 11.3206 15.1084 11.8667 15.1084C12.4128 15.1084 12.8555 15.5511 12.8555 16.0972Z"
            fill={fill}
          />
          <path
            d="M16.2568 16.0972C16.2568 16.6432 15.8141 17.0859 15.2681 17.0859C14.722 17.0859 14.2793 16.6432 14.2793 16.0972C14.2793 15.5511 14.722 15.1084 15.2681 15.1084C15.8141 15.1084 16.2568 15.5511 16.2568 16.0972Z"
            fill={fill}
          />
          <path
            d="M4.74707 19.459C2.56272 19.459 0.791992 17.6883 0.791992 15.5039V9.01758C0.791992 6.83323 2.56272 5.0625 4.74707 5.0625H15.5049C17.6892 5.0625 19.46 6.83323 19.46 9.01758V15.5039C19.46 17.6883 17.6892 19.459 15.5049 19.459H4.74707Z"
            stroke={fill}
            strokeWidth="1.215"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.95605 9.41309H16.2959"
            stroke={fill}
            strokeWidth="1.215"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.19922 2.37305C7.19922 1.49933 6.4909 0.791016 5.61719 0.791016C4.74347 0.791016 4.03516 1.49933 4.03516 2.37305"
            stroke={fill}
            strokeWidth="1.215"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.2959 2.37305C16.2959 1.49933 15.5876 0.791016 14.7139 0.791016C13.8401 0.791016 13.1318 1.49933 13.1318 2.37305"
            stroke={fill}
            strokeWidth="1.215"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.2568 12.6958C16.2568 13.2419 15.8141 13.6846 15.2681 13.6846C14.722 13.6846 14.2793 13.2419 14.2793 12.6958C14.2793 12.1497 14.722 11.707 15.2681 11.707C15.8141 11.707 16.2568 12.1497 16.2568 12.6958Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_61_4820">
          <rect width="20.25" height="20.25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

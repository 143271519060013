import { useState } from "react";
import SideMenu from "../components/sideMenu";
import Layout from "../layouts/layout";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import Search from "../containers/_clients/search";
import ClientsList from "../containers/_clients/clientsList";
import { DiscordIcon1Svg } from "../svgs/discordIcon1Svg";
import { BooksIcon1Svg } from "../svgs/booksIcon1Svg";
import CreateClient from "../containers/_clients/createClient";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { ConfirmModal } from "../components/confirmModal";
import CUSTOMERS from "../api/services/CUSTOMERS";
import { toast } from "react-toastify";
import styles from "../styles/_screens/clients.module.scss";

const Clients = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [success, setSuccess] = useState(null);
  const [dltLoading, setDltLoading] = useState(false);

  const sideMenuList = [
    {
      id: 1,
      svg: <DiscordIcon1Svg fill={!isOpen ? "#DE1468" : "#000000"} />,
      text: "הלקוחות שלי",
      active: !isOpen ? true : false,
    },
    {
      id: 2,
      svg: <AddPlusSvg fill={isOpen ? "#DE1468" : "#000000"} />,
      text: "הוספת לקוח חדש",
      active: isOpen ? true : false,
      onClick: () => setIsOpen(true),
    },
    {
      id: 3,
      svg: <BooksIcon1Svg fill="#000000" />,
      text: "מינויים",
      active: false,
      onClick: () => navigate(paths.memberShips),
    },
  ];

  // HANDLE CLIENT DELETE
  const handleDelete = async (userId) => {
    setDltLoading(true)
    try {
      const res = await CUSTOMERS.delete_client({ userId });
      toast.success(res.data.message);
      setSuccess(true);
      setIsOpenModal(false)
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setDltLoading(true)
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.main}>
            <Search search={search} setSearch={setSearch} />

            <ClientsList
              search={search}
              setIsOpenModal={setIsOpenModal}
              success={success}
              setSuccess={setSuccess}
            />
          </div>
        </div>
      </div>

      {isOpen && <CreateClient setIsOpen={setIsOpen} />}

      {isOpenModal && (
        <ConfirmModal
          setIsOpen={setIsOpenModal}
          handleClick={() => handleDelete(isOpenModal)}
          loading={dltLoading}
        />
      )}
    </Layout>
  );
};

export default Clients;

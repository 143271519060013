import styles from "../styles/_components/invoiceEmailModal.module.scss";
import Spinner from "./spinner";

const InvoiceEmailModal = ({
  setIsOpen,
  onClick,
  email,
  setEmail,
  loading,
}) => {
  return (
    <div className={styles.mainContainer}>
      <div
        className={styles.backdrop}
        onClick={() => setIsOpen && setIsOpen(false)}
      ></div>
      <div className={styles.container}>
        <span
          className={styles.close}
          onClick={() => setIsOpen && setIsOpen(false)}
        >
          &times;
        </span>
        <div className={styles.content}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="אנא הכניסו כמה מייל למשלוח החשבונית"
          />
          <button onClick={onClick} disabled={!email}>{loading ? <Spinner /> : "שליחה"}</button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceEmailModal;

import Layout from "../layouts/layout";
import styles from "../styles/_screens/contact.module.scss";

const Contact = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <img src="/assets/help-img-1.png" alt="help" />
            <h2>עזרה ושירות לקוחות</h2>

            <p className={styles.text1}>
              לכל שאלה או בקשה אנו זמינים עבורכם!
              <br />
              יש לכם שאלה? מוזמנים לבקר במרכז ההדרכה שלנו!
            </p>

            <p className={styles.text2}>ניתן ליצור איתנו קשר דרך הדוא”ל:</p>

            <h3>help@tor.digital.com</h3>

            <h3>hello@tor.digital.com</h3>

            <p className={styles.text3}>
              צריכים תמיכה? <span>אנחנו כאן</span>
            </p>

            <hr />

            <p className={styles.text4}>תנאי שימוש באפליקציה</p>

            <small>V1.0</small>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

export const ManagementIconSvg = ({ fill }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_484_2207)">
        <mask
          id="mask0_484_2207"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="20"
        >
          <path
            d="M0.916016 1.90735e-06H20.916V20H0.916016V1.90735e-06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_484_2207)">
          <path
            d="M18.6123 5.625C19.5811 6.36391 20.1357 7.52102 20.1357 8.73942V15.3125C20.1357 17.4699 18.3869 19.2188 16.2295 19.2188H5.60449C3.44711 19.2188 1.69824 17.4699 1.69824 15.3125V8.73942C1.69824 7.52102 2.26676 6.37235 3.23555 5.63348L8.54805 1.58156C9.94711 0.514494 11.8869 0.514494 13.2859 1.58156L16.2295 3.82465V1.25"
            stroke={fill}
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.35449 9.02344C8.81516 9.02344 8.37793 9.46066 8.37793 10C8.37793 10.5393 8.81516 10.9766 9.35449 10.9766C9.89383 10.9766 10.3311 10.5393 10.3311 10C10.3311 9.46066 9.89383 9.02344 9.35449 9.02344Z"
            fill={fill}
          />
          <path
            d="M12.4795 9.02344C11.9402 9.02344 11.5029 9.46066 11.5029 10C11.5029 10.5393 11.9402 10.9766 12.4795 10.9766C13.0188 10.9766 13.4561 10.5393 13.4561 10C13.4561 9.46066 13.0188 9.02344 12.4795 9.02344Z"
            fill={fill}
          />
          <path
            d="M9.35449 12.1484C8.81516 12.1484 8.37793 12.5857 8.37793 13.125C8.37793 13.6643 8.81516 14.1016 9.35449 14.1016C9.89383 14.1016 10.3311 13.6643 10.3311 13.125C10.3311 12.5857 9.89383 12.1484 9.35449 12.1484Z"
            fill={fill}
          />
          <path
            d="M12.4795 12.1484C11.9402 12.1484 11.5029 12.5857 11.5029 13.125C11.5029 13.6643 11.9402 14.1016 12.4795 14.1016C13.0188 14.1016 13.4561 13.6643 13.4561 13.125C13.4561 12.5857 13.0188 12.1484 12.4795 12.1484Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_484_2207">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.916016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import { get, post, put } from "../client";
import {
  CANCEL_SUBSCRIPTION,
  CHECK_SUBSCRIPTION,
  GET_SUBSCRIPTION_DETAILS,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLAN_PRICE,
  SUBSCRIBE_TO_PLAN,
} from "../routes";

export default {
  get_sub_details: async () => await get(GET_SUBSCRIPTION_DETAILS),

  get_sub_plans: async () => await get(GET_SUBSCRIPTION_PLANS),

  get_sub_plan_price: async (data) =>
    await post(GET_SUBSCRIPTION_PLAN_PRICE, data),

  sub_to_plan: async (data) => await post(SUBSCRIBE_TO_PLAN, data),

  cancel_sub: async () => await put(CANCEL_SUBSCRIPTION, {}),

  check_subscription: async () => await get(CHECK_SUBSCRIPTION),
};

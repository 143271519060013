import { useState } from "react";
import Switch from "react-switch";
import { RemoveIcon1Svg } from "../../svgs/removeIcon1Svg";
import { toast } from "react-toastify";
import NOTIFICATIONS from "../../api/services/NOTIFICATIONS";
import Spinner from "../../components/spinner";
import styles from "../../styles/_containers/_createNotifications/popupMessage.module.scss";

const PopupMessage = () => {
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  // SEND POPUP
  const handleSendPopup = async () => {
    setLoading(true);
    try {
      const res = await NOTIFICATIONS.send_popup({ body });
      toast.success(res.data.message);
      setBody("");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  // RESET POPUP
  const handleResetPopup = async () => {
    try {
      const res = await NOTIFICATIONS.reset_popup({  });
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className={styles.container}>
      <h2>צור הודעה עבור הלקוחות שלך</h2>

      <p className={styles.mainText}>
        ההודעה תוצג ללקוחות שלכם כאשר הם יכנסו לקבוע תורבאתר או באפליקציה. תוכלו
        לבשר ללקוחותעל חופשה עתידית או שינוי מחיר.
      </p>

      <textarea
        name="body"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        placeholder="כאן ניתן לרשום את ההודעה שלך"
      ></textarea>

      <div className={styles.row}>
        <p>ניתן לרשום עד 80 תווים</p>
        <p>תווים שנותרו: 13</p>
      </div>
      {/* 
      <div className={styles.check}>
        <div className={styles.block}>
          <Switch
            // onChange={(e) => handleChange(e)}
            checked={true}
            offColor="#EBEBEB"
            onColor="#DE1468"
            checkedIcon={false}
            uncheckedIcon={false}
            className={styles.switch}
          />
          <p>הפעל את ההודעה ללקוחות עכשיו</p>
        </div>
      </div> */}

      <button disabled={!body} onClick={handleSendPopup}>
        {loading ? <Spinner /> : "צור הודעת לקוחות לאתר"}
      </button>

      <div className={styles.remove} onClick={handleResetPopup}>
        <RemoveIcon1Svg fill="#E50000" />
        <p>מחיקה</p>
      </div>
    </div>
  );
};

export default PopupMessage;

import { useState, useEffect } from "react";
import Layout from "../layouts/layout";
import Search from "../containers/_employees/search";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import Items from "../containers/_employees/items";
import Employee from "../containers/_employees/employee";
import EMPLOYEES from "../api/services/EMPLOYEES";
import Loader from "../components/loader";
import styles from "../styles/_screens/employees.module.scss";
import { toast } from "react-toastify";
import { ConfirmModal } from "../components/confirmModal";

const Employees = () => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [employees, setEmployees] = useState(null);

  const [loading, setLoading] = useState(false);

  const [resStatus, setResStatus] = useState(null);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);

  // DELETE EMPLOYEE
  const deleteEmployee = async () => {
    setConfirmModalLoading(true)
    try {
      const res = await EMPLOYEES.del(isConfirmModalOpen);
      if (res.status === 200) {
        toast.success(res.data.message);
        setIsConfirmModalOpen(false)
        setResStatus(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setConfirmModalLoading(false)
  };

  // FETCH EMPLOYEES
  useEffect(() => {
    setResStatus(null);
    const fetchData = async () => {
      try {
        const res = await EMPLOYEES.get(businessId);
        setEmployees(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [resStatus]);

  if (loading || !employees) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.main}>
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* <Search search={search} setSearch={setSearch} /> */}

          <div
            className={`${styles.add} ${
              isOpen === "create" ? styles.active : ""
            }`}
            onClick={() => setIsOpen("create")}
          >
            <AddPlusSvg fill={isOpen === "create" ? "#DE1468" : "#000000"} />
            <p>הוספת עובד חדש</p>
          </div>

          <div className={styles.main}>
            <Items
              data={employees}
              setIsOpen={setIsOpen}
              setSelectedEmployee={setSelectedEmployee}
              setIsConfirmModalOpen={setIsConfirmModalOpen}
            />
            {isOpen && (
              <Employee
                isOpen={isOpen}
                selectedEmployee={selectedEmployee}
                businessId={businessId}
                setResStatus={setResStatus}
              />
            )}
          </div>
        </div>
        {isConfirmModalOpen && (
          <ConfirmModal
            setIsOpen={setIsConfirmModalOpen}
            handleClick={() => deleteEmployee(isConfirmModalOpen)}
            loading={confirmModalLoading}
          />
        )}
      </div>
    </Layout>
  );
};

export default Employees;

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Card from "../containers/_invoice/card";
import Table from "../containers/_invoice/table";
import Layout from "../layouts/layout";
import INVOICES from "../api/services/INVOICES";
import Loader from "../components/loader";
import InvoiceEmailModal from "../components/invoiceEmailModal";
import styles from "../styles/_screens/invoices.module.scss";

const Invoices = () => {
  const [data, setData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);

  // FETCH INVOICES
  useEffect(() => {
    setResponseStatus(null);
    const fetchData = async () => {
      try {
        const res = await INVOICES.get_invoices();
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [responseStatus]);

  // SEND INVOICE TO EMAIL
  const send = async () => {
    let payload = {
      paymentId: isModalOpen,
      email,
    };
    setLoading(true);
    try {
      const res = await INVOICES.send_invoice(payload);
      setIsModalOpen(false);
      toast.success(res.data.message);
      setResponseStatus(true);
      setEmail("");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsModalOpen(false);
      setEmail("");
    }
    setLoading(false);
  };

  if (!data) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.main}>
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.main}>
            <Table data={data} setIsModalOpen={setIsModalOpen} />
            {/* <Card /> */}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <InvoiceEmailModal
          setIsOpen={setIsModalOpen}
          onClick={send}
          email={email}
          setEmail={setEmail}
          loading={loading}
        />
      )}
    </Layout>
  );
};

export default Invoices;

import { useState, useEffect } from "react";
import { format } from "date-fns";
import { generateProfileImgUrl } from "../utils/generateProfileImgUrl";
import Modal from "./modal";
import Spinner from "./spinner";
import { ClockIcon1Svg } from "../svgs/clockIcon1Svg";
import CUSTOMERS from "../api/services/CUSTOMERS";
import EMPLOYEES from "../api/services/EMPLOYEES";
import SERVICES from "../api/services/SERVICES";
import SCHEDULE from "../api/services/SCHEDULE";
import { toast } from "react-toastify";
import APPOINTMENTS from "../api/services/APPOINTMENTS";
import { calculateEndTimeOfAppointment } from "../utils/calculateEndTimeOfAppointment";
import styles from "../styles/_components/createAppointmentModal.module.scss";
import { CustomSlotsModal } from "./customSlotsModal";

const CreateAppointmentModal = ({
  data,
  setIsOpen,
  setSuccess,
  currentView,
}) => {
  const [step, setStep] = useState(0);

  const [currentClient, setCurrentClient] = useState(null);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [repeatPeriod, setRepeatPeriod] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);
  const [timeslot, setTimeslot] = useState(null);
  const [expiredInError, setExpiredInError] = useState(null);

  const [isCustomSlotModalOpen, setIsCustomSlotModalOpen] = useState(false);
  const [customSlotValue, setCustomSlotValue] = useState(null);

  const handleCustomSlotChange = (e) => {
    setCustomSlotValue(e.target.value);
    setTimeslot(e.target.value);
    setIsCustomSlotModalOpen(false);
  };

  const [createAppointmentLoading, setCreateAppointmentLoading] =
    useState(false);

  // CREATE APPOINTMENT
  const handleCreateAppointment = async () => {
    setCreateAppointmentLoading(true);

    let payload = {
      employeeId: currentEmployee.id,
      userId: currentClient.userId,
      serviceId: currentService._id,
      businessId: businessId,
      date: format(new Date(data.start), "yyyy-MM-dd"),
      timeSlot:
        currentView === "month"
          ? timeslot
          : format(new Date(data.start), "HH:mm"),
      forcedBooking: true,
    };

    if (repeatPeriod) {
      payload.repeatPeriod = repeatPeriod;

      if (!expiresIn) {
        setExpiredInError("חובה לבחור תוקף");
      } else {
        payload.expiresIn = expiresIn;
      }
    }

    if (!repeatPeriod || (repeatPeriod && expiresIn)) {
      try {
        const res = await APPOINTMENTS.create(payload);
        toast.success(res.data.message);
        setExpiredInError(null);
        setIsOpen(false);
        setSuccess(true);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    }
    setCreateAppointmentLoading(false);
  };

  return (
    <Modal setIsOpen={setIsOpen} onClick={() => null} type={3}>
      <div className={styles.container}>
        <h2 className={styles.mainHeading}>הוספת תור חדש</h2>

        {step === 0 ? (
          <Clients
            currentClient={currentClient}
            setCurrentClient={setCurrentClient}
            setStep={setStep}
          />
        ) : step === 1 ? (
          <Employees
            currentEmployee={currentEmployee}
            setCurrentEmployee={setCurrentEmployee}
            setBusinessId={setBusinessId}
            setStep={setStep}
          />
        ) : step === 2 ? (
          <Services
            currentEmployee={currentEmployee}
            currentService={currentService}
            setCurrentService={setCurrentService}
            setStep={setStep}
          />
        ) : (
          <Time
            repeatPeriod={repeatPeriod}
            setRepeatPeriod={setRepeatPeriod}
            expiresIn={expiresIn}
            setExpiresIn={setExpiresIn}
            expiredInError={expiredInError}
            data={data}
            timeslot={timeslot}
            setTimeslot={setTimeslot}
            currentEmployee={currentEmployee}
            currentService={currentService}
            currentView={currentView}
            setIsCustomSlotModalOpen={setIsCustomSlotModalOpen}
            customSlotValue={customSlotValue}
          />
        )}

        {step > 2 && (
          <button
            onClick={handleCreateAppointment}
            className={styles.btn}
            disabled={currentView === "month" ? !timeslot : false}
          >
            {createAppointmentLoading ? <Spinner /> : "קבעו לי תור"}
          </button>
        )}
      </div>

      {isCustomSlotModalOpen && (
        <CustomSlotsModal
          setIsOpen={setIsCustomSlotModalOpen}
          customSlotValue={customSlotValue}
          handleChange={handleCustomSlotChange}
        />
      )}
    </Modal>
  );
};

export default CreateAppointmentModal;

// CLIENTS COMPONENT
const Clients = ({ currentClient, setCurrentClient, setStep }) => {
  const [clients, setClients] = useState([]);

  // FETCH CLIENTS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await CUSTOMERS.get(undefined, undefined, "all", "");
        setClients(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.clientsContainer}>
      <h2>בחירת לקוח</h2>
      <div className={styles.clients}>
        {clients?.map((i) => (
          <ClientCard
            key={i._id}
            id={i._id}
            currentClient={currentClient}
            img={generateProfileImgUrl(i.user.profile, i.user.name)}
            name={i.user.name}
            handleClick={() => {
              setCurrentClient(i);
              setStep(1);
            }}
          />
        ))}
      </div>
    </div>
  );
};

// CLIENT CARD COMPONENT
export const ClientCard = ({ id, currentClient, img, name, handleClick }) => {
  const [isHovered, setIsHovered] = useState(null);

  return (
    <div
      className={styles.card}
      onClick={handleClick}
      style={{
        border:
          currentClient?._id === id
            ? `1px solid #DE1468`
            : isHovered === id
            ? `1px solid #DE1468`
            : "",
      }}
      onMouseEnter={() => setIsHovered(id)}
      onMouseLeave={() => setIsHovered(null)}
    >
      {img}
      <p
        style={{
          color:
            currentClient?._id === id
              ? "#DE1468"
              : isHovered === id
              ? "#DE1468"
              : "",
        }}
      >
        {name}
      </p>
    </div>
  );
};

// EMPLOYEES COMPONENT
const Employees = ({
  currentEmployee,
  setCurrentEmployee,
  setBusinessId,
  setStep,
}) => {
  const [employees, setEmployees] = useState(null);

  let user_id = localStorage.getItem("user_id");
  user_id = JSON.parse(user_id);

  // FETCH EMPLOYEES
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await EMPLOYEES.get(user_id);

        setEmployees(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.clientsContainer}>
      <h2>בחירת עובד/ת</h2>
      <div className={styles.clients}>
        {employees?.map((i) => (
          <EmployeeCard
            key={i.id}
            id={i.id}
            currentEmployee={currentEmployee}
            img={generateProfileImgUrl(i.profile, i.name)}
            name={i.name}
            handleClick={() => {
              setCurrentEmployee(i);
              setBusinessId(i.businessId);
              setStep(2);
            }}
          />
        ))}
      </div>
    </div>
  );
};

// EMPLOYEES CARD COMPONENT
export const EmployeeCard = ({
  id,
  currentEmployee,
  img,
  name,
  handleClick,
}) => {
  const [isHovered, setIsHovered] = useState(null);

  return (
    <div
      className={styles.card}
      onClick={handleClick}
      style={{
        border:
          currentEmployee?.id === id
            ? `1px solid #DE1468`
            : isHovered === id
            ? `1px solid #DE1468`
            : "",
      }}
      onMouseEnter={() => setIsHovered(id)}
      onMouseLeave={() => setIsHovered(null)}
    >
      {img}
      <p
        style={{
          color:
            currentEmployee?.id === id
              ? "#DE1468"
              : isHovered === id
              ? "#DE1468"
              : "",
        }}
      >
        {name}
      </p>
    </div>
  );
};

// SERVICES COMPONENT
const Services = ({
  currentEmployee,
  currentService,
  setCurrentService,
  setStep,
}) => {
  const [services, setServices] = useState([]);

  // FETCH SERVICES
  useEffect(() => {
    if (currentEmployee) {
      const fetchData = async () => {
        try {
          const res = await SERVICES.get(currentEmployee.id);

          setServices(res.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [currentEmployee]);

  return (
    <div className={styles.servicesContainer}>
      <h2>בחירת שירות</h2>
      <div className={styles.services}>
        {services?.map((i) => (
          <ServiceCard
            key={i._id}
            id={i._id}
            currentService={currentService}
            name={i.name}
            price={i.price}
            desc={i.description}
            isExactPrice={i.isExactPrice}
            maxQueue={i.maximumQueue}
            time={i.time}
            handleClick={() => {
              setCurrentService(i);
              setStep(3);
            }}
          />
        ))}
      </div>
    </div>
  );
};

// SERVICE CARD COMPONENT
export const ServiceCard = ({
  id,
  currentService,
  name,
  price,
  desc,
  isExactPrice,
  maxQueue,
  time,
  handleClick,
}) => {
  const [isHovered, setIsHovered] = useState(null);

  return (
    <div
      className={`${styles.card} ${
        id === currentService?._id ? styles.active : ""
      }`}
      onClick={handleClick}
      style={{
        border:
          currentService?._id === id
            ? `1px solid #DE1468`
            : isHovered === id
            ? `1px solid #DE1468`
            : "",
        backgroundColor:
          currentService?._id === id
            ? "rgba(255, 172, 205, 0.21)"
            : isHovered === id
            ? "rgba(255, 172, 205, 0.21)"
            : "",
      }}
      onMouseEnter={() => setIsHovered(id)}
      onMouseLeave={() => setIsHovered(null)}
    >
      <div className={styles.img} style={{ backgroundColor: "#DE1468" }}>
        <h3>{name?.slice(0, 1)}</h3>
      </div>

      <div className={styles.content}>
        <h5 className={styles.name}>{name}</h5>
        <p className={styles.desc}>{desc}</p>

        <div className={styles.row}>
          <div
            className={styles.block}
            style={{
              backgroundColor: "rgba(255, 172, 205, 0.21)",
              border: `1px solid #DE1468`,
            }}
          >
            <ClockIcon1Svg fill={"#DE1468"} />
            <p style={{ color: "#DE1468" }}>{time} דקות</p>
          </div>
          {price > 0 && (
            <div
              className={styles.block}
              style={{
                backgroundColor: "rgba(255, 172, 205, 0.21)",
                border: `1px solid #DE1468`,
                maxWidth: !isExactPrice ? "120px" : "60px",
                minWidth: !isExactPrice ? "100px" : "60px",
              }}
            >
              <p style={{ color: "#DE1468" }}>
                {isExactPrice ? " " : "החל מ-"}₪{price}
              </p>
            </div>
          )}
        </div>
      </div>

      <button
        style={{
          backgroundColor:
            currentService?._id === id
              ? "#DE1468"
              : isHovered === id
              ? "#DE1468"
              : "",
        }}
      >
        בחירת
        <br />
        טיפול
      </button>
    </div>
  );
};

// TIME COMPONENT
const Time = ({
  repeatPeriod,
  setRepeatPeriod,
  expiresIn,
  setExpiresIn,
  expiredInError,
  data,
  timeslot,
  setTimeslot,
  currentEmployee,
  currentService,
  currentView,
  setIsCustomSlotModalOpen,
  customSlotValue,
}) => {
  let repeatingTime = [
    { label: "ללא חזרתיות", value: "" },
    { label: "כל יום", value: "1d" },
    { label: "כל יומיים", value: "2d" },
    { label: "כל שלושה ימים", value: "3d" },
    { label: "כל שבוע", value: "7d" },
    { label: "כל שבועיים", value: "14d" },
    { label: "כל 3 שבועות", value: "21d" },
    { label: "כל חודש", value: "1m" },
    { label: "כל חודשיים", value: "2m" },
    { label: "כל שלושה חודשים", value: "3m" },
    { label: "כל חצי שנה", value: "6m" },
    { label: "כל שנה", value: "1y" },
  ];

  let expiryTime = [
    { label: "שבוע", value: "1w" },
    { label: "שבועיים", value: "2w" },
    { label: "3 שבועות", value: "3w" },
    { label: "חודש", value: "1m" },
    { label: "3 חודשים", value: "3m" },
    { label: "6 חודשים", value: "6m" },
    { label: "שנה", value: "1y" },
  ];

  const [timeSlots, setTimeSlots] = useState(null);
  const [isHovered, setIsHovered] = useState(null);
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [timeslotsError, setTimeslotsError] = useState(null);

  // FETCH TIMESLOTS
  useEffect(() => {
    setTimeslotsError(null);
    if (currentEmployee && currentService && data && currentView === "month") {
      const fetchData = async () => {
        try {
          const payload = {
            employeeId: currentEmployee.id,
            serviceId: currentService._id,
            date: format(new Date(data.start), "yyyy-MM-dd"),
          };
          const res = await SCHEDULE.get_timeslots(payload);
          let unavailableSlots = res.data.data.detailedRecords;
          let availableSlots = res.data.data.records.map((i) => {
            return {
              slot: i,
              available: true,
            };
          });

          // merge both arrays then check and remove the same slot value arrays
          let mergedArray = [...unavailableSlots, ...availableSlots].reduce(
            (acc, slot) => {
              const foundSlot = acc.find((s) => s.slot === slot.slot);
              if (!foundSlot) {
                acc.push(slot);
              }
              return acc;
            },
            []
          );

          setTimeSlots(mergedArray);
        } catch (error) {
          console.log(error);
          setTimeslotsError(error.response.data.message);
        }
      };

      fetchData();
    }
  }, [currentEmployee, currentService, data, currentView]);

  // REMOVE THE CURRENT DAY SLOTS THAT ARE IN PAST
  useEffect(() => {
    if (currentView === "month") {
      if (new Date(data.start).getDate() === new Date().getDate()) {
        // if any slot is in past then remove it
        let updatedSlots = timeSlots?.filter((i) => {
          // convert both timeslot and current time to minutes past midnight
          let slotMinutes =
            parseInt(i.slot.slice(0, 2)) * 60 + parseInt(i.slot.slice(3, 5));
          let currentMinutes =
            new Date().getHours() * 60 + new Date().getMinutes();

          if (slotMinutes > currentMinutes) {
            return i;
          }
        });

        setFilteredSlots(updatedSlots);
      } else {
        setFilteredSlots(timeSlots);
      }
    }
  }, [data, timeSlots, currentService, currentEmployee, currentView]);

  // handle slot click
  const handleClick = (i) => {
    if (i.available) {
      setTimeslot(i.slot);
    }
  };

  return (
    <div className={styles.time}>
      {/* CUSTOM TIME SELECT SECTION */}
      {!timeslotsError && (
        <div className={styles.customTime}>
          <h3>לבחירת שעה מותאמת אישית</h3>
          <button onClick={() => setIsCustomSlotModalOpen(true)}>
            לחצו כאן
          </button>
        </div>
      )}

      {/* SHOW TIME AFTER SELECTING CUSTOM SLOT */}
      {customSlotValue && (
        <div className={styles.timeDisplay}>
          <h3>תאריך ושעת התור</h3>
          <div className={styles.block}>
            <p>{format(new Date(data.start), "dd/MM/yyyy")}</p>
          </div>

          <div className={styles.block}>
            <img src="/assets/clock-icon-1.svg" />
            <p>
              {calculateEndTimeOfAppointment(timeslot, currentService.time)}
            </p>
            <span>-</span>
            <p>{timeslot}</p>
          </div>
        </div>
      )}

      {/* SLOTS SECTION */}
      {currentView === "month" && !customSlotValue && (
        <div className={styles.slotsSection}>
          <h3>בחירת שעה</h3>

          {timeslotsError && (
            <p className={styles.timeslotsError}>{timeslotsError}</p>
          )}

          <div className={styles.slots}>
            <div className={styles.items}>
              {filteredSlots?.length === 0 ? (
                <div className={styles.noSlots}>
                  <p>לא נותרו תורים ביום זה</p>
                </div>
              ) : (
                filteredSlots?.map((i) => (
                  <div
                    key={i.slot}
                    className={styles.slot}
                    onClick={() => handleClick(i)}
                    style={{
                      border:
                        timeslot === i.slot
                          ? "1px solid #DE1468"
                          : isHovered === i.slot
                          ? "1px solid #DE1468"
                          : !i.available
                          ? "1px solid #E50000"
                          : "",
                      backgroundColor: !i.available ? "#E50000" : "",
                      cursor: !i.available ? "not-allowed" : "pointer",
                    }}
                    onMouseEnter={() => setIsHovered(i.slot)}
                    onMouseLeave={() => setIsHovered(null)}
                  >
                    <p
                      style={{
                        color:
                          timeslot === i.slot
                            ? "#DE1468"
                            : isHovered === i.slot
                            ? "#DE1468"
                            : !i.available
                            ? "#ffffff"
                            : "",
                      }}
                    >
                      {i.slot}
                      <br />
                    </p>
                  </div>
                ))
              )}

              {/* IF ALL SLOTS OF THE CURRENT DAY ARE IN PAST */}
              {/* {(filteredSlots?.length === 0 ||
                filteredSlots?.every((s) => !s.available)) && (
                <div className={styles.noSlots}>
                  <p>לא נותרו תורים ביום זה</p>
                </div>
              )} */}
            </div>
          </div>
        </div>
      )}

      {/* SHOW TIME FOR DAY AND WEEK VIEW */}
      {currentView !== "month" && !customSlotValue && (
        <div className={styles.timeDisplay}>
          <div className={styles.block}>
            <p>{format(new Date(data.start), "dd/MM/yyyy")}</p>
          </div>

          <div className={styles.block}>
            <img src="/assets/clock-icon-1.svg" />
            <p>
              {calculateEndTimeOfAppointment(
                format(new Date(data.start), "HH:mm"),
                currentService.time
              )}
            </p>
            <span>-</span>
            <p>{format(new Date(data.start), "HH:mm")}</p>
          </div>
        </div>
      )}

      {/* REPEATING DROPDOWNS */}
      <div className={styles.dropdowns}>
        <h3>תור קבוע</h3>
        <h4>במידה והתור קבוע יש לבחור את זמן החזרתיות ואת התקופה</h4>

        <div className={styles.row}>
          <select
            name="repeatPeriod"
            value={repeatPeriod}
            onChange={(e) => setRepeatPeriod(e.target.value)}
          >
            {repeatingTime.map((i, idx) => (
              <option value={i.value} key={idx}>
                {i.label}
              </option>
            ))}
          </select>

          {repeatPeriod && (
            <select
              name="expiresIn"
              value={expiresIn}
              onChange={(e) => setExpiresIn(e.target.value)}
            >
              <option value="" selected disabled>
                תקופה
              </option>
              {expiryTime.map((i, idx) => (
                <option value={i.value} key={idx}>
                  {i.label}
                </option>
              ))}
            </select>
          )}
        </div>
        <small className={styles.error}>{expiredInError}</small>
      </div>
    </div>
  );
};

export const translations = {
  // side bar - nav bar
  nav: {
    eng: {
      item_1: "Appointments",
      item_2: "Employees",
      item_3: "My Availability",
      item_4: "My Services",
      item_5: "My Business Info",
      item_6: "Log out",
      item_7: "Business Setting",
      item_8: "Clients",
    },
    hebrew: {
      item_1: "התורים שלי",
      item_2: "העובדים שלי",
      item_3: "ימי קבלת קהל",
      item_4: "השירותים שלי",
      item_5: "פרטי העסק",
      item_6: "התנתק",
      item_7: "הגדרות העסק ותורים",
      item_8: "הלקוחות שלי",
    },
  },

  // screens
  screens: {
    appointments: {
      eng: {
        heading: "Appointments",
        addBtn: "New",
        item: {
          text_1: (mins) => `${mins} Min Meeting`,
        },
        modal: {
          heading: "Add new appointment",
          sub_heading_1: "Select a user",
          sub_heading_2: "Select an employee",
          sub_heading_3: "Select a service",
          sub_heading_4: "Select a day",
          sub_heading_5: "Select a timeslot",
        },
      },
      hebrew: {
        heading: "התורים שלי",
        addBtn: "צור תור חדש",
        item: {
          text_1: (mins) => `תור של ${mins} דקות`,
        },
        modal: {
          heading: "הוספת תור חדש",
          sub_heading_1: "בחירת לקוח",
          sub_heading_2: "בחירת נותן שירות",
          sub_heading_3: "בחירת שירות",
          sub_heading_4: "בחירת יום",
          sub_heading_5: "בחירת שעה",
        },
      },
    },

    employees: {
      eng: {
        heading: "Employees",
        addBtn: "New",
        modal: {
          heading: "Add an Employee",
          input_1: "Name",
          input_2: "Phone Number",
          submitBtn: "Submit",
        },
      },
      hebrew: {
        heading: "עובדים",
        addBtn: "הוספת עובד חדש",
        modal: {
          heading: "הוספת עובד חדש",
          input_1: "שם",
          input_2: "מספר טלפון",
          submitBtn: "הקמת עובד",
        },
      },
    },

    myAvailability: {
      eng: {
        heading: "Availability",
        text_1: "Configure times when you are available for bookings.",
        btn: "Save",
        text_2: "Change the start and end times of your day",
      },
      hebrew: {
        heading: "ימי קבלת קהל",
        text_1: "בחירת זמני קבלת קהל ",
        btn: "שמירה",
        text_2: "בחירת ימי עבודה, שעות קבלת קהל והשירותים",
      },
    },

    myServices: {
      eng: {
        heading: "Manage Services",
        addBtn: "New",
        modal: {
          heading_1: "Add Service",
          input_1: "Name",
          input_2: "Time",
          input_3: "Price",
          input_4: "Description",
          submitBtn_1: "Submit",
          heading_2: "Update Service",
          submitBtn_2: "Update",
        },
      },
      hebrew: {
        heading: "השירותים של העסק",
        addBtn: "הוספת שירות חדש",
        modal: {
          heading_1: "הוספת שירות חדש",
          input_1: "שם השירות",
          input_2: "אורך השירות",
          input_3: "מחיר",
          input_4: "תיאור",
          submitBtn_1: "הקמת שירות",
          heading_2: "עריכת שירות",
          submitBtn_2: "עדכן",
        },
      },
    },

    myProfile: {
      eng: {
        btn_1: "Schedule",
        btn_2: "Services",
        text: "I'm accepting groups",
        input_1: "Description",
        input_2: "Business Name",
        input_3: "Instagram",
        input_4: "Phone Number",
        input_5: "Business Number",
        input_6: "Whatsapp",
        input_7: "City",
        input_8: "Address",
        submitBtn: "Update",
      },
      hebrew: {
        btn_1: "ימי הקבלה שלי",
        btn_2: "השירותים שלי",
        text: "אני מקבל קבוצות (מתאים למאמני כושר או לכל מי שמקבל קבוצות לאותה שעה)",
        input_1: "תיאור",
        input_2: "שם העסק",
        input_3: "לינק לאינסטגרם",
        input_4: "מספר טלפון",
        input_5: "מזהה העסק שלי",
        input_6: "מספר טלפון לוואטסאפ",
        input_7: "עיר",
        input_8: "כתובת מלאה",
        submitBtn: "עדכון פרטים",
      },
    },
  },
};

import { CheckIcon1Svg } from "../../../svgs/checkIcon1Svg";
import styles from "../../../styles/_containers/_workingHours/day/services.module.scss";

const Services = ({ dayId, timingIndex, services, allServices, setData }) => {
  
  const handleClick = (currentService) => {
    if (services.find((e) => e.id?.name === currentService?.name)) {
      setData((prev) =>
        prev.map((obj) => {
          if (obj._id === dayId) {
            return {
              ...obj,
              timing: obj.timing.map((slot, idxx) => {
                if (timingIndex === idxx) {
                  return {
                    ...slot,
                    services: slot.services.filter((serv) => {
                      return currentService?.name !== serv.id?.name;
                    }),
                  };
                }
                return slot;
              }),
            };
          }
          return obj;
        })
      );
    } else {
      setData((prev) =>
        prev.map((obj) => {
          if (obj._id === dayId) {
            return {
              ...obj,
              timing: obj.timing.map((slot, idxx) => {
                if (timingIndex === idxx) {
                  return {
                    ...slot,
                    services: [
                      ...slot.services,
                      {
                        _id: currentService._id,
                        status: true,
                        id: {
                          name: currentService.name,
                          _id: currentService._id,
                        },
                      },
                    ],
                  };
                }
                return slot;
              }),
            };
          }
          return obj;
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>בחר שירות:</p>

      <div className={styles.services}>
        {allServices &&
          allServices.length > 0 &&
          allServices.map((i) => (
            <div
              key={i._id}
              className={`${styles.service} ${
                services.find((j) => j.id?.name === i?.name) ? styles.active : ""
              }`}
              onClick={() => handleClick(i)}
            >
              <CheckIcon1Svg
                fill={
                  services.find((j) => j.id?.name === i?.name)
                    ? "#de1468"
                    : "#DADADA"
                }
              />
              <p>{i.name}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Services;

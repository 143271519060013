import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import Layout from "../layouts/layout";
import SideMenu from "../components/sideMenu";
import Card from "../containers/_profile/card";
import { BagIcon1Svg } from "../svgs/bagIcon1Svg";
import { ClockIcon1Svg } from "../svgs/clockIcon1Svg";
import { WalletIcon1Svg } from "../svgs/walletIcon1Svg";
import styles from "../styles/_screens/profile.module.scss";

const Profile = () => {
  const navigate = useNavigate();

  const sideMenuList = [
    {
      id: 1,
      svg: <BagIcon1Svg fill="#DE1468" />,
      text: "פרופיל עסקי",
      active: true,
      onClick: () => navigate(paths.profile),
    },

    {
      id: 2,
      svg: <ClockIcon1Svg fill="#000000" />,
      text: "היסטורית פעולות",
      active: false,
      onClick: () => navigate(paths.notifications),
    },
    {
      id: 3,
      svg: <WalletIcon1Svg fill="#000000" />,
      text: "חיובים וחשבוניות",
      active: false,
      onClick: () => navigate(paths.subscription),
    },
  ];

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.main}>
            <Card />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

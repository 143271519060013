import { useEffect, useState, useRef } from "react";
import { generateProfileImgUrl } from "../../utils/generateProfileImgUrl";
import CUSTOMERS from "../../api/services/CUSTOMERS";
import Loader from "../../components/loader";
import styles from "../../styles/_containers/_createAppointment/clients.module.scss";

const Clients = ({ currentClient, setCurrentClient, setStep, search }) => {
  const ref = useRef();

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  // FETCH CLIENTS
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await CUSTOMERS.get(undefined, undefined, "all", search);
        setClients(res.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [search]);

  // IF LOADING
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <div className={styles.container}>
      <h1>בחירת לקוח</h1>

      <div className={styles.clients}>
        {clients?.map((i) => (
          <ClientCard
            key={i._id}
            id={i._id}
            currentClient={currentClient}
            img={generateProfileImgUrl(i.user.profile, i.user.name)}
            name={i.user.name}
            handleClick={() => {
              setCurrentClient(i);
              setTimeout(() => {
                ref.current?.scrollIntoView({ behavior: "smooth" });
              }, 500);
            }}
          />
        ))}
      </div>

      {currentClient && (
        <button ref={ref} onClick={() => setStep(1)}>
          הבא
        </button>
      )}
    </div>
  );
};

export default Clients;

// CLIENT CARD
export const ClientCard = ({ id, currentClient, img, name, handleClick }) => {
  const [isHovered, setIsHovered] = useState(null);

  return (
    <div
      className={styles.card}
      onClick={handleClick}
      style={{
        border:
          currentClient?._id === id
            ? `1px solid #DE1468`
            : isHovered === id
            ? `1px solid #DE1468`
            : "",
      }}
      onMouseEnter={() => setIsHovered(id)}
      onMouseLeave={() => setIsHovered(null)}
    >
      {img}
      <p
        style={{
          color:
            currentClient?._id === id
              ? "#DE1468"
              : isHovered === id
              ? "#DE1468"
              : "",
        }}
      >
        {name}
      </p>
    </div>
  );
};

export const RemoveIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_158)">
        <path
          d="M10 20C4.48578 20 0 15.5142 0 10C0 4.48578 4.48578 0 10 0C15.5142 0 20 4.48578 20 10C20 15.5142 15.5142 20 10 20ZM10 1.25C5.17502 1.25 1.25 5.17502 1.25 10C1.25 14.825 5.17502 18.75 10 18.75C14.825 18.75 18.75 14.825 18.75 10C18.75 5.17502 14.825 1.25 10 1.25Z"
          fill={fill}
        />
        <path
          d="M6.90683 13.7183C6.74677 13.7183 6.5867 13.6576 6.46509 13.5351C6.22095 13.2909 6.22095 12.8951 6.46509 12.651L12.6525 6.46338C12.8968 6.21924 13.2926 6.21924 13.5368 6.46338C13.7809 6.70752 13.7809 7.10333 13.5368 7.34763L7.34918 13.5351C7.22589 13.6576 7.06598 13.7183 6.90683 13.7183Z"
          fill={fill}
        />
        <path
          d="M13.0933 13.7185C12.9333 13.7185 12.7733 13.6578 12.6517 13.5352L6.46423 7.34856C6.21993 7.10442 6.21993 6.70861 6.46423 6.46447C6.70837 6.22018 7.10418 6.22018 7.34832 6.46447L13.5358 12.6519C13.7801 12.896 13.7801 13.2919 13.5358 13.536C13.4134 13.6578 13.2533 13.7185 13.0933 13.7185Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5_158">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import SideMenu from "../components/sideMenu";
import Cards from "../containers/_class/cards";
import Layout from "../layouts/layout";
import { EditIconSvg } from "../svgs/editIconSvg";
import { RemoveIcon1Svg } from "../svgs/removeIcon1Svg";
import Table from "../containers/_class/table";
import styles from "../styles/_screens/class.module.scss";

const Class = () => {
  const sideMenuList = [
    {
      id: 1,
      svg: <EditIconSvg fill="#000000" />,
      text: "עריכה פנימית",
      active: false,
      //   onClick: () => navigate(paths.createAppointment),
    },
    {
      id: 2,
      svg: <RemoveIcon1Svg fill="#ffffff" />,
      text: "ביטול שיעור",
      active: false,
      type: 2,
    },
  ];

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.main}>
            <h2>עריכת ניהול השיעור - אימון פונקציונלי מתקדם</h2>
            <Cards />

            <Table />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Class;

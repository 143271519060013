import { AddPlusSvg } from "../../../svgs/addPlusSvg";
import styles from "../../../styles/_containers/_workingHours/day/actions.module.scss";

const Actions = ({ dayId, timingIndex, setData }) => {
  // ADD
  const handleAdd = () => {
    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          return {
            ...obj,
            timing: [
              ...obj.timing,
              {
                end_time: "0:00",
                start_time: "0:00",
                services: [],
              },
            ],
          };
        }
        return obj;
      })
    );
  };

  // REMOVE
  const handleRemove = () => {
    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          return {
            ...obj,
            timing: obj.timing.filter((x, idxx) => {
              return timingIndex !== idxx;
            }),
          };
        }
        return obj;
      })
    );
  };

  return (
    <div className={styles.container}>
      {
        timingIndex !== 0
        &&
        <div className={styles.block} onClick={handleRemove}>
          <img src="/assets/remove-icon-1.svg" alt="" />
          <p>מחיקה</p>
        </div>
      }
      <div className={styles.block} onClick={handleAdd}>
        <AddPlusSvg fill="#06A816" />
        <p>הוספה</p>
      </div>
    </div>
  );
};

export default Actions;

import { useEffect, useState } from "react";
import Layout from "../layouts/layout";
import CUSTOMERS from "../api/services/CUSTOMERS";
import { useParams } from "react-router-dom";
import Loader from "../components/loader";
import AppointmentCard from "../components/appointmentCard";
import styles from "../styles/_screens/clientHistory.module.scss";
import { format } from "date-fns";
import { hebrewMonthNameGenerator } from "../utils/hebrewMonthNameGenerator";
import { hebrewDayNameGenerator } from "../utils/hebrewDayNameGenerator";
import CommentModal from "../components/commentModal";
import { ConfirmModal } from "../components/confirmModal";
import APPOINTMENTS from "../api/services/APPOINTMENTS";
import { toast } from "react-toastify";

const ClientHistory = () => {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const [success, setSuccess] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);

  const [groupedAppointmentsData, setGroupedAppointmentsData] = useState(null);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  // FETCH CLIENT HISTORY
  useEffect(() => {
    setSuccess(false);
    const fetchData = async () => {
      setLoading(true);
      try {
        const payload = {
          id: businessId,
          userId: id,
          page,
          size,
        };
        const res = await CUSTOMERS.get_client_history(payload);

        if (page === 1) {
          setHistory(res.data.data);
        } else {
          setHistory((prev) => [...prev, ...res.data.data]);
        }
        setTotalPages(res.data.totalPages);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [success, page]);

  // GROUP APPOINTMENTS
  useEffect(() => {
    function groupAppointmentsByDate(history) {
      const groupedAppointments = {};

      history.forEach((appointment) => {
        const date = new Date(appointment.date).toDateString();

        if (!groupedAppointments[date]) {
          groupedAppointments[date] = [];
        }

        groupedAppointments[date].push(appointment);
      });

      return groupedAppointments;
    }

    const groupedAppointments = groupAppointmentsByDate(history);
    setGroupedAppointmentsData(groupedAppointments);
  }, [success, history]);

  // IF LOADING
  if (loading || !groupedAppointmentsData) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.main}>
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  // CANCEL APPOINTMENT
  const cancelAppointment = async () => {
    let payload = {
      id: isConfirmModalOpen,
      status: 3,
    };
    setConfirmModalLoading(true);
    try {
      const res = await APPOINTMENTS.cancel_appointment(payload);
      setSuccess(true);
      setIsConfirmModalOpen(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.messsage);
    }
    setConfirmModalLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.main}>
            {/* IF THERE IS NO DATA */}
            {history.length === 0 && (
              <div className={styles.noData}>
                <p>לא קיימת היסטוריית תורים ללקוח/ה</p>
              </div>
            )}

            {/* APPOINTMENTS HISTORY */}
            {Object.keys(groupedAppointmentsData).map((date) => (
              <div className={styles.history} key={date}>
                <h3>
                  {`
                    ${hebrewMonthNameGenerator(format(new Date(date), "M"))},
                    ${hebrewDayNameGenerator(
                      format(new Date(date), "EEEE").toLowerCase()
                    )} 
                    ${format(new Date(date), "d/MM/yyyy")} 
                    `}
                </h3>
                {groupedAppointmentsData[date].map((i) => (
                  <AppointmentCard
                    key={i._id}
                    id={i._id}
                    customerName={i.userId.name}
                    date={i.date}
                    startTime={i.timeSlot}
                    serviceName={i.serviceId.name}
                    employeeId={i.employeeId}
                    time={i.serviceId.time}
                    type={i.serviceId?.type}
                    isConfirmed={i.isConfirmed}
                    phoneNumber={i.userId.phone}
                    userId={i.userId._id}
                    setIsCommentModalOpen={setIsCommentModalOpen}
                    setIsConfirmModalOpen={setIsConfirmModalOpen}
                  />
                ))}
              </div>
            ))}

            {/* LOAD MORE */}
            {page < totalPages && (
              <button
                className={styles.loadMore}
                onClick={() => setPage((prev) => prev + 1)}
              >
                load more
              </button>
            )}
          </div>
        </div>
        {/* MODALS */}
        {isCommentModalOpen && (
          <CommentModal
            setIsOpen={setIsCommentModalOpen}
            appointmentId={isCommentModalOpen}
          />
        )}

        {isConfirmModalOpen && (
          <ConfirmModal
            setIsOpen={setIsConfirmModalOpen}
            handleClick={cancelAppointment}
            loading={confirmModalLoading}
          />
        )}
      </div>
    </Layout>
  );
};

export default ClientHistory;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import styles from "../styles/_layouts/mobileNav.module.scss";
import { CalendarIcon1Svg } from "../svgs/calendarIcon1Svg";
import { ManagementIconSvg } from "../svgs/managementIconSvg";
import { DiscordIcon1Svg } from "../svgs/discordIcon1Svg";
import { RemindersIconSvg } from "../svgs/remindersIconSvg";
import { ServicesIconSvg } from "../svgs/servicesIconSvg";
import { EmployeesIconSvg } from "../svgs/employeesIconSvg";
import { StatsIconSvg } from "../svgs/statsIconSvg";
import { HelpIconSvg } from "../svgs/helpIconSvg";
const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const MobileNav = ({ setIsOpen }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [businessImg, setBusinessImg] = useState(null);
  const path = location.pathname;

  let userType = localStorage.getItem("user_type");
  userType = JSON.parse(userType);

  let userID = localStorage.getItem("user_id");
  userID = JSON.parse(userID);

  const classes = {
    appointments: path === paths.appointments ? styles.active : "",
    availability: path.includes("my-availability") ? styles.active : "",
    employees: path.includes("employee") ? styles.active : "",
    services: path.includes("my-services") ? styles.active : "",
    myProfile: path === paths.myProfile ? styles.active : "",
  };


  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <img
              src="https://tor.digital/wp-content/themes/hello-elementor/hmassets/img/appicon.svg"
              alt="logo"
              className={styles.logo}
            />
            {/* <img src="/assets/search.svg" alt="search" /> */}
          </div>

          <nav>
            {/* management */}
            <li
              className={classes.management ? styles.active : ""}
              onClick={() => navigate(paths.management)}
            >
              <ManagementIconSvg
                fill={classes.management ? "#de1468" : "#000000"}
              />
              <p>מערכת ניהול</p>
            </li>

            {/* create appointment */}
            <li
              className={classes.appointments ? styles.active : ""}
              onClick={() => navigate(paths.appointments)}
            >
              <CalendarIcon1Svg
                fill={classes.appointments ? "#de1468" : "#000000"}
              />
              <p>ניהול תורים</p>
            </li>

            {/* clients */}
            <li
              className={classes.clients ? styles.active : ""}
              onClick={() => navigate(paths.clients)}
            >
              <DiscordIcon1Svg
                fill={classes.clients ? "#de1468" : "#000000"}
              />
              <p>ניהול הלקוחות</p>
            </li>

            {/* notifications */}
            <li
              className={classes.createNotifications ? styles.active : ""}
              onClick={() => navigate(paths.createNotifications)}
            >
              <RemindersIconSvg
                fill={classes.createNotifications ? "#de1468" : "#000000"}
              />
              <p>עדכונים ללקוח</p>
            </li>

            {/* services */}
            <li
              className={classes.services ? styles.active : ""}
              onClick={() => navigate(paths.services)}
            >
              <ServicesIconSvg
                fill={classes.services ? "#de1468" : "#000000"}
              />
              <p>השירותים שלי</p>
            </li>

            {/* employees */}
            <li
              className={classes.employees ? styles.active : ""}
              onClick={() => navigate(paths.employees)}
            >
              <EmployeesIconSvg
                fill={classes.employees ? "#de1468" : "#000000"}
              />
              <p>ניהול עובדים</p>
            </li>

            {/* stats */}
            <li
              className={classes.stats ? styles.active : ""}
              onClick={() => navigate(paths.stats)}
            >
              <StatsIconSvg fill={classes.stats ? "#de1468" : "#000000"} />
              <p>דוחות ומידע</p>
            </li>

            {/* help */}
            <li
              className={classes.contact ? styles.active : ""}
              onClick={() => navigate(paths.contact)}
            >
              <HelpIconSvg fill={classes.contact ? "#de1468" : "#000000"} />
              <p>עזרה ושירות לקוחות</p>
            </li>

            {/* search */}
            {/* <li>
            <SearchIconSvg fill="#000000" />
            <p>חיפוש</p>
          </li> */}

            {/* profile */}
            <li onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              {businessImg ? (
                <img
                  src={businessImg}
                  alt="business-admin-pic"
                  className={styles.userImg}
                  style={{width:'17px', height:'17px'}}
                />
              ) : (
                <img
                  src="/assets/placeholder.png"
                  alt="placeholder"
                  className={styles.userImg}
                  style={{width:'17px', height:'17px'}}
                />
              )}
            
                <div className={styles.userDropdown}>
                  <ul>
                    <li
                      onClick={() => {
                        navigate(paths.profile);
                      }}
                    >
                      <img style={{width:'17px', height:'17px'}} src="/assets/user-icon-1.svg" alt="profile" />
                      <p>פרופיל</p>
                    </li>
                    <li
                      onClick={() => {
                        localStorage.clear();
                        navigate(paths.login);
                      }}
                    >
                      <img src="/assets/logout.svg" alt="logout" />
                      <p>התנתקות</p>
                    </li>
                  </ul>
                </div>
              
            </li>
          </nav>

        </div>
      </div>
    </div>
  );
};

export default MobileNav;

import { useState, useEffect } from "react";
import Switch from "react-switch";
import { CheckIcon1Svg } from "../../svgs/checkIcon1Svg";
import { toast } from "react-toastify";
import { hebrewDayNameGenerator } from "../../utils/hebrewDayNameGenerator";
import EMPLOYEES from "../../api/services/EMPLOYEES";
import SERVICES from "../../api/services/SERVICES";
import SCHEDULE from "../../api/services/SCHEDULE";
import Spinner from "../../components/spinner";
import styles from "../../styles/_containers/_employees/employee.module.scss";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";

const Employee = ({ businessId, isOpen, selectedEmployee, setResStatus }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [services, setServices] = useState([]);
  const [days, setDays] = useState(null);
  const [daysInOrder, setDaysInOrder] = useState([]);

  const [formdata, setFormdata] = useState({
    profileImg: "",
    coverImg: "",
    name: "",
    description: "",
    businessName: "",
    phone: "",
    city: "",
    id: "",
    // dob: "",
    services: [],
  });

  // EMPLOYEE DETAILS
  useEffect(() => {
    if (isOpen === "edit") {
      setFormdata({
        profileImg: selectedEmployee.profile,
        coverImg: selectedEmployee.business_image,
        name: selectedEmployee.name,
        description: selectedEmployee.description
          ? selectedEmployee.description
          : "",
        businessName: selectedEmployee.business_name,
        phone: selectedEmployee.business_phone
          ? selectedEmployee.business_phone
          : "",
        city: selectedEmployee.city ? selectedEmployee.city : "",
        id: selectedEmployee.id,
        services: [],
      });
    } else {
      setFormdata({
        profileImg: "",
        coverImg: "",
        name: "",
        description: "",
        businessName: "",
        phone: "",
        city: "",
        services: [],
        id: "",
      });
    }
  }, [isOpen, selectedEmployee]);

  // FETCH SERVICES
  useEffect(() => {
    if (isOpen === "edit" && formdata.id) {
      const fetchData = async () => {
        try {
          const res = await SERVICES.get(formdata.id);
          setServices(res.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [isOpen, selectedEmployee, formdata]);

  // HANDLE INPUT CHANGE
  const handleChange = (e) => {
    setFormdata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // HANDLE PROFILE IMAGE CHANGE
  const handleProfileImageChange = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setFormdata((prev) => {
        return {
          ...prev,
          profileImg: reader.result,
        };
      });
    };
    reader.readAsDataURL(file);
  };

  // HANDLE COVER IMAGE CHANGE
  const handleCoverImageChange = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setFormdata((prev) => {
        return {
          ...prev,
          coverImg: reader.result,
        };
      });
    };
    reader.readAsDataURL(file);
  };

  // UPDATE EMPLOYEE
  const handleSubmit = async () => {
    setLoading(true);
    let payload = {
      business_name: formdata.businessName,
      name: formdata.name,
      description: formdata.description,
      phone: formdata.phone,
      business_phone: formdata.phone,
      business_image: formdata.coverImg,
      profile: formdata.profileImg,
      id: formdata.id,
      createdBy: businessId,
    };
    try {
      const res = await EMPLOYEES.update(payload);

      if (res.status === 200) {
        toast.success("Updated successfully!");
        setResStatus(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  // FETCH SCHEDULE
  useEffect(() => {
    if (isOpen === "edit") {
      const fetchData = async () => {
        // setDaysLoading(true);
        try {
          const res = await SCHEDULE.get_schedule(selectedEmployee.id);
          setDays(res.data.days);
          if (res.code === 200) {
            let formattedDays = res.data.days.map((i) => i.name);

            let a1 = [
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ];
            let a2 = formattedDays;

            let missingDays = a1.filter((e) => !a2.includes(e));

            let payload = {
              employeeId: selectedEmployee.id,
              days: [],
            };
            if (missingDays.length > 0) {
              missingDays.map((day) => {
                payload.days.push({
                  name: day,
                  status: false,
                  timing: [
                    {
                      start_time: "",
                      end_time: "",
                      services: [],
                    },
                  ],
                });
              });

              if (res.data.days.length > 0) {
                payload.days = [...payload.days, ...res.data.days];
              }

              const res2 = await SCHEDULE.create_schedule(payload);
              if (res2.status === 201) {
                window.location.reload();
              }
            }
          }
        } catch (error) {
          console.log(error);
          if (error.response.data.result.statusCode === 404) {
            const payload = {
              employeeId: selectedEmployee.id,
              days: [
                {
                  name: "monday",
                  status: false,
                  timing: [
                    {
                      start_time: "",
                      end_time: "",
                      services: [],
                    },
                  ],
                },
                {
                  name: "tuesday",
                  status: false,
                  timing: [
                    {
                      start_time: "",
                      end_time: "",
                      services: [],
                    },
                  ],
                },
                {
                  name: "wednesday",
                  status: false,
                  timing: [
                    {
                      start_time: "",
                      end_time: "",
                      services: [],
                    },
                  ],
                },
                {
                  name: "thursday",
                  status: false,
                  timing: [
                    {
                      start_time: "",
                      end_time: "",
                      services: [],
                    },
                  ],
                },
                {
                  name: "friday",
                  status: false,
                  timing: [
                    {
                      start_time: "",
                      end_time: "",
                      services: [],
                    },
                  ],
                },
                {
                  name: "saturday",
                  status: false,
                  timing: [
                    {
                      start_time: "",
                      end_time: "",
                      services: [],
                    },
                  ],
                },
                {
                  name: "sunday",
                  status: false,
                  timing: [
                    {
                      start_time: "",
                      end_time: "",
                      services: [],
                    },
                  ],
                },
              ],
            };

            const res = await SCHEDULE.create_schedule(payload);
            if (res.status === 201) {
              window.location.reload();
            }
            console.log(res, "ERROR CREATE");
          }
        }
        // setDaysLoading(false);
      };

      fetchData();
    }
  }, [isOpen, selectedEmployee]);

  // RE-ARRANGE DAYS
  useEffect(() => {
    if (isOpen === "edit") {
      if (days && days.length === 7) {
        const dayOrder = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];
        function compareDays(a, b) {
          return dayOrder.indexOf(a.name) - dayOrder.indexOf(b.name);
        }
        let newArr = days.sort(compareDays);

        // ADD LEADING ZERO
        newArr.forEach(function (innerArray) {
          innerArray.timing.forEach(function (obj) {
            if (obj.start_time.length === 4) {
              obj.start_time = "0" + obj.start_time;
            }

            if (obj.end_time.length === 4) {
              obj.end_time = "0" + obj.end_time;
            }
          });
        });

        setDaysInOrder(newArr);
      }
    }
  }, [days, isOpen]);

  // CREATE EMPLOYEE
  const createEmployee = async (e) => {
    setLoading(true);
    try {
      const payload = {
        name: formdata.name,
        description: formdata.description,
        business_phone: formdata.phone,
        phone: formdata.phone,
        city: formdata.city,
        profile: formdata.profileImg,
        business_image: formdata.coverImg,
        createdBy: businessId,
      };

      const res = await EMPLOYEES.create(payload);

      if (res.status === 201) {
        toast.success(res.data.message);
        setResStatus(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.profile}>
        <div className={styles.cover}>
          <img
            src={
              formdata.coverImg
                ? formdata.coverImg
                : "/assets/cover_placeholder.jpeg"
            }
            alt="cover"
            className={styles.img}
          />

          <div className={styles.icon}>
            <input type="file" onChange={handleCoverImageChange} />
            <img src="/assets/gallery-icon-1.svg" alt="gallery" />
          </div>
        </div>
        <div className={styles.main}>
          <img
            src={
              formdata.profileImg
                ? formdata.profileImg
                : "/assets/cover_placeholder.jpeg"
            }
            alt="user"
            className={styles.img}
          />
          <div className={styles.icon}>
            <input type="file" onChange={handleProfileImageChange} />
            <img src="/assets/gallery-icon-1.svg" alt="gallery" />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        {isOpen === "edit" && <h3 className={styles.name}>{formdata.name}</h3>}

        {/* <div className={styles.check}>
          <Switch
            //   onChange={(e) => handleChange(e)}
            checked={true}
            offColor="#EBEBEB"
            onColor="#DE1468"
            checkedIcon={false}
            uncheckedIcon={false}
            className={styles.switch}
          />

          <p>
            אני מקבל מספר אנשים במקביל
            <br />
            (מתאים למאמני כושר או לכל מי שמקבל קבוצות לאותה שעה)
          </p>
        </div> */}

        <div className={styles.desc}>
          <h4>תיאור קצר</h4>
          <textarea
            name="description"
            value={formdata.description}
            onChange={handleChange}
            placeholder="כאן ניתן לרשום את ההודעה שלך"
          ></textarea>
          <div className={styles.row}>
            <p>ניתן לרשום עד 80 תווים</p>
            <p>תווים שנותרו: 13</p>
          </div>
        </div>

        <div className={styles.form}>
          {/* NAME */}
          <div className={styles.field}>
            <label>
              שם העובד <span>*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formdata.name}
              onChange={handleChange}
              placeholder="שם העובד"
            />
          </div>

          {/* BUSINESS NAME */}
          {/* {isOpen === "edit" && (
            <div className={styles.field}>
              <label>
                שם העובד <span>*</span>
              </label>
              <input
                type="text"
                name="businessName"
                value={formdata.businessName}
                onChange={handleChange}
                placeholder="רשום את שם העובד"
              />
            </div>
          )} */}

          {/* PHONE */}
          <div className={styles.field}>
            <label>
              מספר טלפון <span>*</span>
            </label>
            <input
              type="text"
              name="phone"
              value={formdata.phone}
              onChange={handleChange}
              placeholder="הזן טלפון העובד"
            />
          </div>

          {/* DOB */}
          {/* <div className={styles.field}>
            <label>
              תאריך לידה <span>*</span>
            </label>
            <input type="text" placeholder="הזן טלפון העובד" />
          </div> */}

          {/* CITY */}
          {/* <div className={styles.field}>
            <label>
              עיר מגורים <span>*</span>
            </label>
            <input
              type="text"
              name="city"
              value={formdata.city}
              onChange={handleChange}
              placeholder="הזן טלפון העובד"
            />
          </div> */}
        </div>

        {/* SERVICES */}
        {/* {isOpen === "edit" && services.length > 0 && (
          <div className={styles.services}>
            <h4>בחר שירות שהעובד נותן</h4>
            <div className={styles.items}>
              {services.map((i) => (
                <div className={`${styles.item}`} key={i._id}>
                  <CheckIcon1Svg fill="#C3C3C3" />
                  <p>מניקור רפואי</p>
                </div>
              ))}
              <div className={styles.item}>
                <CheckIcon1Svg fill="#DE1468" />
                <p>פדיקור</p>
              </div>
            </div>
          </div>
        )} */}

        {isOpen === "edit" && (
          <button
            className={styles.daysBtn}
            onClick={() => navigate(paths.workingHours(formdata.id))}
          >
            עריכת ימים, שעות קבלה ושירותים
          </button>
        )}

        {/* WORKING HOURS */}
        {isOpen === "edit" && daysInOrder.length > 0 && (
          <div className={styles.workingHours}>
            <h4>שעות וימי קבלה</h4>

            <div className={styles.items}>
              {daysInOrder.map((i) => (
                <div className={styles.item} key={i._id}>
                  <h3>{hebrewDayNameGenerator(i.name)}</h3>

                  <div className={styles.timing}>
                    {i.timing &&
                      i.timing.length > 0 &&
                      i.timing.map((j, idx) => (
                        <div className={styles.hours} key={idx}>
                          <img src="/assets/clock.svg" />
                          <p>{j.start_time}</p>
                          <span>-</span>
                          <p>{j.end_time}</p>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {isOpen === "create" ? (
          <button className={styles.submit} onClick={createEmployee}>
            {loading ? <Spinner /> : "צור עובד חדש"}
          </button>
        ) : (
          <button className={styles.submit} onClick={handleSubmit}>
            {loading ? <Spinner /> : "שמירת נתונים"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Employee;

export const UserIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_5422)">
        <path
          d="M17.0711 2.92892C15.1823 1.04019 12.6711 0 10 0C7.32895 0 4.81766 1.04019 2.92892 2.92892C1.04019 4.81766 0 7.32895 0 10C0 12.6711 1.04019 15.1823 2.92892 17.0711C4.81766 18.9598 7.32895 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 7.32895 18.9598 4.81766 17.0711 2.92892ZM4.34006 16.7694C4.67026 13.9282 7.11304 11.7346 10 11.7346C11.5219 11.7346 12.953 12.3276 14.0295 13.4039C14.939 14.3135 15.5125 15.5011 15.6601 16.7693C14.1266 18.0536 12.1521 18.8281 10 18.8281C7.8479 18.8281 5.87357 18.0537 4.34006 16.7694ZM10 10.5276C8.32535 10.5276 6.96274 9.16504 6.96274 7.49039C6.96274 5.81558 8.32535 4.45312 10 4.45312C11.6747 4.45312 13.0373 5.81558 13.0373 7.49039C13.0373 9.16504 11.6747 10.5276 10 10.5276ZM16.6687 15.7787C16.3701 14.5747 15.7474 13.4647 14.8581 12.5754C14.1379 11.8552 13.2858 11.3148 12.3566 10.9763C13.4737 10.2187 14.2091 8.93875 14.2091 7.49039C14.2091 5.16953 12.3209 3.28125 10 3.28125C7.67914 3.28125 5.79086 5.16953 5.79086 7.49039C5.79086 8.93951 6.52695 10.2199 7.64496 10.9773C6.79001 11.2889 5.99884 11.7706 5.31815 12.4048C4.33243 13.3228 3.64792 14.4933 3.33054 15.7777C1.98639 14.2282 1.17188 12.2075 1.17188 10C1.17188 5.13214 5.13214 1.17188 10 1.17188C14.8679 1.17188 18.8281 5.13214 18.8281 10C18.8281 12.2079 18.0133 14.2291 16.6687 15.7787Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_61_5422">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { calculateEndTimeOfAppointment } from "../utils/calculateEndTimeOfAppointment";
import { redirectToWhatsapp } from "../utils/redirectToWhatsapp";
import APPOINTMENTS from "../api/services/APPOINTMENTS";
import styles from "../styles/_components/appointmentCard.module.scss";

const AppointmentCard = ({
  id,
  serviceName,
  employeeId,
  customerName,
  date,
  startTime,
  time,
  type,
  isConfirmed,
  phoneNumber,
  userId,
  adminComment,
  setIsCommentModalOpen,
  setIsConfirmModalOpen,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // SEND MANUAL REMINDER
  const handleManualReminder = async () => {
    let payload = {
      userId,
      appointmentId: id,
    };
    try {
      const res = await APPOINTMENTS.send_reminder(payload);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.messsage);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        {/* HEAD */}
        <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
          {/* HOURS */}
          <div className={styles.hours}>
            <p>
              {startTime}
              <br />
              {calculateEndTimeOfAppointment(startTime, time)}
            </p>
            <p className={styles.date}>{format(new Date(date), "dd/MM/yy")}</p>
          </div>

          <span className={styles.line} />

          <div className={styles.service}>
            {/* SERVICE NAME */}
            <h4>{serviceName}</h4>
            {adminComment && (
              <p className={styles.adminComment}>הערה פנימית: {adminComment}</p>
            )}
            <div className={styles.row}>
              {/* CUSTOMER NAME */}
              {customerName && (
                <div className={styles.block}>
                  <img src="/assets/employee-icon-1.svg" alt="customer" />
                  <p>{customerName}</p>
                </div>
              )}
              {/* DATE */}
              {/* <div className={styles.block}>
              <img src="/assets/calendar-icon-1.svg" alt="calendar" />
              <p>{format(new Date(date), "dd/MM/yy")}</p>
            </div> */}
            </div>
          </div>

          {/* STATUS */}
          {type === "CLASS" ? (
            <button
              className={styles.classBtn}
              onClick={() => navigate(paths.class(id, employeeId))}
            >
              <p>ניהול שיעור</p>
            </button>
          ) : (
            <div
              className={`${styles.status} ${
                isConfirmed === "PENDING" ? styles.pending : styles.completed
              }`}
            >
              {isConfirmed === "PENDING" ? (
                <p>ממתין לאישור</p>
              ) : (
                <p>מאושר הגעה</p>
              )}
            </div>
          )}

          <span className={styles.arrow}>
            <img
              src={`/assets/${isOpen ? "up" : "down"}-arrow.svg`}
              alt="up-arrow"
            />
          </span>
        </div>

        {/* BODY */}
        <div className={`${styles.body} ${isOpen ? styles.open : styles.hide}`}>
          <hr className={styles.line1} />

          <ul>
            {/* WHATSAPP */}
            {phoneNumber && (
              <li onClick={() => redirectToWhatsapp(phoneNumber)}>
                <img src="/assets/whatsapp-icon-1.svg" alt="whatsapp" />
                <p>שלח הודעת וואטסאפ</p>
              </li>
            )}
            {/* COMMENT */}
            <li onClick={() => setIsCommentModalOpen(id)}>
              <img src="/assets/write-icon-1.svg" alt="write" />
              <p>הוספת הערה לתור</p>
            </li>

            {/* SEND MANUAL REMINDER */}
            <li onClick={handleManualReminder}>
              <img src="/assets/paper-plane-icon-1.svg" alt="paper-plane" />
              <p>שליחת הודעה ללקוח עם פרטי התור</p>
            </li>

            {/* CLIENT MEMBERSHIP */}
            <li onClick={() => navigate(paths.clientMemberShip(userId))}>
              <img src="/assets/card-icon-1.svg" alt="card" />
              <p>כרטיסיות ומנויים</p>
            </li>

            <li>
              <img src="/assets/tinder-icon-1.svg" alt="tinder" />
              <p>תיק לקוח</p>
            </li>

            {/* CANCEL APPOINTMENT */}
            <li onClick={() => setIsConfirmModalOpen(id)}>
              <img src="/assets/remove-icon-1.svg" alt="remove" />
              <p>ביטול תור</p>
            </li>
          </ul>
        </div>
      </div>

    </React.Fragment>
  );
};

export default AppointmentCard;

export const StatsIconSvg = ({ fill }) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_484_2346)">
        <mask
          id="mask0_484_2346"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="22"
          height="22"
        >
          <path
            d="M0.000976562 0.500002H21.001V21.5H0.000976562V0.500002Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_484_2346)">
          <path
            d="M16.0801 1.32031H4.92383C2.65858 1.32031 0.822266 3.15662 0.822266 5.42188V16.5781C0.822266 18.8434 2.65858 20.6797 4.92383 20.6797H16.0801C18.3453 20.6797 20.1816 18.8434 20.1816 16.5781V5.42188C20.1816 3.15662 18.3453 1.32031 16.0801 1.32031Z"
            stroke={fill}
            strokeWidth="1.26"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.10352 10.6309L4.39444 11.8836C4.64152 12.8775 6.07263 12.8212 6.241 11.811L6.88442 5.67216C7.0445 4.71178 8.43706 4.74832 8.54653 5.7158L9.67307 16.4247C9.84652 17.6318 11.3627 17.6055 11.4998 16.3937L11.7802 14.0352"
            stroke={fill}
            strokeWidth="1.26"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.1504 10.918L12.4569 8.3408C12.5663 7.37333 13.9589 7.33678 14.1189 8.29716L14.7624 11.8111C14.9308 12.8212 16.3619 12.8775 16.609 11.8836L16.8999 10.6309"
            stroke={fill}
            strokeWidth="1.26"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_484_2346">
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

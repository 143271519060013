import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { paths } from "./paths";
import Appointments from "../screens/appointments";
import Availability from "../screens/availability";
import Employee from "../screens/employee";
import Employees from "../screens/employees";
import EmployeeServices from "../screens/employeeServices";
import Login from "../screens/login";
import Profile from "../screens/profile";
import Clients from "../screens/clients";
import ClientHistory from "../screens/clientHistory";
import Settings from "../screens/settings";
import CreateAppointment from "../screens/createAppointment";
import WorkingHours from "../screens/workingHours";
import Memberships from "../screens/memberships";
import ClientMembership from "../screens/clientMembership";
import Services from "../screens/services";
import Subscription from "../screens/subscription";
import Invoices from "../screens/invoices";
import Contact from "../screens/contact";
import Management from "../screens/management";
import Notifications from "../screens/notifications";
import CreateNotifications from "../screens/createNotifications";
import Statistics from "../screens/statistics";
import Class from "../screens/class";
// import Coupons from "../screens/coupons";
// import GiftCards from "../screens/giftCards";
// import GiftCardsPurchaseHistory from "../screens/giftCardsPurchaseHistory";
// import GiftCardsConsumptionHistory from "../screens/giftCardsConsumptionHistory";
// import CouponUsageHistory from "../screens/couponUsageHistory";
// import GiftCardPurchasedItemHistory from "../screens/giftCardConsumptionHistoryByCode";
// import GiftCardConsumptionHistoryByCode from "../screens/giftCardConsumptionHistoryByCode";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path={paths.management} element={<Management />} />

        <Route exact path={paths.stats} element={<Statistics />} />

        <Route exact path={paths.appointments} element={<Appointments />} />

        <Route exact path={paths.settings} element={<Settings />} />

        <Route
          exact
          path={paths.createAppointment}
          element={<CreateAppointment />}
        />

        <Route exact path={paths.login} element={<Login />} />

        <Route exact path={paths.clients} element={<Clients />} />

        <Route
          exact
          path={paths.clientMemberShip(":id")}
          element={<ClientMembership />}
        />

        <Route
          exact
          path={paths.clientHistory(":id")}
          element={<ClientHistory />}
        />

        <Route
          exact
          path={paths.workingHours(":id")}
          element={<WorkingHours />}
        />

        <Route exact path={paths.memberShips} element={<Memberships />} />

        <Route exact path={paths.employees} element={<Employees />} />

        <Route exact path={paths.services} element={<Services />} />

        <Route
          exact
          path={paths.class(":appointmentId", ":employeeId")}
          element={<Class />}
        />

        <Route
          exact
          path={paths.createNotifications}
          element={<CreateNotifications />}
        />

        <Route exact path={paths.notifications} element={<Notifications />} />

        <Route exact path={paths.profile} element={<Profile />} />

        <Route exact path={paths.subscription} element={<Subscription />} />

        <Route exact path={paths.invoices} element={<Invoices />} />

        <Route exact path={paths.contact} element={<Contact />} />

        {/* <Route exact path={paths.coupons} element={<Coupons />} />

        <Route
          exact
          path={paths.couponsUsageHistory}
          element={<CouponUsageHistory />}
        />

        <Route exact path={paths.giftCards} element={<GiftCards />} />

        <Route
          exact
          path={paths.giftCardPurchaseHistory}
          element={<GiftCardsPurchaseHistory />}
        />

        <Route
          exact
          path={paths.giftCardConsumptionHistoryByCode(":code")}
          element={<GiftCardConsumptionHistoryByCode />}
        />

        <Route
          exact
          path={paths.giftCardConsumptionHistory}
          element={<GiftCardsConsumptionHistory />}
        /> */}
      </Routes>
    </Router>
  );
};

export default AppRoutes;

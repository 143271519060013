import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AUTH from "../api/services/AUTH";
import Spinner from "../components/spinner";
import OTPInput from "otp-input-react";
import { paths } from "../routes/paths";
import styles from "../styles/_screens/login.module.scss";

const Login = () => {
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  // redirect if token exists
  useEffect(() => {
    if (token) {
      navigate(paths.appointments);
    }
  }, [token]);

  const [step, setStep] = useState(0);

  const [phone, setPhone] = useState("");

  const [OTP, setOTP] = useState("");

  const [loading, setLoading] = useState(false);

  const [loading2, setLoading2] = useState(false);

  // LOGIN
  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const payload = {
        phone,
      };

      const res = await AUTH.login(payload);
      if (res.status === 201) {
        toast.success(res.data.message);
        // alert(res.data.otp)
        setStep(1);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  // VERIFY OTP
  const verifyOTP = async (e) => {
    e.preventDefault();

    setLoading2(true);
    try {
      const payload = {
        phone,
        otp: OTP,
      };

      const res = await AUTH.verify(payload);
      toast.success(res.data.message);
      localStorage.setItem(
        "token",
        JSON.stringify(res.data.token.access.token)
      );
      localStorage.setItem("user_type", JSON.stringify(res.data.user.type));
      localStorage.setItem("user_id", JSON.stringify(res.data.user.id));
      navigate(paths.appointments);
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message);
      toast.error("OTP is invalid");
    }
    setLoading2(false);
  };

  return (
    <div className={styles.container}>
      <img
        src="https://tor.digital/wp-content/themes/hello-elementor/hmassets/img/appicon.svg"
        alt="logo"
        className={styles.logo}
      />

      <form
        className={styles.card}
        onSubmit={step === 0 ? handleLogin : verifyOTP}
      >

        <h1>{step === 0 ? "התחברות" : "אימות קוד"}</h1>
        <div className={styles.field}>
          {step === 0 && <label>מספר טלפון</label>}


          {step === 0 ? (
            <input
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          ) : (
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              className={styles.otp}
              style={{ justifyContent: "center" }}
              inputStyles={{
                marginRight: "0px",
                margin: "0px 5px",
                width: "40px",
                height: "40px",
                fontSize: "22px",
              }}
            />
          )}
        </div>

        {step === 0 ? (
          <button type="submit">{loading ? <Spinner /> : "התחברות"}</button>
        ) : (
          <button type="submit">{loading2 ? <Spinner /> : "אימות"}</button>
        )}
      </form>
    </div>
  );
};

export default Login;

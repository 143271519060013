import { post, put } from "../client";
import {
  ADD_COMMENT,
  CANCEL_APPOINTMENT,
  CREATE_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_EMPLOYEE_APPOINTMENTS,
  SEND_REMINDER,
} from "../routes";

export default {
  get_appointments: async (data) => await post(GET_APPOINTMENTS, data),

  get_employees_appointments: async (data) =>
    await post(GET_EMPLOYEE_APPOINTMENTS, data),

  create: async (data) => await post(CREATE_APPOINTMENT, data),

  send_reminder: async (data) => await post(SEND_REMINDER, data),

  add_comment: async (data) => await put(ADD_COMMENT, data),

  cancel_appointment: async (data) => await put(CANCEL_APPOINTMENT, data),
};

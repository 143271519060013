import { useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Modal from "./modal";
import { UserIcon1Svg } from "../svgs/userIcon1Svg";
import { CalendarIcon1Svg } from "../svgs/calendarIcon1Svg";
import { ClockIcon1Svg } from "../svgs/clockIcon1Svg";
import { redirectToWhatsapp } from "../utils/redirectToWhatsapp";
import CommentModal from "./commentModal";
import APPOINTMENTS from "../api/services/APPOINTMENTS";
import styles from "../styles/_components/appointmentDetailsModal.module.scss";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";

const AppointmentDetailsModal = ({ data, setIsOpen, setSuccess }) => {
  const navigate = useNavigate();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  // SEND MANUAL REMINDER
  const handleManualReminder = async () => {
    let payload = {
      userId: data.userId,
      appointmentId: data.id,
    };
    try {
      const res = await APPOINTMENTS.send_reminder(payload);
      toast.success(res.data.message);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.messsage);
      setIsOpen(false);
    }
  };

  // CANCEL APPOINTMENT
  const cancelAppointment = async () => {
    let payload = {
      id: data.id,
      status: 3,
    };
    try {
      const res = await APPOINTMENTS.cancel_appointment(payload);
      setSuccess(true);
      toast.success(res.data.message);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.messsage);
    }
  };

  return (
    <Modal setIsOpen={setIsOpen} onClick={() => null} type={2}>
      <div className={styles.container}>
        <h2>{data.title}</h2>

        <div className={styles.customer}>
          <UserIcon1Svg fill="#000" />
          <p>{data.customerName}</p>
        </div>

        <div className={styles.date}>
          <CalendarIcon1Svg fill="#000" />
          <p>{format(new Date(data.start), "dd/MM/yyyy")}</p>
        </div>

        <div className={styles.hours}>
          <p>{format(new Date(data.start), "HH:mm")}</p>
          <span>-</span>
          <p>{format(new Date(data.end), "HH:mm")}</p>
          <ClockIcon1Svg fill="#000" />
        </div>

        <div className={styles.body}>
          <ul>
            {/* WHATSAPP */}
            {data.phone && (
              <li onClick={() => redirectToWhatsapp(data.phone)}>
                <img src="/assets/whatsapp-icon-1.svg" alt="whatsapp" />
                <p>שלח הודעת וואטסאפ</p>
              </li>
            )}
            {/* COMMENT */}
            <li onClick={() => setIsCommentModalOpen(true)}>
              <img src="/assets/write-icon-1.svg" alt="write" />
              <p>הוספת הערה לתור</p>
            </li>

            {/* SEND MANUAL REMINDER */}
            <li onClick={handleManualReminder}>
              <img src="/assets/paper-plane-icon-1.svg" alt="paper-plane" />
              <p>שליחת הודעה ללקוח עם פרטי התור</p>
            </li>

            {/* CLIENT MEMBERSHIP */}
            <li onClick={() => navigate(paths.clientMemberShip(data.userId))}>
              <img src="/assets/card-icon-1.svg" alt="card" />
              <p>כרטיסיות ומנויים</p>
            </li>

            <li>
              <img src="/assets/tinder-icon-1.svg" alt="tinder" />
              <p>תיק לקוח</p>
            </li>

            {/* CANCEL APPOINTMENT */}
            <li onClick={cancelAppointment}>
              <img src="/assets/remove-icon-1.svg" alt="remove" />
              <p>ביטול תור</p>
            </li>
          </ul>
        </div>

        <div className={styles.date}>
          <CalendarIcon1Svg fill="#000" />
          <p>{format(new Date(data.createdAt), "dd/MM/yyyy")}</p>
        </div>
      </div>

      {/* MODALS */}
      {isCommentModalOpen && (
        <CommentModal
          setIsOpen={setIsCommentModalOpen}
          appointmentId={data.id}
        />
      )}
    </Modal>
  );
};

export default AppointmentDetailsModal;

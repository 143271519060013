import { useState } from "react";
import { toast } from "react-toastify";
import { CheckIcon1Svg } from "../../svgs/checkIcon1Svg";
import PLANS from "../../api/services/PLANS";
import Spinner from "../../components/spinner";
import styles from "../../styles/_containers/_clientMembership/clientCard.module.scss";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";

const ClientCard = ({ data, allPlans, setResSuccess }) => {
  const navigate = useNavigate();

  let [currentServicePlanId, setCurrentServicePlanId] = useState("");
  let [servicePlans, setServicePlans] = useState([]);
  let [loading, setLoading] = useState(false);
  const firstLetter = data && data.name ? data.name.charAt(0).toUpperCase() : '?';


  // ASSIGN PARTS TO THE USER
  const assignPlan = async () => {
    let payload = {
      servicePlanIds: servicePlans.map((i) => i._id),
      userId: data?.id,
    };
    setLoading(true);
    try {
      const res = await PLANS.assign_plans(payload);
      setServicePlans([]);
      toast.success(res.data.message);
      setResSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        {data?.profile ? (
          <img src={data?.profile} alt="user" />
        ) : (
          <div className={styles.placeholder}>
            <p>{firstLetter}</p>
          </div>
        )}
      </div>

      <div className={styles.body}>
        <hr className={styles.line1} />

        <ul>
          <li>
            <img src="/assets/user-icon-1.svg" alt="user" />
            <p>שם הלקוח: {data.name}</p>
          </li>
          {data.phone && (
            <li>
              <img src="/assets/phone-icon-2.svg" alt="phone" />
              <p>מס’ טלפון: {data.phone}</p>
            </li>
          )}
          {data.email && (
            <li>
              <img src="/assets/paper-plane-icon-1.svg" alt="paper-plane" />
              <p>כתובת דוא”ל: {data.email}</p>
            </li>
          )}
        </ul>

        <div className={styles.status}>
          <h4>הצהרת בריאות</h4>
          <span
            className={`${
              data.isHealthCheckCompleted
                ? styles.completed
                : styles.notCompleted
            }`}
          >
            {data.isHealthCheckCompleted && <CheckIcon1Svg fill="#06A816" />}
            <p>{data.isHealthCheckCompleted ? "מאושר" : "לא קיים"}</p>
          </span>
        </div>

        {/* ASSIGN A PLAN */}
        {allPlans?.length > 0 && (
          <div className={styles.assign}>
            <h4>סוג מנוי</h4>
            <div className={styles.field}>
              <select
                name="servicePlanIds"
                value={currentServicePlanId}
                onChange={(e) => {
                  setCurrentServicePlanId(e.target.value);

                  if (!servicePlans.find((x) => x._id === e.target.value)) {
                    setServicePlans((prev) => [
                      ...prev,
                      allPlans.find((j) => j._id === e.target.value),
                    ]);
                  }
                }}
              >
                <option value="" selected disabled>
                  סוג מנוי
                </option>
                {allPlans.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.name}
                  </option>
                ))}
              </select>
              <button onClick={assignPlan} disabled={servicePlans.length === 0}>
                {loading ? <Spinner /> : "בחר"}
              </button>
            </div>
          </div>
        )}

        {servicePlans.length > 0 && (
          <div className={styles.selectedPlans}>
            {servicePlans.map((i) => (
              <div className={styles.selectedPlan} key={i._id}>
                <p>{i.name}</p>
              </div>
            ))}
          </div>
        )}

        <hr className={styles.line2} />

        <h4>צפייה בהיסטוריה, מסמכים וחבילות</h4>

        <div className={styles.options}>
          <button>מסמכים</button>
          <button onClick={() => navigate(paths.clientHistory(data.id))}>
            היסטוריה
          </button>
          {/* <button>חבילות שנרכשו</button> */}
        </div>
      </div>
    </div>
  );
};

export default ClientCard;

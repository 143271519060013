import { hoursAndMinutes } from "../../../utils";
import styles from "../../../styles/_containers/_workingHours/day/hours.module.scss";

const Hours = ({ startTime, endTime, setData, timingIndex, dayId }) => {
  // HANDLE START TIME CHANGE
  const handleStartTimeChange = (e) => {
    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          return {
            ...obj,
            timing: obj.timing.map((x, idxx) => {
              if (timingIndex === idxx) {
                return {
                  ...x,
                  start_time: e.target.value,
                };
              }
              return x;
            }),
          };
        }
        return obj;
      })
    );
  };

  // HANDLE END TIME CHANGE
  const handleEndTimeChange = (e) => {
    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          return {
            ...obj,
            timing: obj.timing.map((x, idxx) => {
              if (timingIndex === idxx) {
                return {
                  ...x,
                  end_time: e.target.value,
                };
              }
              return x;
            }),
          };
        }
        return obj;
      })
    );
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>שעות קבלה</p>
      <div className={styles.slots}>
        <select
          name="endTime"
          value={endTime}
          className={`${styles.slot} ${styles.active}`}
          onChange={handleEndTimeChange}
        >
          {hoursAndMinutes.map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </select>

        <span>-</span>

        <select
          name="startTime"
          value={startTime}
          className={`${styles.slot} ${styles.active}`}
          onChange={handleStartTimeChange}
        >
          {hoursAndMinutes.map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Hours;

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { Calendar } from "react-calendar";
import { AdIcon1Svg } from "../../svgs/adIcon1Svg";
import { WarningIcon1Svg } from "../../svgs/warningIcon1Svg";
import SUBSCRIPTION from "../../api/services/SUBSCRIPTION";
import NOTIFICATIONS from "../../api/services/NOTIFICATIONS";
import Spinner from "../../components/spinner";
import styles from "../../styles/_containers/_createNotifications/broadcastMessage.module.scss";

const BroadcastMessage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [message, setMessage] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [loading, setLoading] = useState(false);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  // FETCH BUSINESS SUBSCRIPTION DETAILS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SUBSCRIPTION.get_sub_details();
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (!isOpen) {
      let payload = {
        id: businessId,
        message,
      };
      setLoading(true);
      try {
        const res = await NOTIFICATIONS.send_notification_to_all(payload);
        if (res.data.code === 201) {
          toast.success(res.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setLoading(false);
    } else {
      let payload = {
        id: businessId,
        start: format(new Date(dateValue[0]), "yyyy-MM-dd"),
        end: format(new Date(dateValue[1]), "yyyy-MM-dd"),
        message,
      };
      setLoading(true);
      try {
        const res = await NOTIFICATIONS.send_notification(payload);
        if (res.data.code === 201) {
          toast.success(res.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2>שליחת הודעת תפוצה</h2>

        <p className={styles.mainText}>
          לאחר אישור תשלח הודעת וואטסאפ עבור הלקוחות שלך
        </p>

        <div className={styles.remaining}>
          <h3>{data?.messages.allowedFreeMessages}</h3>
          <hr />
          <p>
            יתרת הודעות <span>SMS</span> לשליחה
          </p>
        </div>

        <div className={styles.actions}>
          <h4>למי לשלוח?</h4>
          <div className={styles.btns}>
            <button onClick={() => setIsOpen(false)}>
              כל רשימת הלקוחות שלי ({data?.clients})
            </button>
            <button onClick={() => setIsOpen(true)}>
              בעלי תור בתאריכים לבחירה
            </button>
          </div>
        </div>

        {isOpen && (
          <div className={styles.calendar}>
            <Calendar
              value={dateValue}
              onChange={setDateValue}
              calendarType="Hebrew"
              formatDay={(locale, date) => format(date, "d")}
              next2Label={null}
              prev2Label={null}
              nextLabel="<"
              prevLabel=">"
              selectRange
              formatShortWeekday={(locale, date) => {
                if (format(date, "E").toLowerCase() === "sun") {
                  return "א";
                } else if (format(date, "E").toLowerCase() === "mon") {
                  return "ב";
                } else if (format(date, "E").toLowerCase() === "tue") {
                  return "ג";
                } else if (format(date, "E").toLowerCase() === "wed") {
                  return "ד";
                } else if (format(date, "E").toLowerCase() === "thu") {
                  return "ה";
                } else if (format(date, "E").toLowerCase() === "fri") {
                  return "ו";
                } else if (format(date, "E").toLowerCase() === "sat") {
                  return "ש";
                }
              }}
              formatMonthYear={(locale, date) => {
                if (
                  format(date, "MMMM yyyy") ===
                  `January ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} ינואר`;
                } else if (
                  format(date, "MMMM yyyy") ===
                  `February ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} פברואר`;
                } else if (
                  format(date, "MMMM yyyy") === `March ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} מרץ`;
                } else if (
                  format(date, "MMMM yyyy") === `April ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} אפריל`;
                } else if (
                  format(date, "MMMM yyyy") === `May ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} מאי`;
                } else if (
                  format(date, "MMMM yyyy") === `June ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} יוני`;
                } else if (
                  format(date, "MMMM yyyy") === `July ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} יולי`;
                } else if (
                  format(date, "MMMM yyyy") === `August ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} אוגוסט`;
                } else if (
                  format(date, "MMMM yyyy") ===
                  `September ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} ספטמבר`;
                } else if (
                  format(date, "MMMM yyyy") ===
                  `October ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} אוקטובר`;
                } else if (
                  format(date, "MMMM yyyy") ===
                  `November ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} נובמבר`;
                } else if (
                  format(date, "MMMM yyyy") ===
                  `December ${format(date, "yyyy")}`
                ) {
                  return `${format(date, "yyyy")} דצמבר`;
                }
              }}
              minDate={new Date()}
            />
          </div>
        )}

        {/* TYPE - FOR LATER */}
        {/* <div className={styles.types}>
          <h4>סוג ההודעה</h4>

          <div className={styles.row}>
            <div className={`${styles.type} ${styles.active}`}>
              <div className={styles.iconContainer}>
                <AdIcon1Svg fill="#232323" />
              </div>
              <h5>התראה</h5>
              <p>הודעות ללאתוכן שיווקי / פרסומי</p>
              <small>19 אנשי קשר</small>
              <button>נבחר</button>
            </div>
            <div className={`${styles.type} ${""}`}>
              <div className={styles.iconContainer}>
                <WarningIcon1Svg fill="#D7D7D7" />
              </div>
              <h5>פרסומת</h5>
              <p>הודעות פרסומיותעם קישור להסרה</p>
              <small>19 אנשי קשר</small>
              <button>רכישת פרמיום</button>
            </div>
          </div>
        </div> */}

        <div className={styles.message}>
          <h4>תוכן ההודעה</h4>
          <textarea
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="כאן ניתן לרשום את ההודעה שלך"
          ></textarea>
        </div>

        <div className={styles.row}>
          <p>ניתן לרשום עד 80 תווים</p>
          <p>תווים שנותרו: 13</p>
        </div>

        <button
          className={styles.submitBtn}
          onClick={handleSubmit}
          disabled={isOpen ? (!dateValue || !message) : !message}
        >
          {loading ? <Spinner /> : "שליחת הודעה"}
        </button>
      </div>
    </div>
  );
};

export default BroadcastMessage;

import styles from "../../styles/_containers/_createAppointment/searchClient.module.scss";

const SearchClient = ({ search, setSearch }) => {
  return (
    <div className={styles.container}>
      <input
        type="text"
        placeholder="הקלד/י כאן"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <img src="/assets/star-icon-1.svg" alt="star-icon" />
    </div>
  );
};

export default SearchClient;

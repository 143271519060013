// import Switch from "react-switch";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";
import { toast } from "react-toastify";
import PROFILE from "../../api/services/PROFILE";
import Loader from "../../components/loader";
import Spinner from "../../components/spinner";
import styles from "../../styles/_containers/_profile/card.module.scss";
import { BASE_URL, IMAGE_BASE_URL } from "../../config";

let colors = [
  { color: "#007BFF" },
  { color: "#E273AA" },
  { color: "#111827" },
  { color: "#28A745" },
  { color: "#C82333" },
  { color: "#FD7E14" },
  { color: "#D00254" },
  { color: "#9932CC" },
  { color: "#884A39" },
];

const Card = () => {
  const navigate = useNavigate();
  const [formdata, setFormdata] = useState({
    name: "",
    description: "",
    businessName: "",
    businessImage: "",
    profileImage: "",
    phone: "",
    whatsApp: "",
    businessAddress: "",
    instagram: "",
    facebook: "",
    tiktok: "",
    website: "",
    color: [],
    images: [],
  });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [resStatus, setResStatus] = useState(null);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  // FETCH PROFILE DETAILS
  useEffect(() => {
    setResStatus(true);
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await PROFILE.get();

        setFormdata({
          name: res.data.name,
          description: res.data.description ? res.data.description : "",
          businessName: res.data.business_name,

          businessImage:
            res.data.business_image &&
            (res.data.business_image.includes("http") ||
            res.data.business_image.includes("data:image/")
              ? res.data.business_image
              : `${BASE_URL}/uploads/${res.data.business_image}`),

          profileImage:
            res.data.profile &&
            (res.data.profile.includes("http") ||
            res.data.profile.includes("data:image/")
              ? res.data.profile
              : `${BASE_URL}/uploads/${res.data.profile}`),

          phone: res.data.phone ? res.data.phone : "",
          whatsApp: res.data.whatsApp_phone ? res.data.whatsApp_phone : "",
          businessAddress: res.data.address ? res.data.address : "",
          instagram: res.data.instagramId ? res.data.instagramId : "",
          facebook: res.data.facebook ? res.data.facebook : "",
          tiktok: res.data.tiktok ? res.data.tiktok : "",
          website: res.data.website ? res.data.website : "",
          color: res.data.color,
          images: res.data.multiImage.map((i) => {
            if (i.includes("http") || i.includes("data:image/")) {
              return i;
            } else {
              return `${BASE_URL}/uploads/${i}`;
            }
          }),
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [resStatus]);

  // HANDLE INPUT CHANGE
  const handleChange = (e) => {
    setFormdata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // HANDLE PROFILE IMAGE CHANGE
  const handleProfileImageChange = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      // setProfileImage(reader.result);
      setFormdata((prev) => {
        return {
          ...prev,
          profileImage: reader.result,
        };
      });
    };
    reader.readAsDataURL(file);
  };

  // HANDLE COVER IMAGE CHANGE
  const handleBusinessImageChange = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setFormdata((prev) => {
        return {
          ...prev,
          businessImage: reader.result,
        };
      });
    };
    reader.readAsDataURL(file);
  };

  // HANDLE GALLERY
  const handleGallery = (e) => {
    let files = e.target.files;
  
    // Ensure files is an array
    files = [...files];
  
    if (formdata.images.length + files.length > 8) {
      // If adding these files exceeds the limit, slice the files array
      files = files.slice(0, 8 - formdata.images.length);
    }
  
    files.forEach((file) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        setFormdata((prev) => {
          return {
            ...prev,
            images: [...prev.images, reader.result],
          };
        });
      };
      reader.readAsDataURL(file);
    });
  };

  // REMOVE IMAGE FROM GALLERY
  const removeImage = (img) => {
    setFormdata((prev) => {
      return {
        ...prev,
        images: prev.images.filter((j) => j !== img),
      };
    });
  };

  // UPDATE PROFILE
  const handleSubmit = async () => {
    setLoading2(true);
    try {
      let payload = {
        id: businessId,
        name: formdata.name,
        phone: formdata.phone,
        color: formdata.color ? formdata.color : "",
        tiktok: formdata.tiktok,
        facebook: formdata.facebook,
        instagram: formdata.instagram,
        business_phone: formdata.phone,
        profile: formdata.profileImage ? formdata.profileImage : "",
        whatsApp_phone: formdata.whatsApp,
        address: formdata.businessAddress,
        description: formdata.description,
        business_name: formdata.businessName,
        business_image: formdata.businessImage ? formdata.businessImage : "",
        multiImage: formdata.images,
      };

      const res = await PROFILE.update(payload);

      if (res.status === 200) {
        toast.success("Updated successfully!");
        setResStatus(true);
      }
    } catch (error) {
      console.log(error, "ERORROROR");
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("The File you uploaded is too large");
      }
    }
    setLoading2(false);
  };

  if (loading || !formdata.name) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {/* <div className={styles.id}>
        <p>מס’ משתמש: 245889</p>
      </div> */}

      <div className={styles.profile}>
        {/* COVER IMAGE */}
        <div className={styles.cover}>
          <img
            src={
              formdata.businessImage
                ? formdata.businessImage
                : "/assets/cover_placeholder.jpeg"
            }
            alt="cover"
            className={styles.img}
          />

          <div className={styles.icon}>
            <input type="file" onChange={handleBusinessImageChange} />
            <img src="/assets/gallery-icon-1.svg" alt="gallery" />
          </div>
        </div>

        {/* PROFILE IMAGE */}
        <div className={styles.main}>
          <img
            src={
              formdata.profileImage
                ? formdata.profileImage
                : "/assets/cover_placeholder.jpeg"
            }
            alt="user"
            className={styles.img}
          />
          <div className={styles.icon}>
            <input type="file" onChange={handleProfileImageChange} />
            <img src="/assets/gallery-icon-1.svg" alt="gallery" />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        {/* NAME */}
        <h3 className={styles.name}>{formdata.name}</h3>

        {/* BUTTONS */}
        <div className={styles.btns}>
          <button
            className={styles.daysBtn}
            onClick={() => navigate(paths.workingHours(businessId))}
          >
            ימי הקבלה שלי
          </button>
          <button
            className={styles.servicesBtn}
            onClick={() => navigate(paths.services)}
          >
            השירותים שלי
          </button>
        </div>

        {/* SWITCH */}
        {/* <div className={styles.check}>
          <Switch
            //   onChange={(e) => handleChange(e)}
            checked={true}
            offColor="#EBEBEB"
            onColor="#DE1468"
            checkedIcon={false}
            uncheckedIcon={false}
            className={styles.switch}
          />

          <p>
            אני מקבל מספר אנשים במקביל
            <br />
            (מתאים למאמני כושר או לכל מי שמקבל קבוצות לאותה שעה)
          </p>
        </div> */}

        {/* DESCRIPTION */}
        <div className={styles.desc}>
          <h4>תיאור קצר</h4>
          <textarea
            name="description"
            value={formdata.description}
            onChange={handleChange}
            placeholder="כאן ניתן לרשום את ההודעה שלך"
          ></textarea>
          <div className={styles.row}>
            <p>ניתן לרשום עד 80 תווים</p>
            <p>תווים שנותרו: 13</p>
          </div>
        </div>

        {/* FIELDS */}
        <div className={styles.form}>
          <div className={styles.field}>
            <label>
              שם העסק <span>*</span>
            </label>
            <input
              type="text"
              name="businessName"
              value={formdata.businessName}
              onChange={handleChange}
              placeholder="רשום את שם העובד"
            />
          </div>
          <div className={styles.field}>
            <label>
              מספר טלפון <span>*</span>
            </label>
            <input
              type="text"
              name="phone"
              value={formdata.phone}
              onChange={handleChange}
              placeholder="הזן טלפון העובד"
            />
          </div>
          <div className={styles.field}>
            <label>מספר וואטסאפ</label>
            <input
              type="text"
              name="whatsApp"
              value={formdata.whatsApp}
              onChange={handleChange}
              placeholder="הזן מספר"
            />
          </div>
          <div className={styles.field}>
            <label>
              כתובת העסק <span>*</span>
            </label>
            <input
              type="text"
              name="businessAddress"
              value={formdata.businessAddress}
              onChange={handleChange}
              placeholder="הזן טלפון העובד"
            />
          </div>

          <div className={styles.field}>
            <label>קישור אינסטגרם</label>
            <input
              type="text"
              name="instagram"
              value={formdata.instagram}
              onChange={handleChange}
              placeholder="הזן טלפון העובד"
            />
          </div>

          <div className={styles.field}>
            <label>קישור פייסבוק</label>
            <input
              type="text"
              name="facebook"
              value={formdata.facebook}
              onChange={handleChange}
              placeholder="הזן טלפון העובד"
            />
          </div>

          <div className={styles.field}>
            <label>קישור טיק טוק</label>
            <input
              type="text"
              name="tiktok"
              value={formdata.tiktok}
              onChange={handleChange}
              placeholder="הזן טלפון העובד"
            />
          </div>

          <div className={styles.field}>
            <label>קישור אתר אינטרנט</label>
            <input
              type="text"
              name="website"
              value={formdata.website}
              onChange={handleChange}
              placeholder="הזן טלפון העובד"
            />
          </div>
        </div>

        {/* COLOR */}
        <div className={styles.color}>
          <div className={styles.title}>
            <h4>בחירת צבע העסק</h4>
            <span>חדש</span>
          </div>
          <p>בחרו את צבע האתר והאפליקציה שלכם</p>

          <div className={styles.colors}>
            {colors.map((i) => (
              <div
                key={i.color}
                className={`${styles.circle} ${
                  formdata.color?.toLowerCase() === i.color.toLowerCase()
                    ? styles.active
                    : ""
                }`}
                style={{ backgroundColor: i.color }}
                onClick={() => {
                  setFormdata((prev) => {
                    return {
                      ...prev,
                      color: i.color,
                    };
                  });
                }}
              />
            ))}
          </div>
        </div>

        {/* GALLERY */}
        <div className={styles.gallery}>
          <div className={styles.title}>
            <h4>בחירת תמונות</h4>
          </div>
          <p className={styles.text}>
            בחרו תמונות של העבודות הכי טובות שלכם, התמונות יוצגו ללקוחות באתר
            שלכם
          </p>

          <div className={styles.upload}>
            <div className={styles.uploadBtn}>
              <img src="/assets/gallery-icon-1.svg" alt="gallery-icon" />
              <p>העלאת תמונה</p>
              <input
                type="file"
                multiple
                onChange={handleGallery}
                disabled={formdata.images.length === 8}
                style={{
                  cursor:
                    formdata.images.length === 8 ? "not-allowed" : "pointer",
                }}
              />
            </div>

            <p className={styles.text}>ניתן לעלות עד 8 תמונות בגודל 100kb</p>
          </div>

          {formdata.images.length > 0 && (
            <div className={styles.images}>
              {formdata.images.map((i) => (
                <div className={styles.image} key={i}>
                  <span className={styles.minus} onClick={() => removeImage(i)}>
                    -
                  </span>
                  <img src={i} alt="placeholder" className={styles.img} />
                </div>
              ))}

              {/* <div className={styles.image}>
              <span className={styles.minus}>-</span>
              <img src="/assets/image-placeholder-1.png" alt="placeholder" />
            </div> */}
            </div>
          )}
        </div>

        <button className={styles.submit} onClick={handleSubmit}>
          {loading2 ? <Spinner /> : "שמירה"}
        </button>
      </div>
    </div>
  );
};

export default Card;

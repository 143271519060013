import { useState } from "react";
import { toast } from "react-toastify";
import Modal from "./modal";
import Spinner from "./spinner";
import SUBSCRIPTION from "../api/services/SUBSCRIPTION";
import styles from "../styles/_components/payment.module.scss";

const Payment = ({ setIsOpen, selectedPlan }) => {
  const [formdata, setFormdata] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
    holderId: "",
    fullName: "",
    phone: "",
    email: "",
    companyId: "",
    address: "",
  });

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormdata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async () => {
    let payload = {
      planId: selectedPlan,
      businessId,
      cvv: formdata.cvc,
      holderId: formdata.holderId,
      exp: formdata.expiry,
      cardNumber: formdata.cardNumber,
      fullName: formdata.fullName,
      email: formdata.email,
      companyId: formdata.companyId,
      address: formdata.address,
      city: formdata.city,
      phone: formdata.phone,
    };
    setLoading(true);
    try {
      const res = await SUBSCRIPTION.sub_to_plan(payload);
      toast.success(res.data.message);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsOpen(false);
    }
    setLoading(false);
  };

  return (
    <Modal setIsOpen={setIsOpen}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h2>תשלום</h2>
          <p className={styles.mainText}>הזן את פרטי כרטיס האשראי לחיוב</p>

          <div className={styles.method}>
            <img src="/assets/mastercard-2.png" alt="mastercard" />
            <img src="/assets/visa-icon.png" alt="visa" />
          </div>

          <div className={styles.form}>
            <div className={styles.field}>
              <label>מספר כרטיס</label>
              <input
                type="text"
                name="cardNumber"
                value={formdata.cardNumber}
                onChange={handleChange}
                placeholder="מספר כרטיס"
              />
            </div>

            <div className={styles.field}>
              <label>תאריך</label>
              <input
                type="text"
                name="expiry"
                value={formdata.expiry}
                onChange={handleChange}
                placeholder="MM/YY"
              />
            </div>

            <div className={styles.field}>
              <label>CVC</label>
              <input
                type="text"
                name="cvc"
                value={formdata.cvc}
                onChange={handleChange}
                placeholder="CVC"
              />
            </div>

            <div className={styles.field}>
              <label>שם בעל הכרטיס</label>
              <input
                type="text"
                name="holderId"
                value={formdata.holderId}
                onChange={handleChange}
                placeholder="שם בעל הכרטיס"
              />
            </div>
            {/* =========== */}
            <hr />

            <div className={styles.field}>
              <label>
                שם העסק לחשבונית
                <span>*</span>
              </label>
              <input
                type="text"
                name="fullName"
                value={formdata.fullName}
                onChange={handleChange}
                placeholder="רשום את השם המלא שלך"
              />
            </div>

            <div className={styles.field}>
              <label>
                מספר טלפון
                <span>*</span>
              </label>
              <input
                type="text"
                name="phone"
                value={formdata.phone}
                onChange={handleChange}
                placeholder="הזן טלפון"
              />
            </div>

            <div className={styles.field}>
              <label>
                דואל <span>*</span>
              </label>
              <input
                type="text"
                name="email"
                value={formdata.email}
                onChange={handleChange}
                placeholder="הזן כתובת דוא”ל"
              />
            </div>

            <div className={styles.field}>
              <label>
                מספר עוסק / ח.פ / ת״ז <span>*</span>
              </label>
              <input
                type="text"
                name="companyId"
                value={formdata.companyId}
                onChange={handleChange}
                placeholder="מספר עוסק / ח.פ / ת״ז"
              />
            </div>

            <div className={styles.field}>
              <label>
                כתובת <span>*</span>
              </label>
              <input
                type="text"
                name="address"
                value={formdata.address}
                onChange={handleChange}
                placeholder="כתובת"
              />
            </div>

            <div className={styles.field}>
              <label>
                עיר <span>*</span>
              </label>
              <input
                type="text"
                name="city"
                value={formdata.city}
                onChange={handleChange}
                placeholder="עיר"
              />
            </div>
          </div>

          <button onClick={handleSubmit}>
            {loading ? <Spinner /> : "תשלום"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Payment;

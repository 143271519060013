import styles from "../../styles/_containers/_clients/search.module.scss";

const Search = ({ search, setSearch }) => {
  return (
    <div className={styles.container}>
      <div className={styles.right}>
        <h3>חיפוש לקוח:</h3>
        <p>ניתן להקליד שם לקוח על מנת לחפש</p>
      </div>
      <div className={styles.left}>
        <div className={styles.input}>
          <input
            type="text"
            placeholder="הקלד/י כאן"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* <img src="/assets/star-icon-1.svg" alt="star-icon" /> */}
        </div>
      </div>
    </div>
  );
};

export default Search;

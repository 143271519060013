export const ServicesIconSvg = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_484_2306)">
        <mask
          id="mask0_484_2306"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="23"
          height="22"
        >
          <path
            d="M-0.000488281 0.416994H21.166V21.5835H-0.000488281V0.416994Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_484_2306)">
          <path
            d="M16.205 1.24397H4.96025C2.67704 1.24397 0.826172 3.09484 0.826172 5.37805V16.6228C0.826172 18.906 2.67704 20.7568 4.96025 20.7568H16.205C18.4882 20.7568 20.339 18.906 20.339 16.6228V5.37805C20.339 3.09484 18.4882 1.24397 16.205 1.24397Z"
            stroke={fill}
            strokeWidth="1.26578"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.60456 6.99919L8.95055 8.31672C8.79158 8.63693 8.48435 8.85887 8.12892 8.91022L6.66645 9.12153C5.77141 9.25082 5.41403 10.3443 6.06168 10.972L7.11994 11.9976C7.37714 12.2469 7.49448 12.606 7.43374 12.9579L7.18394 14.406C7.03105 15.2923 7.96669 15.9682 8.76726 15.5497L10.0753 14.866C10.3932 14.6998 10.773 14.6998 11.0909 14.866L12.3989 15.5497C13.1995 15.9682 14.1351 15.2923 13.9822 14.406L13.7324 12.9579C13.6717 12.606 13.7891 12.2469 14.0462 11.9976L15.1045 10.972C15.7522 10.3443 15.3948 9.25082 14.4997 9.12153L13.0373 8.91022C12.6818 8.85887 12.3746 8.63693 12.2156 8.31672L11.5616 6.99919C11.1613 6.19281 10.0048 6.19281 9.60456 6.99919Z"
            stroke={fill}
            strokeWidth="1.26578"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_484_2306">
          <rect
            width="21.1665"
            height="21.1665"
            fill="white"
            transform="translate(0 0.416992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const EditIconSvg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_611_8476)">
        <path
          d="M10 1.17188C12.0369 1.1727 14.0109 1.87768 15.5875 3.16738C17.1642 4.45707 18.2464 6.25217 18.651 8.24851C19.0555 10.2449 18.7575 12.3197 17.8074 14.1214C16.8572 15.9232 15.3134 17.341 13.4375 18.1348C11.28 19.0464 8.84875 19.0637 6.67853 18.1828C4.50831 17.3019 2.77692 15.595 1.86523 13.4375C0.953553 11.28 0.936264 8.84875 1.81717 6.67853C2.69808 4.50831 4.40503 2.77692 6.5625 1.86523C7.64997 1.40504 8.81917 1.16921 10 1.17188ZM10 0C4.47734 0 0 4.47734 0 10C0 15.5227 4.47734 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47734 15.5227 0 10 0Z"
          fill={fill}
        />
        <path
          d="M15.1172 6.94449L13.0554 4.88277C13.0065 4.83372 12.9484 4.7948 12.8844 4.76825C12.8204 4.7417 12.7519 4.72803 12.6826 4.72803C12.6133 4.72803 12.5447 4.7417 12.4807 4.76825C12.4168 4.7948 12.3586 4.83372 12.3097 4.88277L4.88278 12.3097C4.82707 12.3655 4.78457 12.433 4.7584 12.5074C4.73224 12.5818 4.72309 12.661 4.7316 12.7394L4.93785 14.5968C4.951 14.7159 5.00433 14.8269 5.08906 14.9115C5.17378 14.9962 5.28482 15.0494 5.40387 15.0625L7.26129 15.2687C7.2806 15.271 7.30004 15.2722 7.3195 15.2722C7.38884 15.272 7.45746 15.2581 7.52141 15.2313C7.58536 15.2045 7.64338 15.1653 7.69215 15.116L15.1172 7.69019C15.1662 7.64128 15.2051 7.58316 15.2317 7.51918C15.2582 7.4552 15.2719 7.38661 15.2719 7.31734C15.2719 7.24807 15.2582 7.17948 15.2317 7.1155C15.2051 7.05152 15.1662 6.9934 15.1172 6.94449ZM7.12379 14.1941L5.93746 14.0625L5.80543 12.8765L11.0316 7.65035L12.3496 8.96832L7.12379 14.1941ZM13.0953 8.22261L11.7773 6.90464L12.6824 5.99957L14.0004 7.31754L13.0953 8.22261Z"
          fill={fill}
        />
        <path
          d="M14.7461 14.2188H11.0547C10.7634 14.2188 10.5273 14.4548 10.5273 14.7461C10.5273 15.0373 10.7634 15.2734 11.0547 15.2734H14.7461C15.0373 15.2734 15.2734 15.0373 15.2734 14.7461C15.2734 14.4548 15.0373 14.2188 14.7461 14.2188Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_611_8476">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import Card from "./card";
import styles from "../../styles/_containers/_settings/sections.module.scss";

const SectionOne = ({ data, handleChange }) => {
  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.cardContainer}>
          {/* BLOCK 1 */}
          {/* WAITING LIST */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>רשימת המתנה</h4>
              <div className={styles.tag}>
                <p>חדש</p>
              </div>
            </div>

            <p className={styles.textOne}>
              בחרו האם להפעיל רשימת המתנה לתורים בעסק
            </p>

            <div className={styles.field}>
              <label>רשימת המתנה</label>
              <select
                name="waitingListMode"
                value={data.waitingListMode}
                onChange={handleChange}
              >
                <option value="DAY">רשימת המתנה ליום שלם</option>
                <option value="TIME">רשימת המתנה לכל חלון זמן</option>
                <option value="DISABLED">כבה את רשימת ההמתנה</option>
              </select>
            </div>
          </div>

          {/* BLOCK 2 */}
          {/* IS CONFIRM ENABLED */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>אישור הגעה לתורים</h4>
              <div className={styles.tag}>
                <p>חדש</p>
              </div>
            </div>

            <p className={styles.textOne}>
              במידה ופעיל,לקוחות יקבלו הודעה ויבקשו לאשר את התור באופן ידני
            </p>

            <div className={styles.field}>
              <label>אישור הגעה</label>
              <select
                name="isConfirmEnabled"
                value={data.isConfirmEnabled}
                onChange={handleChange}
              >
                <option value={true}>פעיל</option>
                <option value={false}>לא פעיל</option>
              </select>
            </div>
          </div>

          {/* BLOCK 3 */}
          {/* BUSINESS SCHEDULE ALLOWED FOR */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>זמן קביעת תור עתידי</h4>
            </div>

            <p className={styles.textOne}>
              לקוחות יוכלו לקבוע תור עתידי עד 3 חודשים מראש
            </p>

            <div className={styles.field}>
              <label>תורים עתידים</label>
              <select
                name="businesssScheduleAllowedFor"
                value={data.businesssScheduleAllowedFor}
                onChange={handleChange}
              >
                <option value="1w">שבוע</option>
                <option value="2w">שבועיים</option>
                <option value="3w">3 שבועות</option>
                <option value="1m">חודש</option>
                <option value="3m">3 חודשים</option>
                <option value="6m">6 חודשים</option>
                <option value="1y">שנה</option>
              </select>
            </div>
          </div>

          {/* BLOCK 4 */}
          {/* IS 24 HOURS REMINDER DISABLED */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>שליחת תזכורת 24 שעות לפני התור</h4>
              <div className={styles.tag}>
                <p>חדש</p>
              </div>
            </div>

            <p className={styles.textOne}>
              במידה ופעיל, המערכת תשלח ללקוח תזכורת 24 שעות לפני התור. שימו לב
              שהמערכת שולחת בכל און תזכורת 3 שעות לפני התור
            </p>

            <div className={styles.field}>
              <label>זמן ביטול תור מראש</label>
              <select
                name="is24HoursReminderDisabled"
                value={data.is24HoursReminderDisabled}
                onChange={handleChange}
              >
                <option value={true}>לא פעיל</option>
                <option value={false}>פעיל</option>
              </select>
            </div>
          </div>

      {/* BLOCK 4.5 */}
          {/* IS 3 HOURS REMINDER DISABLED */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>שליחת תזכורת 3 שעות לפני התור</h4>
            
            </div>

            <p className={styles.textOne}>
              במידה ופעיל, המערכת תשלח ללקוח תזכורת 3 שעות לפני התור. שימו לב
              שהמערכת שולחת בכל און תזכורת 3 שעות לפני התור
            </p>

            <div className={styles.field}>
              <label>זמן ביטול תור מראש</label>
              <select
                name="is3HoursReminderDisabled"
                value={data.is3HoursReminderDisabled}
                onChange={handleChange}
              >
                <option value={true}>לא פעיל</option>
                <option value={false}>פעיל</option>
              </select>
            </div>
          </div>

          {/* BLOCK 5 */}
          {/* BUSINESS CANCELLATION PERIOD */}
          <div className={`${styles.block} ${styles.lastBlock}`}>
            <div className={styles.title}>
              <h4>זמן ביטול תור מראש</h4>
            </div>

            <p className={styles.textOne}>
              אנא בחרו את הזמן שבו הלקוחות יוכלו לבטל את התור שלהם מראש
            </p>

            <div className={styles.field}>
              <label>זמן ביטול תור מראש</label>
              <select
                name="businessCancellationPeriod"
                value={data.businessCancellationPeriod}
                onChange={handleChange}
              >
                <option value="15">15 דקות</option>
                <option value="30">30 דקות</option>
                <option value="45">45 דקות</option>
                <option value="60">1 שעה</option>
                <option value="90">שעה וחצי</option>
                <option value="120">2 שעות</option>
                <option value="150">שעתיים וחצי</option>
                <option value="180">3 שעות</option>
                <option value="360">6 שעות</option>
                <option value="720">12 שעות</option>
                <option value="1440">24 שעות</option>
                <option value="2880">48 שעות</option>
                <option value="4320">72 שעות</option>
                <option value="10080">1 שבוע</option>
              </select>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SectionOne;

import { useState, useEffect } from "react";
import Switch from "react-switch";
import { BellIcon1Svg } from "../../svgs/bellIcon1Svg";
import { GraduationHatIconSvg } from "../../svgs/graduationHatIconSvg";
import SERVICES from "../../api/services/SERVICES";
import { toast } from "react-toastify";
import Spinner from "../../components/spinner";
import styles from "../../styles/_containers/_services/create.module.scss";

const Create = ({ businessId, setResponseStatus, isOpen }) => {
  const [formdata, setFormdata] = useState({
    id: "",
    type: "REGULAR",
    name: "",
    description: "",
    time: "",
    price: "",
    maximumQueue: 1,
    employeeId: "",
    isExactPrice: true,
    restingTime: "",
    offsetTime: "",
    offsetNum: "",
    location: "",
    forSubscriptionOnly: false,
  });

  const [loading, setLoading] = useState(false);

  // IF EDIT, THEN FETCH SERVICE DETAILS
  useEffect(() => {
    if (isOpen !== "create") {
      const fetchData = async () => {
        try {
          const res = await SERVICES.get_by_id(isOpen);

          if (res.code === 200) {
            // split offset time string
            let offsetUnit = res.data.timeOffset
              ? res.data.timeOffset.slice(res.data.timeOffset.length - 1)
              : "";

            let offsetNum = res.data.timeOffset
              ? res.data.timeOffset.slice(0, res.data.timeOffset.length - 1)
              : "";

            setFormdata({
              type: res.data.type,
              name: res.data.name,
              description: res.data.description,
              time: res.data.time,
              price: res.data.price,
              maximumQueue: res.data.maximumQueue,
              isExactPrice: res.data.isExactPrice,
              restingTime: res.data.restingTime,
              offsetTime: offsetUnit,
              offsetNum: offsetNum,
              location: res.data.location ? res.data.location : "",
              forSubscriptionOnly: res.data.forSubscriptionOnly,
              id: res.data._id,
            });
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    } else {
      setFormdata({
        id: "",
        type: "REGULAR",
        name: "",
        description: "",
        time: "",
        price: "",
        maximumQueue: 1,
        employeeId: "",
        isExactPrice: true,
        restingTime: "",
        offsetTime: "",
        offsetNum: "",
        location: "",
        forSubscriptionOnly: false,
      });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setFormdata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // CREATE SERVICE
  const handleCreateService = async () => {
    let payload = {
      employeeId: businessId,
      type: formdata.type,
      name: formdata.name,
      description: formdata.description,
      time: formdata.time,
      price: formdata.price,
      maximumQueue: formdata.maximumQueue,
      isExactPrice: formdata.isExactPrice,
      restingTime: formdata.restingTime,
      timeOffset: formdata.offsetNum + formdata.offsetTime,
      location: formdata.location,
      forSubscriptionOnly: formdata.forSubscriptionOnly,
    };

    setLoading(true);
    try {
      const res = await SERVICES.create(payload);
      console.log(res);
      toast.success(res.data.message);

      setResponseStatus(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  // EDIT SERVICE
  const handleEditService = async () => {
    try {
      let payload = {
        employeeId: businessId,
        type: formdata.type,
        name: formdata.name,
        description: formdata.description,
        time: formdata.time,
        price: formdata.price,
        maximumQueue: formdata.maximumQueue,
        isExactPrice: formdata.isExactPrice,
        restingTime: formdata.restingTime,
        timeOffset: formdata.offsetNum + formdata.offsetTime,
        location: formdata.location,
        forSubscriptionOnly: formdata.forSubscriptionOnly,
        id: formdata.id,
      };
      setLoading(true);
      try {
        const res = await SERVICES.update(payload);
        toast.success(res.data.message);
        setResponseStatus(true);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2>צור שירות חדש עבור הלקוחות שלך</h2>
        <p className={styles.mainText}>ניתן לערוך או למחוק בכל עט לבחירתכם!</p>

        <div className={styles.types}>
          <h4>סוג השירות</h4>

          {/* TYPE */}
          <div className={styles.row}>
            {/* REGULAR */}
            <div
              className={`${styles.type} ${
                formdata.type === "REGULAR" ? styles.active : ""
              }`}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    type: "REGULAR",
                  };
                });
              }}
            >
              <BellIcon1Svg
                fill={formdata.type === "REGULAR" ? "#DE1468" : "#CFCFCF"}
              />
              <h5>שירות רגיל</h5>
              <button>נבחר</button>
            </div>
            {/* CLASS */}
            <div
              className={`${styles.type} ${
                formdata.type === "CLASS" ? styles.active : ""
              }`}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    type: "CLASS",
                  };
                });
              }}
            >
              <GraduationHatIconSvg
                fill={formdata.type === "CLASS" ? "#DE1468" : "#CFCFCF"}
              />
              <h5>שיעור</h5>
              <button>בחירה</button>
            </div>
          </div>
        </div>

        <div className={styles.form}>
          {/* NAME */}
          <div className={styles.field}>
            <label>
              שם השירות <span>*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="רשום את שם השירות לבחירתך"
              value={formdata.name}
              onChange={handleChange}
            />
          </div>
          {/* TIME */}
          <div className={styles.field}>
            <label>
              זמן השירות <span>*</span>
            </label>
            <input
              type="number"
              name="time"
              placeholder="רשום את זמן השירות בדקות (לדוגמא: 00:35)"
              value={formdata.time}
              onChange={handleChange}
            />
          </div>
          {/* PRICE */}
          <div className={styles.field}>
            <label>
              מחיר השירות <span>(אופציונלי)</span>
            </label>
            <div className={styles.fixedPrice}>
              <select
                name="isExactPrice"
                value={formdata.isExactPrice}
                onChange={handleChange}
              >
                <option value={true}>מחיר קבוע</option>
                <option value={false}>החל מ</option>
              </select>
              <input
                type="number"
                name="price"
                placeholder="₪150"
                value={formdata.price}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* OFFSET TIME */}
          <div className={styles.field}>
            <label>זמן קביעת תור עתידי</label>
            <div className={styles.fixedPrice}>
              <select
                name="offsetTime"
                value={formdata.offsetTime}
                onChange={handleChange}
              >
                <option value="h">שעות</option>
                <option value="d">ימים</option>
                <option value="w">שבועות</option>
                <option value="m">חודשים</option>
                <option value="y">שנים</option>
              </select>
              <input
                type="number"
                name="offsetNum"
                placeholder="1"
                value={formdata.offsetNum}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* DESCRIPTION */}
          <div className={styles.field}>
            <label>
              תיאור השרות <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="description"
              value={formdata.description}
              onChange={handleChange}
              placeholder="לדוגמא: ניקוי עמוק, נא להגיע עם חלוק"
            />
          </div>
          {/* LOCATION */}
          <div className={styles.field}>
            <label>
              מיקום <span>(אופציונלי)</span>
            </label>
            <input
              type="text"
              name="location"
              value={formdata.location}
              onChange={handleChange}
              placeholder="הזן את מיקום השירות"
            />
          </div>
          {/* REST TIME */}
          <div className={styles.field}>
            <label>
              זמן מנוחה/ הפסקה <span>(אופציונלי)</span>
            </label>
            <input
              type="number"
              name="restingTime"
              value={formdata.restingTime}
              onChange={handleChange}
              placeholder="הזן את זמני המנוחה לאחר השירות"
            />
          </div>
          {/* FOR SUBSCRIPTION ONLY */}
          <div className={styles.check}>
            <Switch
              name="forSubscriptionOnly"
              value={formdata.forSubscriptionOnly}
              checked={formdata.forSubscriptionOnly}
              onChange={(e) => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    forSubscriptionOnly: e,
                  };
                });
              }}
              offColor="#EBEBEB"
              onColor="#DE1468"
              checkedIcon={false}
              uncheckedIcon={false}
              className={styles.switch}
            />
            <p>כניסה למנוים בלבד</p>
          </div>
          {/* MAXIMUM QUEUE */}
          <div className={styles.numberOfCustomers}>
            <h4>
              כמה לקוחות יוכלו לקבוע תור בו זמנית לשירות זה <span>*</span>
            </h4>
            <div className={styles.counter}>
              <span
                onClick={() => {
                  setFormdata((prev) => {
                    return {
                      ...prev,
                      maximumQueue: prev.maximumQueue + 1,
                    };
                  });
                }}
              >
                +
              </span>
              <input type="text" value={formdata.maximumQueue} />
              <span
                onClick={() => {
                  setFormdata((prev) => {
                    return {
                      ...prev,
                      maximumQueue:
                        prev.maximumQueue > 1 ? prev.maximumQueue - 1 : 1,
                    };
                  });
                }}
              >
                -
              </span>
            </div>
          </div>

          {isOpen === "create" ? (
            <button className={styles.submitBtn} onClick={handleCreateService}>
              {loading ? <Spinner /> : "צור שירות חדש"}
            </button>
          ) : (
            <button className={styles.submitBtn} onClick={handleEditService}>
              {loading ? <Spinner /> : "שמירת נתונים"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Create;

import { get, post, put } from "../client";
import {
  GET_EMPLOYEE_APPOINTMENT_HISTORY,
  GET_PARTICIPANTS_OF_CLASS,
  MARK_ATTENDANCE,
} from "../routes";

export default {
  get_employee_appointment_history: async (data) =>
    await post(GET_EMPLOYEE_APPOINTMENT_HISTORY, data),

  get_participants_of_class: async (id) =>
    await get(GET_PARTICIPANTS_OF_CLASS(id)),

  mark_attendance: async (data) => await put(MARK_ATTENDANCE, data),
};

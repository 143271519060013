export const BagIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_5439)">
        <mask
          id="mask0_61_5439"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white" />
        </mask>
        <g mask="url(#mask0_61_5439)">
          <path
            d="M3.35964 5.50781C1.82019 5.50781 0.623818 6.84824 0.798154 8.37781L1.63882 15.7548C1.86374 17.7282 3.53374 19.2188 5.51995 19.2188H14.4861C16.4724 19.2188 18.1423 17.7282 18.3672 15.7548L19.2079 8.37781C19.3823 6.84824 18.1859 5.50781 16.6464 5.50781"
            stroke={fill}
            strokeWidth="1.5625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.53125 5.50781H13.6719V4.41406C13.6719 2.40773 12.0454 0.781251 10.0391 0.781251C8.03273 0.781251 6.40625 2.40773 6.40625 4.41406V7.30469"
            stroke={fill}
            strokeWidth="1.5625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.61719 11.3672C7.61719 11.9065 7.17996 12.3438 6.64062 12.3438C6.10129 12.3438 5.66406 11.9065 5.66406 11.3672C5.66406 10.8279 6.10129 10.3906 6.64062 10.3906C7.17996 10.3906 7.61719 10.8279 7.61719 11.3672Z"
            fill={fill}
          />
          <path
            d="M10.9766 11.3672C10.9766 11.9065 10.5393 12.3438 10 12.3438C9.46066 12.3438 9.02344 11.9065 9.02344 11.3672C9.02344 10.8279 9.46066 10.3906 10 10.3906C10.5393 10.3906 10.9766 10.8279 10.9766 11.3672Z"
            fill={fill}
          />
          <path
            d="M14.3359 11.3672C14.3359 11.9065 13.8987 12.3438 13.3594 12.3438C12.82 12.3438 12.3828 11.9065 12.3828 11.3672C12.3828 10.8279 12.82 10.3906 13.3594 10.3906C13.8987 10.3906 14.3359 10.8279 14.3359 11.3672Z"
            fill={fill}
          />
          <path
            d="M7.61719 14.7266C7.61719 15.2659 7.17996 15.7031 6.64062 15.7031C6.10129 15.7031 5.66406 15.2659 5.66406 14.7266C5.66406 14.1872 6.10129 13.75 6.64062 13.75C7.17996 13.75 7.61719 14.1872 7.61719 14.7266Z"
            fill={fill}
          />
          <path
            d="M10.9766 14.7266C10.9766 15.2659 10.5393 15.7031 10 15.7031C9.46066 15.7031 9.02344 15.2659 9.02344 14.7266C9.02344 14.1872 9.46066 13.75 10 13.75C10.5393 13.75 10.9766 14.1872 10.9766 14.7266Z"
            fill={fill}
          />
          <path
            d="M14.3359 14.7266C14.3359 15.2659 13.8987 15.7031 13.3594 15.7031C12.82 15.7031 12.3828 15.2659 12.3828 14.7266C12.3828 14.1872 12.82 13.75 13.3594 13.75C13.8987 13.75 14.3359 14.1872 14.3359 14.7266Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_61_5439">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

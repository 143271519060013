import { useState } from "react";
import { format } from "date-fns";
import { AddPlusSvg } from "../../svgs/addPlusSvg";
import styles from "../../styles/_containers/_clientMembership/membershipCard.module.scss";
import { toast } from "react-toastify";
import PLANS from "../../api/services/PLANS";
import Spinner from "../../components/spinner";

const MembershipCard = ({
  id,
  title,
  userId,
  price,
  isFrozen,
  disabled,
  createdAt,
  expiresAt,
  numberOfTurns,
  isSubscription,
  consumedOneTimeTurns,
  setResSuccess,
}) => {
  const [show, setShow] = useState(false);
  const [freezeLoading, setFreezeLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  // FREEZE OR UN-FREEZE PLAN
  const handleFreeze = async () => {
    let payload = {
      userServicePlanId: id,
      freeze: !isFrozen,
    };
    setFreezeLoading(true);
    try {
      const res = await PLANS.freezeUnFreezePlan(payload);
      toast.success(res.data.message);
      setResSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setFreezeLoading(false);
  };

  // CANCEL MEMBERSHIP
  const cancelMemberShip = async () => {
    let payload = {
      userId: userId,
      userServicePlanId: id,
    };
    setCancelLoading(true);
    try {
      const res = await PLANS.cancelMembership(payload);
      toast.success(res.data.message);
      setResSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setCancelLoading(false);
  };

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
      <div className={styles.title}>
        <h3>
          {title} | <span>{isSubscription ? "מנוי" : "כרטיסיה"}</span>
        </h3>

        {disabled && (
          <span
            className={styles.questionMark}
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
          >
            ?
          </span>
        )}
      </div>

      {show && (
        <div className={styles.tooltip}>
          {/* <img src="/assets/polygon.png" alt="polygon" /> */}
          <p>
            <span>שימו לב!</span> במנוי הוקפא ללקוח/ה
          </p>
        </div>
      )}

      <hr />

      <ul>
        {!isSubscription && (
          <li>
            <AddPlusSvg fill="#06A816" />
            {/* <p>מימוש - 50/12</p> */}
            <p>
              מימושים: {numberOfTurns} / {consumedOneTimeTurns || 0}
            </p>
          </li>
        )}
        {expiresAt && expiresAt !== "never" && (
          <li>
            <AddPlusSvg fill="#06A816" />
            <p>תוקף המינוי - עד {format(new Date(expiresAt), "dd/MM/yyyy")}</p>
          </li>
        )}
        <li>
          <AddPlusSvg fill="#06A816" />
          <p>מחיר - ₪{price}</p>
        </li>
        <li>
          <AddPlusSvg fill="#06A816" />
          <p>שוייך - עד {format(new Date(createdAt), "dd/MM/yyyy")}</p>
        </li>
      </ul>

      <div className={styles.btns}>
        <button onClick={handleFreeze}>
          {freezeLoading ? (
            <Spinner />
          ) : isFrozen ? (
            "הפשרת מנוי"
          ) : (
            "להקפיא מנוי"
          )}
        </button>
        {/* <button>חידוש מנוי</button> */}
        <button onClick={cancelMemberShip}>
          {cancelLoading ? <Spinner /> : "ביטול מנוי"}
        </button>
      </div>
    </div>
  );
};

export default MembershipCard;

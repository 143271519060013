import { useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { generateProfileImgUrl } from "../../utils/generateProfileImgUrl";
import { ClockIcon1Svg } from "../../svgs/clockIcon1Svg";
import { Calendar } from "react-calendar";
import SCHEDULE from "../../api/services/SCHEDULE";
import APPOINTMENTS from "../../api/services/APPOINTMENTS";
import Spinner from "../../components/spinner";
import styles from "../../styles/_containers/_createAppointment/selectTime.module.scss";
import { CustomSlotsModal } from "../../components/customSlotsModal";
import { calculateEndTimeOfAppointment } from "../../utils/calculateEndTimeOfAppointment";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";

const SelectTime = ({
  currentClient,
  currentEmployee,
  businessId,
  currentService,
  timeSlot,
  setTimeSlot,
  appDate,
  setAppDate,
  setStep,
  repeatPeriod,
  setRepeatPeriod,
  expiresIn,
  setExpiresIn,
  setIsCustomSlotModalOpen,
  customSlotValue,
}) => {
  const navigate = useNavigate();
  const slotRef = useRef();
  const createRef = useRef();

  const [timeSlots, setTimeSlots] = useState(null);
  const [createAppointmentLoading, setCreateAppointmentLoading] =
    useState(false);

  const [expiredInError, setExpiredInError] = useState(null);

  const [timeslotsError, setTimeslotsError] = useState(null);

  // FETCH TIMESLOTS
  useEffect(() => {
    if (currentEmployee && currentService && appDate) {
      const fetchData = async () => {
        try {
          const payload = {
            employeeId: currentEmployee.id,
            serviceId: currentService._id,
            date: format(new Date(appDate), "yyyy-MM-dd"),
          };
          const res = await SCHEDULE.get_timeslots(payload);
          let unavailableSlots = res.data.data.detailedRecords;
          let availableSlots = res.data.data.records.map((i) => {
            return {
              slot: i,
              available: true,
            };
          });

          // merge both arrays then check and remove the same slot value arrays
          let mergedArray = [...unavailableSlots, ...availableSlots].reduce(
            (acc, slot) => {
              const foundSlot = acc.find((s) => s.slot === slot.slot);
              if (!foundSlot) {
                acc.push(slot);
              }
              return acc;
            },
            []
          );

          setTimeSlots(mergedArray);
          slotRef.current?.scrollIntoView({ behavior: "smooth" });
        } catch (error) {
          console.log(error);
          setTimeslotsError(error.response.data.message);
        }
      };

      fetchData();
    }
  }, [currentEmployee, currentService, appDate]);

  const handleCreateAppointment = async () => {
    setCreateAppointmentLoading(true);

    let payload = {
      employeeId: currentEmployee.id,
      userId: currentClient.userId,
      serviceId: currentService._id,
      businessId: businessId,
      date: format(new Date(appDate), "yyyy-MM-dd"),
      timeSlot: timeSlot,
    };

    if (repeatPeriod) {
      payload.repeatPeriod = repeatPeriod;

      if (!expiresIn) {
        setExpiredInError("חובה לבחור תוקף");
      } else {
        payload.expiresIn = expiresIn;
      }
    }

    if (!repeatPeriod || (repeatPeriod && expiresIn)) {
      try {
        const res = await APPOINTMENTS.create(payload);
        toast.success(res.data.message);
        setExpiredInError(null);
        navigate(paths.appointments)
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    }
    setCreateAppointmentLoading(false);
  };

  return (
    <div className={styles.container}>
      <h1>בחירת יום ושעה</h1>

      <p className={styles.mainText}>ניתן לבחור פעם אחד בלבד</p>

      <div className={styles.card}>
        {/* SUMMARY */}
        <Summary
          currentEmployee={currentEmployee}
          currentService={currentService}
        />

        {/* CALENDAR */}
        <CalendarComponent
          dateValue={appDate}
          onChangeDateValue={setAppDate}
          currentEmployee={currentEmployee}
        />

        {/* TIMESLOTS */}
        {appDate && (
          <TimeSlots
            slots={timeSlots}
            setSlots={setTimeSlots}
            timeslot={timeSlot}
            setTimeslot={setTimeSlot}
            dateValue={appDate}
            currentService={currentService}
            currentEmployee={currentEmployee}
            slotRef={slotRef}
            createRef={createRef}
            timeslotsError={timeslotsError}
            setIsCustomSlotModalOpen={setIsCustomSlotModalOpen}
            customSlotValue={customSlotValue}
          />
        )}

        {/* DROPDOWNS */}
        <Dropdowns
          repeatPeriod={repeatPeriod}
          setRepeatPeriod={setRepeatPeriod}
          expiresIn={expiresIn}
          setExpiresIn={setExpiresIn}
          expiredInError={expiredInError}
        />
      </div>

      <div className={styles.btns} ref={createRef}>
        <button onClick={() => setStep(2)}>הקודם</button>
        {timeSlot && (
          <button onClick={handleCreateAppointment}>
            {createAppointmentLoading ? <Spinner /> : "קבעו לי תור"}
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectTime;

// SUMMARY COMPONENT
const Summary = ({ currentEmployee, currentService }) => {
  return (
    <div className={styles.summary}>
      <div className={styles.employee}>
        {generateProfileImgUrl(currentEmployee.profile, currentEmployee.name)}
        <p style={{ lineHeight: "100%" }}>{currentEmployee.name}</p>
      </div>

      <span className={`${styles.line} ${styles.firstLine}`} />

      <div className={styles.service}>
        <h5>{currentService.name}</h5>
        <p>{currentService.description}</p>
      </div>

      <span className={`${styles.line} ${styles.secondLine}`} />

      <div className={styles.serviceOthers}>
        <div
          className={styles.block}
          style={{
            border: `1px solid #DE1468`,
            backgroundColor: "rgba(222, 20, 104, 0.15)",
          }}
        >
          <ClockIcon1Svg fill={"#DE1468"} />
          <p style={{ color: "#DE1468" }}>{currentService.time} דקות</p>
        </div>
        {currentService.price !== "0" && (
          <div
            className={styles.block}
            style={{
              border: `1px solid #DE1468`,
              backgroundColor: "rgba(222, 20, 104, 0.15)",
            }}
          >
            <p style={{ color: "#DE1468" }}>
              {currentService.isExactPrice ? " " : "החל מ-"}₪
              {currentService.price}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

// TIMESLOTS COMPONENT
const TimeSlots = ({
  slots,
  timeslot,
  setTimeslot,
  dateValue,
  currentService,
  currentEmployee,
  slotRef,
  createRef,
  timeslotsError,
  setIsCustomSlotModalOpen,
  customSlotValue,
}) => {
  const [isHovered, setIsHovered] = useState(null);
  const [filteredSlots, setFilteredSlots] = useState([]);

  // REMOVE THE CURRENT DAY SLOTS THAT ARE IN PAST
  useEffect(() => {
    if (new Date(dateValue).getDate() === new Date().getDate()) {
      // if any slot is in past then remove it
      let updatedSlots = slots?.filter((i) => {
        // convert both timeslot and current time to minutes past midnight
        let slotMinutes =
          parseInt(i.slot.slice(0, 2)) * 60 + parseInt(i.slot.slice(3, 5));
        let currentMinutes =
          new Date().getHours() * 60 + new Date().getMinutes();

        if (slotMinutes > currentMinutes) {
          return i;
        }
      });

      setFilteredSlots(updatedSlots);
    } else {
      setFilteredSlots(slots);
    }
  }, [dateValue, slots, currentService, currentEmployee]);

  // handle slot click
  const handleClick = (i) => {
    if (i.available) {
      setTimeslot(i.slot);
      createRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={styles.slots} ref={slotRef}>
      <h4>בחירת שעה</h4>

      {!timeslotsError && (
        <div className={styles.customTime}>
          <h3>לבחירת שעה מותאמת אישית</h3>
          <button onClick={() => setIsCustomSlotModalOpen(true)}>
            לחצו כאן
          </button>
        </div>
      )}

      {timeslotsError && (
        <p className={styles.timeslotsError}>{timeslotsError}</p>
      )}

      {customSlotValue && (
        <div className={styles.timeRow}>
          <img src="/assets/clock-icon-1.svg" />
          <p>{calculateEndTimeOfAppointment(timeslot, currentService.time)}</p>
          <span>-</span>
          <p>{timeslot}</p>
        </div>
      )}

      <div className={styles.items}>
        {filteredSlots?.map((i) => (
          <div
            key={i.slot}
            className={styles.slot}
            onClick={() => handleClick(i)}
            style={{
              border:
                timeslot === i.slot
                  ? "1px solid #DE1468"
                  : isHovered === i.slot
                  ? "1px solid #DE1468"
                  : !i.available
                  ? "1px solid #E50000"
                  : "",
              backgroundColor: !i.available ? "#E50000" : "",
              cursor: !i.available ? "not-allowed" : "pointer",
            }}
            onMouseEnter={() => setIsHovered(i.slot)}
            onMouseLeave={() => setIsHovered(null)}
          >
            <p
              style={{
                color:
                  timeslot === i.slot
                    ? "#DE1468"
                    : isHovered === i.slot
                    ? "#DE1468"
                    : !i.available
                    ? "#ffffff"
                    : "",
              }}
            >
              {i.slot}
              <br />
            </p>
          </div>
        ))}

        {/* IF ALL SLOTS OF THE CURRENT DAY ARE IN PAST */}
        {(filteredSlots?.length === 0 ||
          filteredSlots?.every((s) => !s.available)) && (
          <div className={styles.noSlots}>
            <p>לא נותרו תורים ביום זה</p>
          </div>
        )}
      </div>
    </div>
  );
};

// CALENDAR COMPONENT
const CalendarComponent = ({ dateValue, onChangeDateValue }) => {
  return (
    <div className={styles.calendar}>
      <h4>בחירת יום</h4>
      <Calendar
        value={dateValue}
        onChange={onChangeDateValue}
        className={`mainCalendar ${styles.cal}`}
        calendarType="Hebrew"
        formatDay={(locale, date) => format(date, "d")}
        next2Label={null}
        prev2Label={null}
        nextLabel="<"
        prevLabel=">"
        formatShortWeekday={(locale, date) => {
          if (format(date, "E").toLowerCase() === "sun") {
            return "א";
          } else if (format(date, "E").toLowerCase() === "mon") {
            return "ב";
          } else if (format(date, "E").toLowerCase() === "tue") {
            return "ג";
          } else if (format(date, "E").toLowerCase() === "wed") {
            return "ד";
          } else if (format(date, "E").toLowerCase() === "thu") {
            return "ה";
          } else if (format(date, "E").toLowerCase() === "fri") {
            return "ו";
          } else if (format(date, "E").toLowerCase() === "sat") {
            return "ש";
          }
        }}
        formatMonthYear={(locale, date) => {
          if (format(date, "MMMM yyyy") === `January ${format(date, "yyyy")}`) {
            return `${format(date, "yyyy")} ינואר`;
          } else if (
            format(date, "MMMM yyyy") === `February ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} פברואר`;
          } else if (
            format(date, "MMMM yyyy") === `March ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} מרץ`;
          } else if (
            format(date, "MMMM yyyy") === `April ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} אפריל`;
          } else if (
            format(date, "MMMM yyyy") === `May ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} מאי`;
          } else if (
            format(date, "MMMM yyyy") === `June ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} יוני`;
          } else if (
            format(date, "MMMM yyyy") === `July ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} יולי`;
          } else if (
            format(date, "MMMM yyyy") === `August ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} אוגוסט`;
          } else if (
            format(date, "MMMM yyyy") === `September ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} ספטמבר`;
          } else if (
            format(date, "MMMM yyyy") === `October ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} אוקטובר`;
          } else if (
            format(date, "MMMM yyyy") === `November ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} נובמבר`;
          } else if (
            format(date, "MMMM yyyy") === `December ${format(date, "yyyy")}`
          ) {
            return `${format(date, "yyyy")} דצמבר`;
          }
        }}
        minDate={new Date()}
      />
    </div>
  );
};

// ADDITIONAL DROPDOWNS
const Dropdowns = ({
  repeatPeriod,
  setRepeatPeriod,
  expiresIn,
  setExpiresIn,
  expiredInError,
}) => {
  let repeatingTime = [
    { label: "ללא חזרתיות", value: "" },
    { label: "כל יום", value: "1d" },
    { label: "כל יומיים", value: "2d" },
    { label: "כל שלושה ימים", value: "3d" },
    { label: "כל שבוע", value: "7d" },
    { label: "כל שבועיים", value: "14d" },
    { label: "כל 3 שבועות", value: "21d" },
    { label: "כל חודש", value: "1m" },
    { label: "כל חודשיים", value: "2m" },
    { label: "כל שלושה חודשים", value: "3m" },
    { label: "כל חצי שנה", value: "6m" },
    { label: "כל שנה", value: "1y" },
  ];

  let expiryTime = [
    { label: "שבוע", value: "1w" },
    { label: "שבועיים", value: "2w" },
    { label: "3 שבועות", value: "3w" },
    { label: "חודש", value: "1m" },
    { label: "3 חודשים", value: "3m" },
    { label: "6 חודשים", value: "6m" },
    { label: "שנה", value: "1y" },
  ];

  return (
    <div className={styles.dropdowns}>
      <h3>תור קבוע</h3>
      <h4>במידה והתור קבוע יש לבחור את זמן החזרתיות ואת התקופה</h4>

      <div className={styles.row}>
        <select
          name="repeatPeriod"
          value={repeatPeriod}
          onChange={(e) => setRepeatPeriod(e.target.value)}
        >
          {repeatingTime.map((i, idx) => (
            <option value={i.value} key={idx}>
              {i.label}
            </option>
          ))}
        </select>

        {repeatPeriod && (
          <select
            name="expiresIn"
            value={expiresIn}
            onChange={(e) => setExpiresIn(e.target.value)}
          >
            {expiryTime.map((i, idx) => (
              <option value={i.value} key={idx}>
                {i.label}
              </option>
            ))}
          </select>
        )}
      </div>
      <small className={styles.error}>{expiredInError}</small>
    </div>
  );
};

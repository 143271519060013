import { useEffect, useState } from "react";
import Modal from "./modal";
import {
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import PROFILE from "../api/services/PROFILE";
import styles from "../styles/_components/shareModal.module.scss";

export const ShareModal = ({ setIsOpen }) => {
  const [businessCode, setBusinessCode] = useState(null);

  // FETCH PROFILE DETAILS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await PROFILE.get();
        setBusinessCode(res.data.businessId);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Modal setIsOpen={setIsOpen} onClick={() => null}>
        <div className={styles.container}>
          <h2>שיתוף העסק</h2>

          <div className={styles.items}>
            <div>
              <FacebookShareButton
                url={`http://app.tor.digital/${businessCode}`}
              >
                <FacebookIcon />
              </FacebookShareButton>
            </div>
            <div>
              <WhatsappShareButton
                url={`http://app.tor.digital/${businessCode}`}
              >
                <WhatsappIcon />
              </WhatsappShareButton>
            </div>
            <div>
              <EmailShareButton
                url={`http://app.tor.digital/${businessCode}`}
                subject={`http://app.tor.digital/${businessCode}`}
                body={`http://app.tor.digital/${businessCode}`}
              >
                <EmailIcon />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

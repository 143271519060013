import { hebrewDayNameGenerator } from "../../utils/hebrewDayNameGenerator";
import Day from "./day";
import styles from "../../styles/_containers/_workingHours/card.module.scss";

const Card = ({ data, setData, allServices }) => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>בחירת ימי עבודה, שעות קבלת קהל והשירותים</p>

      <div className={styles.days}>
        {data?.map((i) => (
          <Day
            key={i._id}
            id={i._id}
            dayName={hebrewDayNameGenerator(i.name)}
            status={i.status}
            timing={i.timing}
            setData={setData}
            allServices={allServices}
          />
        ))}
      </div>
    </div>
  );
};

export default Card;

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SERVICES from "../../api/services/SERVICES";
import PLANS from "../../api/services/PLANS";
import Spinner from "../../components/spinner";
import styles from "../../styles/_containers/_memberships/create.module.scss";

const Create = () => {
  const [formdata, setFormdata] = useState({
    name: "",
    price: "",
    type: "",
    expiryNum: "",
    expiryPeriod: "",
    numOfEntries: "",
    desc: "",
    service: "",
    serviceIds: [],
    resNumOfEntries: "",
    resNumberOfEntriesFor: "",
    resFormatForEntries: "",
    unavailbleForDaysOfWeek: [],
  });

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  // FETCH SERVICES
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SERVICES.get_all({ business_id: businessId });
        setServices(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // HANDLE INPUT CHANGE
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormdata((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (name === "service") {
      setFormdata((prev) => {
        return {
          ...prev,
          serviceIds: prev.serviceIds.includes(value)
            ? prev.serviceIds.filter((j) => j !== value)
            : [...prev.serviceIds, value],
        };
      });
    }
  };

  // HANDLE SUBMIT
  const handleSubmit = async () => {
    let payload = {
      restrictedToServiceIds: formdata.serviceIds,
      unavailbleForDaysOfWeek: formdata.unavailbleForDaysOfWeek,
      restrictNumberOfTurns: true,
      restrictedNumberOfTurns: formdata.resNumOfEntries,
      restrictNumberOfTurnsFor:
        formdata.resNumberOfEntriesFor + formdata.resFormatForEntries,
      price: formdata.price,
      expiresIn: formdata.expiryNum + formdata.expiryPeriod,
      isSubscription: formdata.type,
      numberOfTurns: formdata.numOfEntries,
      name: formdata.name,
      description: formdata.desc,
    };

    setLoading(true);
    try {
      const res = await PLANS.create_plan(payload);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <h2>יצירת כרטיס מנוי חדש</h2>
      <p className={styles.text}>
        לאחר יצירת המנוי תוכלו לצפות בו בעמוד המינוים שם ניתן לערוך ולשייך את
        המנוי ללקוחות
      </p>

      <div className={styles.form}>
        {/* SUBSCRIBER NAME */}
        <div className={styles.field}>
          <label>
            שם המנוי <span>*</span>
          </label>
          <input
            type="text"
            name="name"
            value={formdata.name}
            onChange={handleChange}
            placeholder="רשום את שם העובד"
          />
        </div>

        {/* PRICE */}
        <div className={styles.field}>
          <label>מחיר מנוי</label>
          <input
            type="text"
            name="price"
            value={formdata.price}
            onChange={handleChange}
            placeholder="מחיר"
          />
        </div>

        {/* TYPE */}
        <div className={styles.field}>
          <label>סוג המנוי</label>
          <select name="type" value={formdata.type} onChange={handleChange}>
            <option value="" disabled selected>
              סוג המנוי
            </option>
            <option value={true}>מנוי</option>
            <option value={false}>כרטיסיה</option>
          </select>
        </div>

        {/* EXPIRY */}
        <div className={styles.field}>
          <label>תוקף (בימים)</label>
          <div className={styles.row}>
            <input
              type="text"
              name="expiryNum"
              value={formdata.expiryNum}
              onChange={handleChange}
              placeholder="מספר"
            />
            <select
              name="expiryPeriod"
              value={formdata.expiryPeriod}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                תקופה
              </option>
              <option value="d">ימים</option>
              <option value="w">שבועות</option>
              <option value="m">חודשים</option>
              <option value="y">שנים</option>
            </select>
          </div>
        </div>

        {/* NUMBER OF ENTRIES */}
        <div className={styles.field}>
          <label>מספר כניסות</label>
          <input
            type="text"
            name="numOfEntries"
            value={formdata.numOfEntries}
            onChange={handleChange}
            placeholder="רשום את מספר הכניסות לכרטיסיה"
          />
        </div>

        {/* DESCRIPTION */}
        <div className={styles.field}>
          <label>תיאור המנוי</label>
          <input
            type="text"
            name="desc"
            value={formdata.desc}
            onChange={handleChange}
            placeholder="לא חובה, אבל תמיד טוב"
          />
        </div>
      </div>

      {/* RESTRICTIONS CARD */}
      <div className={styles.card}>
        <h4>
          מגבלות כניסה/ הרשמה <span>(אופציונלי)</span>
        </h4>

        {/* SERVICES */}
        <div className={styles.field}>
          <label>לאן ניתן להרשם/להיכנס</label>
          <select
            name="service"
            value={formdata.service}
            onChange={handleChange}
          >
            <option value="" disabled selected>
              כניסה לכל השיעורים
            </option>
            {services?.map((i) => (
              <option value={i._id}>{i.name}</option>
            ))}
          </select>

          <div className={styles.services}>
            {formdata.serviceIds.map((x) => (
              <p>{services?.find((i) => i._id === x).name}</p>
            ))}
          </div>
        </div>

        <h4>הלקוח/ה מוגבל/ת לעד x כניסות בתקופה של y</h4>

        {/* NUMBER OF ENTRANCES */}
        <div className={styles.field}>
          <label>מספר כניסות</label>
          <input
            type="text"
            placeholder="עד"
            name="resNumOfEntries"
            value={formdata.resNumOfEntries}
            onChange={handleChange}
          />
        </div>

        {/* RESTRICT NUMBER OF ENTRANCES FOR */}
        <div className={styles.field}>
          <label>בתקופה של</label>
          <input
            type="text"
            name="resNumberOfEntriesFor"
            value={formdata.resNumberOfEntriesFor}
            onChange={handleChange}
            placeholder="מספר הימים /שבועות / חודשים…"
          />
        </div>

        {/* FORMAT FOR RESTRICT NUMBER OF ENTRANCES */}
        <div className={styles.periods}>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    resFormatForEntries: "d",
                  };
                });
              }}
            >
              {formdata.resFormatForEntries === "d" && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>ימים</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    resFormatForEntries: "w",
                  };
                });
              }}
            >
              {formdata.resFormatForEntries === "w" && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>שבועות</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    resFormatForEntries: "m",
                  };
                });
              }}
            >
              {formdata.resFormatForEntries === "m" && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>חודשיים</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    resFormatForEntries: "y",
                  };
                });
              }}
            >
              {formdata.resFormatForEntries === "y" && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>שנים</p>
          </div>
        </div>

        {/* UNAVAILABLE FOR DAYS OF WEEK */}
        <h4>כניסת המנוי / כרטיסייה תינתן רק בימים</h4>

        <div className={styles.periods}>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    unavailbleForDaysOfWeek:
                      prev.unavailbleForDaysOfWeek.includes(0)
                        ? prev.unavailbleForDaysOfWeek.filter((j) => j !== 0)
                        : [...prev.unavailbleForDaysOfWeek, 0],
                  };
                });
              }}
            >
              {formdata.unavailbleForDaysOfWeek.includes(0) && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>א׳</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    unavailbleForDaysOfWeek:
                      prev.unavailbleForDaysOfWeek.includes(1)
                        ? prev.unavailbleForDaysOfWeek.filter((j) => j !== 1)
                        : [...prev.unavailbleForDaysOfWeek, 1],
                  };
                });
              }}
            >
              {formdata.unavailbleForDaysOfWeek.includes(1) && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>ב׳</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    unavailbleForDaysOfWeek:
                      prev.unavailbleForDaysOfWeek.includes(2)
                        ? prev.unavailbleForDaysOfWeek.filter((j) => j !== 2)
                        : [...prev.unavailbleForDaysOfWeek, 2],
                  };
                });
              }}
            >
              {formdata.unavailbleForDaysOfWeek.includes(2) && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>ג׳</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    unavailbleForDaysOfWeek:
                      prev.unavailbleForDaysOfWeek.includes(3)
                        ? prev.unavailbleForDaysOfWeek.filter((j) => j !== 3)
                        : [...prev.unavailbleForDaysOfWeek, 3],
                  };
                });
              }}
            >
              {formdata.unavailbleForDaysOfWeek.includes(3) && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>ד׳</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    unavailbleForDaysOfWeek:
                      prev.unavailbleForDaysOfWeek.includes(4)
                        ? prev.unavailbleForDaysOfWeek.filter((j) => j !== 4)
                        : [...prev.unavailbleForDaysOfWeek, 4],
                  };
                });
              }}
            >
              {formdata.unavailbleForDaysOfWeek.includes(4) && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>ה׳</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    unavailbleForDaysOfWeek:
                      prev.unavailbleForDaysOfWeek.includes(5)
                        ? prev.unavailbleForDaysOfWeek.filter((j) => j !== 5)
                        : [...prev.unavailbleForDaysOfWeek, 5],
                  };
                });
              }}
            >
              {formdata.unavailbleForDaysOfWeek.includes(5) && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>ו׳</p>
          </div>
          <div className={styles.period}>
            <div
              className={styles.check}
              onClick={() => {
                setFormdata((prev) => {
                  return {
                    ...prev,
                    unavailbleForDaysOfWeek:
                      prev.unavailbleForDaysOfWeek.includes(6)
                        ? prev.unavailbleForDaysOfWeek.filter((j) => j !== 6)
                        : [...prev.unavailbleForDaysOfWeek, 6],
                  };
                });
              }}
            >
              {formdata.unavailbleForDaysOfWeek.includes(6) && (
                <img src="/assets/check.svg" alt="check" />
              )}
            </div>
            <p>ש׳</p>
          </div>
        </div>
      </div>

      {/* BUTTON */}
      <button onClick={handleSubmit}>
        {loading ? <Spinner /> : "צור כרטיסיית מנוי חדש"}
      </button>
    </div>
  );
};

export default Create;

import Switch from "react-switch";
import styles from "../../../styles/_containers/_workingHours/day/dayName.module.scss";

const DayName = ({ dayId, dayName, status, setData, isOpen, onClick }) => {
  // HANDLE SWITCH BUTTON CHANGE
  const handleChange = (e) => {
    setData((prev) =>
      prev.map((obj) => {
        if (obj._id === dayId) {
          if (obj.status) {
            return { ...obj, status: e };
          } else {
            if (obj.timing.length > 0 && obj.timing[0].services.length > 0) {
              return { ...obj, status: e };
            } else {
              alert("Please add services");
            }
          }
        }
        return obj;
      })
    );
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <Switch
        onChange={(e) => handleChange(e)}
        checked={status}
        offColor="#EBEBEB"
        onColor="#DE1468"
        checkedIcon={false}
        uncheckedIcon={false}
        className={styles.switch}
      />
      <p>{dayName}</p>

      <span className={styles.arrow}>
        <img
          src={`/assets/${isOpen ? "up" : "down"}-arrow.svg`}
          alt="up-arrow"
        />
      </span>
    </div>
  );
};

export default DayName;

import styles from "../../styles/_containers/_memberships/nav.module.scss";

const Nav = ({ step, setStep }) => {
  return (
    <div className={styles.container}>
      <ul>
        <li
          className={`${step === 0 ? styles.active : ""}`}
          onClick={() => setStep(0)}
        >
          כל המינוים שלי
        </li>
        {/* <li>לקוחות מינוים</li> */}
        <li
          className={`${step === 2 ? styles.active : ""}`}
          onClick={() => setStep(2)}
        >
          צור מינוי חדש
        </li>
      </ul>
    </div>
  );
};

export default Nav;

import styles from "../styles/_components/sideMenu.module.scss";

const SideMenu = ({ list }) => {
  return (
    <div className={styles.container}>
      {list.map((i) => (
        <div
          key={i.id}
          className={`${styles.item} ${i.active ? styles.active : ""}`}
          onClick={i.onClick}
          style={{ backgroundColor: i.type === 2 ? "#ff0000" : "" }}>
          {i.svg}
          <p style={{ color: i.type === 2 ? "#ffffff" : "" }}>{i.text}</p>
        </div>
      ))}
    </div>
  );
};

export default SideMenu;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateFirstLetterBox } from "../../utils/generateFirstLetterBox";
import { redirectToWhatsapp } from "../../utils/redirectToWhatsapp";
import { paths } from "../../routes/paths";
import styles from "../../styles/_containers/_clients/clientCard.module.scss";

const ClientCard = ({
  id,
  userId,
  businessId,
  phone,
  name,
  img,
  setIsOpenModal,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={styles.container}
      style={{ border: isOpen ? "1px solid #DE1468" : "" }}
    >
      {/* HEAD */}
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles.right}>
          {generateFirstLetterBox(name)}
          <div className={styles.name}>
            <h3>{name}</h3>
            <p>{phone}</p>
            {/* <p>מס’ לקוח - 22456</p> */}
          </div>
        </div>
        <div className={styles.left}>
          <div className={styles.edit}>
            <img src="/assets/edit-icon-1.svg" alt="edit-icon" />
            <p>עריכה</p>
          </div>
          <span className={styles.arrow}>
            <img
              src={`/assets/${isOpen ? "up" : "down"}-arrow.svg`}
              alt="up-arrow"
            />
          </span>
        </div>
      </div>

      {/* BODY */}
      <div className={`${styles.body} ${isOpen ? styles.open : styles.hide}`}>
        <hr className={styles.line1} />

        <ul>
          {/* PHONE */}
          <a href={`tel:+972${phone}`}>
            <li>
              <img src="/assets/phone-icon-1.svg" alt="phone" />
              <p>חיוג ללקוח/ה</p>
            </li>
          </a>

          {/* WHATSAPP */}
          <li onClick={() => redirectToWhatsapp(phone)}>
            <img src="/assets/whatsapp-icon-1.svg" alt="whatsapp" />
            <p>שלח הודעת וואטסאפ</p>
          </li>

          {/* MAKE APPOINTMENT FOR CUSTOMER */}
          <li
            onClick={() => {
              localStorage.setItem(
                "selectedClient",
                JSON.stringify({
                  userId,
                  name,
                })
              );
              navigate(paths.createAppointment);
            }}
          >
            <img src="/assets/add-icon-1.svg" alt="add" />
            <p>קבע תור ללקוח</p>
          </li>

          {/* CUSTOMER CARD AND APPOINTMENTS */}
          <li onClick={() => navigate(paths.clientMemberShip(userId))}>
            <img src="/assets/books-icon-1.svg" alt="books" />
            <p>כרטיס לקוח ומינויים</p>
          </li>

          {/* DOCUMENTS */}
          <li>
            <img src="/assets/news-icon-1.svg" alt="news" />
            <p>מסמכים הצהרות ואישורים</p>
          </li>

          {/* HISTORY */}
          <li onClick={() => navigate(paths.clientHistory(userId))}>
            <img src="/assets/history-icon-1.svg" alt="history" />
            <p>היסטוריית תורים</p>
          </li>

          {/* DELETE */}
          <li onClick={() => setIsOpenModal(userId)}>
            <img src="/assets/remove-icon-1.svg" alt="remove" />
            <p>מחיקת לקוח/ה</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ClientCard;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import SideMenu from "../components/sideMenu";
import Layout from "../layouts/layout";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import { CalendarIcon1Svg } from "../svgs/calendarIcon1Svg";
import { ClockIcon1Svg } from "../svgs/clockIcon1Svg";
import { SettingsIcon1Svg } from "../svgs/settingsIcon1Svg";
import Stepper from "../components/stepper";
import Clients from "../containers/_createAppointment/clients";
import Employees from "../containers/_createAppointment/employees";
import Services from "../containers/_createAppointment/services";
import SelectTime from "../containers/_createAppointment/selectTime";
import SearchClient from "../containers/_createAppointment/searchClient";
import styles from "../styles/_screens/createAppointment.module.scss";
import { CustomSlotsModal } from "../components/customSlotsModal";

const CreateAppointment = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);

  const [currentClient, setCurrentClient] = useState(null);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [timeSlot, setTimeSlot] = useState(null);
  const [appDate, setAppDate] = useState();
  const [repeatPeriod, setRepeatPeriod] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);

  const [search, setSearch] = useState("");

  const sideMenuList = [
    {
      id: 1,
      svg: <AddPlusSvg fill="#DE1468" />,
      text: "הוספת תור חדש",
      active: true,
    },
    {
      id: 2,
      svg: <CalendarIcon1Svg fill="#000000" />,
      text: "יומן שבועי",
      active: false,
      onClick: () => navigate(paths.appointments),
    },
    {
      id: 3,
      svg: <SettingsIcon1Svg fill="#000000" />,
      text: "הגדרות יומן ותורים",
      active: false,
      onClick: () => navigate(paths.settings),
    },
    {
      id: 4,
      svg: <ClockIcon1Svg fill="#000000" />,
      text: "הגדרות קבלת קהל",
      active: false,
      onClick: () => navigate(paths.workingHours),
    },
  ];

  // IF SELECTED CLIENT EXISTS, REDIRECT TO EMPLOYEES
  useEffect(() => {
    let clientFromStorage = localStorage.getItem("selectedClient");
    if (clientFromStorage) {
      setStep(1);
      setCurrentClient(JSON.parse(clientFromStorage));
      localStorage.removeItem("selectedClient");
    }
  }, []);

  const [isCustomSlotModalOpen, setIsCustomSlotModalOpen] = useState(false);
  const [customSlotValue, setCustomSlotValue] = useState(null);

  const handleCustomSlotChange = (e) => {
    setCustomSlotValue(e.target.value);
    setTimeSlot(e.target.value);
    setIsCustomSlotModalOpen(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.sections}>
            <Stepper step={step} />

            {step === 0 && (
              <SearchClient search={search} setSearch={setSearch} />
            )}

            {step === 0 ? (
              <Clients
                currentClient={currentClient}
                setCurrentClient={setCurrentClient}
                setStep={setStep}
                search={search}
              />
            ) : step === 1 ? (
              <Employees
                currentEmployee={currentEmployee}
                setCurrentEmployee={setCurrentEmployee}
                setBusinessId={setBusinessId}
                setStep={setStep}
              />
            ) : step === 2 ? (
              <Services
                currentEmployee={currentEmployee}
                currentService={currentService}
                setCurrentService={setCurrentService}
                setStep={setStep}
              />
            ) : (
              <SelectTime
                currentClient={currentClient}
                currentEmployee={currentEmployee}
                businessId={businessId}
                currentService={currentService}
                timeSlot={timeSlot}
                setTimeSlot={setTimeSlot}
                appDate={appDate}
                setAppDate={setAppDate}
                setStep={setStep}
                repeatPeriod={repeatPeriod}
                setRepeatPeriod={setRepeatPeriod}
                expiresIn={expiresIn}
                setExpiresIn={setExpiresIn}
                setIsCustomSlotModalOpen={setIsCustomSlotModalOpen}
                customSlotValue={customSlotValue}
              />
            )}
          </div>
        </div>
      </div>

      {isCustomSlotModalOpen && (
        <CustomSlotsModal
          setIsOpen={setIsCustomSlotModalOpen}
          customSlotValue={customSlotValue}
          handleChange={handleCustomSlotChange}
        />
      )}
    </Layout>
  );
};

export default CreateAppointment;

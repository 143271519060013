import { useNavigate } from "react-router-dom";
import styles from "../../styles/_containers/_subscription/helpCard.module.scss";
import { paths } from "../../routes/paths";

const HelpCard = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <div className={styles.right}>
        <img src="/assets/help-img-1.png" alt="help" />
      </div>
      <div className={styles.left}>
        <h4>זקוק לעזרה?</h4>
        <p>
          לכל שאלה או בקשה אנו זמינים עבורכם!
          <br />
          יש לכם שאלה? מוזמנים לבקר במרכז ההדרכה שלנו!
        </p>
        <button onClick={() => navigate(paths.contact)}>מעבר לשירות לקוחות</button>
      </div>
    </div>
  );
};

export default HelpCard;

import { get, post, put } from "../client";
import {
  CREATE_CLIENT,
  DELETE_CLIENT,
  GET_CLIENTS,
  GET_CLIENT_BY_ID,
  GET_CLIENT_HISTORY,
} from "../routes";

export default {
  get: async (page, size, type, search) =>
    await get(GET_CLIENTS(page, size, type, search)),

  get_client_history: async (data) => await post(GET_CLIENT_HISTORY, data),

  delete_client: async (data) => await put(DELETE_CLIENT, data),

  get_by_id: async (id) => await get(GET_CLIENT_BY_ID(id)),

  create_client: async (data) => await post(CREATE_CLIENT, data),
};

import GenerateFirstLetterBox from "../../components/generateFirstLetterBox";
import { ClockIcon1Svg } from "../../svgs/clockIcon1Svg";
import styles from "../../styles/_containers/_services/items.module.scss";

const Items = ({
  services,
  setIsOpen,
  setIsConfirmModalOpen,
}) => {
  return (
    <div className={styles.container}>
      {services?.map((i) => (
        <div className={styles.item} key={i._id}>
          <div className={styles.right}>
            <GenerateFirstLetterBox name={i.name} />

            <div className={styles.name}>
              <h4>{i.name}</h4>
              <p>{i.description}</p>
            </div>

            <div className={styles.services}>
              <div className={styles.block}>
                <ClockIcon1Svg fill={"#DE1468"} />
                <p>{i.time} דקות</p>
              </div>
              <div className={styles.block}>
                <ClockIcon1Svg fill={"#DE1468"} />
                <p>משתתפים {i.maximumQueue}</p>
              </div>
              {i.location && (
                <div className={styles.block}>
                  <img src="/assets/location-icon-1.svg" alt="location" />
                  <p>{i.location}</p>
                </div>
              )}
              {i.isExactPrice ? (
                <div className={styles.block}>
                  <p>₪{i.price}</p>
                </div>
              ) : (
                <div className={styles.block}>
                  <p>החל מ- ₪{i.price}</p>
                </div>
              )}
            </div>
          </div>

          <div className={styles.left}>
            <div className={styles.actions}>
              <div className={styles.block} onClick={() => setIsOpen(i._id)}>
                <img src="/assets/edit-icon-1.svg" alt="edit" />
                <p>עריכה</p>
              </div>
              <div
                className={styles.block}
                onClick={() => {
                  setIsConfirmModalOpen(i._id);
                  setIsOpen(null);
                }}
              >
                <img src="/assets/remove-icon-1.svg" alt="remove" />
                <p>מחיקה</p>
              </div>
            </div>
          </div>

          {/* <div className={styles.tag}>
            <p>שירות חדש!</p>
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default Items;

import { useEffect, useState } from "react";
import AppointmentCard from "../../components/appointmentCard";
import { findNearestDate } from "../../utils/findNearestDate";
import Loader from "../../components/loader";
import APPOINTMENTS from "../../api/services/APPOINTMENTS";
import { format } from "date-fns";
import styles from "../../styles/_containers/_appointments/sectionOne.module.scss";

const SectionOne = ({
  appointments,
  appointmentsLoading,
  setIsCommentModalOpen,
  setIsConfirmModalOpen,
  user_id,
}) => {
  const [nearestAppointment, setNearestAppointment] = useState(null);

  const [todayAppointments, setTodayAppointments] = useState(null);
  const [todayAppointmentsLoading, setTodayAppointmentsLoading] =
    useState(false);

  // GET NEAREST APPOINTMENT
  // useEffect(() => {
  //   if (appointments) {
  //     let data = appointments;

  //     if (data.length > 0) {
  //       let nearestObject = findNearestDate(data);

  //       if (nearestObject) {
  //         setNearestAppointment(nearestObject);
  //       } else {
  //         setNearestAppointment(false);
  //       }
  //     }
  //   }
  // }, [appointments]);

  // FETCH TODAY'S APPOINTMENTS
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setTodayAppointmentsLoading(true);
  //     try {
  //       const payload = {
  //         id: user_id,
  //         size: 150,
  //         date: format(new Date(), "yyyy-MM-dd"),
  //       };
  //       const res = await APPOINTMENTS.get_appointments(payload);

  //       let appt = res.data.data.filter((i) => i.id === user_id);

  //       let futureAppointments = appt[0].appoitnemts.filter((i) => {
  //         let currentSlot = format(new Date(), "HH:mm");

  //         if (currentSlot <= i.timeSlot) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       });

  //       let currentDateTime = new Date().toISOString();

  //       futureAppointments.sort((a, b) => {
  //         let timeDifferenceA = Math.abs(
  //           new Date(a.combinedDateTime) - new Date(currentDateTime)
  //         );
  //         let timeDifferenceB = Math.abs(
  //           new Date(b.combinedDateTime) - new Date(currentDateTime)
  //         );

  //         return timeDifferenceA - timeDifferenceB;
  //       });

  //       let nearestObject = findNearestDate(futureAppointments);
  //       setNearestAppointment(nearestObject);

  //       setTodayAppointments(futureAppointments);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     setTodayAppointmentsLoading(false);
  //   };

  //   fetchData();
  // }, [appointments]);

  return (
    <div className={styles.container}>
      {/* NEAREST APPOINTMENT */}
      {/* FOR LATER */}
      {/* <div className={styles.nearest}>
        <h3>התור הקרוב ביותר</h3>

        {appointmentsLoading && <Loader />}

        {!appointmentsLoading && nearestAppointment && (
          <AppointmentCard
            key={nearestAppointment._id}
            id={nearestAppointment._id}
            customerName={nearestAppointment.userId?.name}
            date={nearestAppointment.date}
            startTime={nearestAppointment.timeSlot}
            employeeId={nearestAppointment.employeeId}
            serviceName={nearestAppointment.serviceId?.name}
            time={nearestAppointment.serviceId?.time}
            type={nearestAppointment.serviceId?.type}
            isConfirmed={nearestAppointment.isConfirmed}
            phoneNumber={nearestAppointment.userId?.phone}
            userId={nearestAppointment.userId?._id}
            adminComment={nearestAppointment.adminComment}
            setIsCommentModalOpen={setIsCommentModalOpen}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
          />
        )}

        {!nearestAppointment && (
          <div className={styles.noRecord}>
            <p>לא נמצא תור קרוב להיום, ניתן לבדוק תאריך אחר ביומן.</p>
          </div>
        )}
      </div> */}

      {/* TODAY'S APPOINTMENTS */}
      <div className={styles.all}>
        <h3>התורים שלי היום</h3>

        {/* loading */}
        {appointmentsLoading && <Loader />}

        {/* records */}
        <div className={styles.appointments}>
          {appointments?.map((i) => (
            <AppointmentCard
              key={i._id}
              id={i._id}
              customerName={i.userId?.name}
              date={i.date}
              startTime={i.timeSlot}
              serviceName={i.serviceId?.name}
              employeeId={i.employeeId}
              time={i.serviceId?.time}
              type={i.serviceId?.type}
              isConfirmed={i.isConfirmed}
              phoneNumber={i.userId?.phone}
              userId={i.userId?._id}
              adminComment={i.adminComment}
              setIsCommentModalOpen={setIsCommentModalOpen}
              setIsConfirmModalOpen={setIsConfirmModalOpen}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionOne;

import { BASE_URL } from "../config";

export const generateProfileImgUrl = (img, name) => {
  // Function to get the first uppercase letter or a fallback character
  const getFirstLetter = (name) => {
    if (name && /^[A-Za-z]/.test(name)) {
      return name.charAt(0).toUpperCase();
    }
    return '?'; // Fallback character
  };

  const firstLetter = getFirstLetter(name);
  if (!img) {
    return (
      <div
        style={{
          background: "#DE1468",
          color: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {firstLetter}
      </div>
    );
  } else if (img.includes("user_profile/") || img.includes("business_profile/")) {
    return <img src={`${BASE_URL}/uploads/${img}`} alt={name} />;
  } else {
    return (
      <div
        style={{
          background: "#DE1468",
          color: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {firstLetter}
      </div>
    );
  }
};

export const AddPlusSvg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_4856)">
        <path
          d="M10 0C15.5229 0 20 4.47715 20 10C20 15.5229 15.5229 20 10 20C4.47715 20 0 15.5229 0 10C0 4.47715 4.47715 0 10 0ZM10 1.42855C5.26613 1.42855 1.42855 5.26613 1.42855 10C1.42855 14.7339 5.26613 18.5714 10 18.5714C14.7339 18.5714 18.5714 14.7339 18.5714 10C18.5714 5.26613 14.7339 1.42855 10 1.42855Z"
          fill={fill}
        />
        <path
          d="M9.927 4.56104C10.3083 4.56104 10.6199 4.8599 10.6402 5.23615L10.6413 5.27533L10.6412 9.32959H14.7255C15.12 9.32959 15.4398 9.64939 15.4398 10.0439C15.4398 10.4252 15.1409 10.7368 14.7647 10.7571L14.7255 10.7582H10.6412L10.6413 14.7258C10.6413 15.1203 10.3215 15.4401 9.927 15.4401C9.54567 15.4401 9.23411 15.1412 9.21376 14.7649L9.21271 14.7258L9.21263 10.7582H5.27513C4.88063 10.7582 4.56079 10.4384 4.56079 10.0439C4.56079 9.66256 4.85966 9.351 5.23591 9.33064L5.27509 9.32959H9.21259L9.21267 5.27533C9.21274 4.88084 9.53251 4.56104 9.927 4.56104Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_61_4856">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { useState } from "react";
import { toast } from "react-toastify";
import Modal from "./modal";
import APPOINTMENTS from "../api/services/APPOINTMENTS";
import styles from "../styles/_components/commentModal.module.scss";

const CommentModal = ({ setIsOpen, appointmentId }) => {
  const [commentText, setCommentText] = useState("");

  // add comment
  const handleCommentSubmit = async () => {
    let payload = {
      id: appointmentId,
      adminComment: commentText,
    };
    try {
      const res = await APPOINTMENTS.add_comment(payload);
      toast.success(res.data.message);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsOpen(false);
    }
  };

  return (
    <Modal setIsOpen={setIsOpen} onClick={() => null}>
      <div className={styles.container}>
        <h2>הערה פנימית (הלקוח/ה לא רואים את ההערה)</h2>
        <input
          type="text"
          placeholder="כאן רושמים את ההערה"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <button onClick={handleCommentSubmit} disabled={!commentText}>
          שמירה
        </button>
      </div>
    </Modal>
  );
};

export default CommentModal;

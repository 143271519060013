export const paths = {
  management: "/",

  stats: "/statistics",

  appointments: "/appointments",

  settings: "/settings",

  createAppointment: "/create-appointment",

  clients: "/clients",

  clientMemberShip: (id) => `/client/${id}`,

  clientHistory: (id) => `/client-history/${id}`,

  workingHours: (id) => `/working-hours/${id}`,

  memberShips: "/memberships",

  employees: "/employees",

  services: "/services",

  class: (appointmentId, employeeId) => `/class/${appointmentId}/${employeeId}`,

  createNotifications: "/create-notifications",

  notifications: "/notifications",

  profile: "/profile",

  subscription: "/subscription",

  invoices: "/invoices",

  contact: "/contact",

  coupons: "/coupons",

  couponsUsageHistory: "/coupons-usage-history",

  giftCards: "/gift-cards",

  giftCardPurchaseHistory: "/gift-cards-purchase-history",

  giftCardConsumptionHistory: "/gift-cards-consumption-history",
  
  giftCardConsumptionHistoryByCode: (code) => `/gift-cards-consumption-history/${code}`,

  login: "/auth/login",
};

import { useEffect, useState } from "react";
import { format } from "date-fns";
import Layout from "../layouts/layout";
import Loader from "../components/loader";
import NOTIFICATIONS from "../api/services/NOTIFICATIONS";
import styles from "../styles/_screens/notifications.module.scss";

const Notifications = () => {
  const [data, setData] = useState(null);

  // FETCH NOTIFICATIONS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await NOTIFICATIONS.get_notifications();
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // MARK ALL NOTIFICATIONS AS READ
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await NOTIFICATIONS.mark_as_read_notifications();
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.main}>
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.main}>
            <h1>התראות מערכת ועדכונים</h1>
            <div className={styles.items}>
              {data.map((i) => (
                <div className={styles.item} key={i._id}>
                  <div className={styles.right}>
                    <h3>
                      {i.type === "URGENT"
                        ? "חשובה{'\n'}הודעה"
                        : i.type === "REGULAR"
                        ? `התראת${"\n"}מערכת`
                        : `עדכון${"\n"}מערכת`}
                    </h3>
                    <p>{format(new Date(i.createdAt), "dd/MM/yyyy")}</p>
                    <p>{format(new Date(i.createdAt), "HH:mm")}</p>
                  </div>
                  <div className={styles.left}>
                    <p>{i.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;

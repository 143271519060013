import Card from "./card";
import styles from "../../styles/_containers/_settings/sections.module.scss";

const SectionTwo = ({ data, handleChange }) => {
  return (
    <div className={styles.container}>
      {/* CARD 1 */}
      <Card>
        <div className={styles.cardContainer}>
          {/* BLOCK 1 */}
          {/* IS MAX CAPACITY PER DAY ENABLED */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>הגבלת מספר התורים ביום</h4>
              <div className={styles.tag}>
                <p>חדש</p>
              </div>
            </div>

            <p className={styles.textOne}>
              תוכלו להגביל את מספר התורים שתקבלו ביוםלדוגמא: עד 4 תורים ביום
            </p>

            <div className={styles.field}>
              <label className={styles.specialLabel}>
                *מומלץ לנשאיר במצב: לא פעיל
              </label>
              <select
                name="isMaxCapacityPerDayEnabled"
                value={data.isMaxCapacityPerDayEnabled}
                onChange={handleChange}
              >
                <option value={true}>פעיל</option>
                <option value={false}>לא פעיל</option>
              </select>
            </div>
          </div>

          {/* BLOCK 2 */}
          {/* MAX CAPACITY PER DAY */}
          <div className={`${styles.block} ${styles.lastBlock}`}>
            <div className={styles.title}>
              <h4>העסק יקבל עד</h4>
              <div className={`${styles.tag} ${styles.tag2}`}>
                <p>תורים ביום X</p>
              </div>
            </div>

            <p className={styles.textOne}>
              במידה ופעיל,לקוחות יקבלו הודעה ויבקשו לאשר את התור באופן ידני
            </p>

            <div className={styles.field}>
              <label>אישור הגעה</label>
              <input
                type="text"
                name="maxCapacityPerDay"
                value={data.maxCapacityPerDay}
                onChange={handleChange}
                placeholder="הזינו את מספר התורים המקסימלי"
              />
            </div>
          </div>
        </div>
      </Card>

      {/* CARD 2 */}
      <Card>
        <div className={styles.cardContainer}>
          {/* BLOCK 1 */}
          {/* IS SMART ALGORITHM ACTIVE */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>אלגוריתם ניהול תורים חכם</h4>
              <div className={styles.tag}>
                <p>מומלץ מאוד</p>
              </div>
            </div>

            <p className={styles.textOne}>
              המערכת תנהל עבורכם את התורים כך שתוכלו לקבל מספר מקסימלי של תורים
              ביום לפי סוג התור הנבחר
            </p>

            <div className={styles.field}>
              <label>סטטוס</label>
              <select
                name="isSmartAlgorithmActive"
                value={data.isSmartAlgorithmActive}
                onChange={handleChange}
              >
                <option value={true}>פעיל</option>
                <option value={false}>לא פעיל</option>
              </select>
            </div>
          </div>

          {/* BLOCK 2 */}
          {/* BUSINESS SERVICE SPAN */}
          <div className={`${styles.block} ${styles.lastBlock}`}>
            <div className={styles.title}>
              <h4>אפשרות קביעת תור כל</h4>
              <div className={`${styles.tag} ${styles.tag3}`}>
                <p> 5 דקות</p>
              </div>
            </div>

            <p className={styles.textOne}>
              לדוגמא יהיה ניתן לקבוע תור בקפיצות של:5, 10, 25 דקות וכ’ו
            </p>

            <div className={styles.field}>
              <label>בחירת קפיצה</label>
              <select
                name="businessServiceSpan"
                value={data.businessServiceSpan}
                onChange={handleChange}
              >
                <option value="5">5 דקות</option>
                <option value="10">10 דקות</option>
                <option value="15">15 דקות</option>
                <option value="20">20 דקות</option>
                <option value="25">25 דקות</option>
                <option value="30">30 דקות</option>
                <option value="35">35 דקות</option>
                <option value="40">40 דקות</option>
                <option value="45">45 דקות</option>
                <option value="50">50 דקות</option>
                <option value="55">55 דקות</option>
                <option value="60">1 שעה</option>
                <option value="90">שעה וחצי</option>
                <option value="120">2 שעות</option>
                <option value="150">שעתיים וחצי</option>
                <option value="180">3 שעות</option>
              </select>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SectionTwo;

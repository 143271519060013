import { get, post } from "../client";
import {
  GET_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATIONS,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_TO_ALL,
  SEND_POPUP,
  VERIFY_OTP,
} from "../routes";

export default {
  send_popup: async (data) => await post(SEND_POPUP, data),

  reset_popup: async (data) => await post(VERIFY_OTP, data),

  send_notification: async (data) => await post(SEND_NOTIFICATION, data),

  send_notification_to_all: async (data) =>
    await post(SEND_NOTIFICATION_TO_ALL, data),

  get_notifications: async () => await get(GET_NOTIFICATIONS),

  mark_as_read_notifications: async () => await get(MARK_AS_READ_NOTIFICATIONS),
};

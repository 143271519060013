import styles from "../../styles/_containers/_subscription/otherDetails.module.scss";

const OtherDetails = ({
  setIsOpen,
  appointments,
  clients,
  currentPlan,
  subscription,
  messages,
}) => {
  return (
    <div className={styles.container}>
      <h2>שימוש לפי מחזור החיוב הנוכחי</h2>

      <div className={styles.cards}>
        <div className={styles.card}>
          <h4>{appointments}</h4>
          <hr />
          <p>תורים שקבעו החודש</p>
        </div>
        <div className={styles.card}>
          <h4>
            {subscription.basePlanId.services.appointments.initialQuantity}
          </h4>
          <hr />
          <p>כמות תורים לניצול בחבילה</p>
        </div>
        <div className={styles.card}>
          <h4>{clients}</h4>
          <hr />
          <p>סה”כ לקוחות בעסק</p>
        </div>
        <div className={styles.card}>
          <h4>{subscription.basePlanId.services.clients.initialQuantity}</h4>
          <hr />
          <p>כמות לקוחות לניצול בחבילה</p>
        </div>
        <div className={styles.card}>
          <h4>{messages.messagesSent}</h4>
          <hr />
          <p>הודעות שנשלחו החודש</p>
        </div>
        <div className={styles.card}>
          <h4>{messages.allowedFreeMessages}</h4>
          <hr />
          <p>כמות הודעות לניצול בחבילה</p>
        </div>

        <div className={styles.bigCard}>
          <h4>
            {Number(messages.allowedFreeMessages) -
              Number(messages.messagesSent)}
          </h4>
          <hr />
          <p>
            {Number(messages.allowedFreeMessages) -
              Number(messages.messagesSent) ===
            0
              ? `מכסת ההודעת שלך נגמרה וכעת המנוי בחריגה. ${"\n"} כל הודעה נוספת תחויב בעלות נוספת`
              : "כמות הודעות שנותרו לניצול כחלק מהחבילה"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OtherDetails;

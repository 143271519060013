import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RemoveIcon1Svg } from "../../svgs/removeIcon1Svg";
import { CheckIcon1Svg } from "../../svgs/checkIcon1Svg";
import { redirectToWhatsapp } from "../../utils/redirectToWhatsapp";
import GenerateFirstLetterBox from "../../components/generateFirstLetterBox";
import CLASS from "../../api/services/CLASS";
import CUSTOMERS from "../../api/services/CUSTOMERS";
import CommentModal from "../../components/commentModal";
import APPOINTMENTS from "../../api/services/APPOINTMENTS";
import styles from "../../styles/_containers/_class/table.module.scss";

const Table = () => {
  const { appointmentId } = useParams();

  const [activeRow, setActiveRow] = useState(null);
  const [members, setMembers] = useState([]);
  const [resStatus, setResStatus] = useState(null);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const handleAccordionClick = (index) => {
    setActiveRow(activeRow === index ? null : index);
  };

  // GET PARTICIPANTS
  useEffect(() => {
    setResStatus(null);
    const fetchData = async () => {
      try {
        const res = await CLASS.get_participants_of_class(appointmentId);
        console.log(res);
        setMembers(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [resStatus]);

  // FETCH CUSTOMER DETAILS
  const getCustomerDetails = async (id) => {
    try {
      const res = await CUSTOMERS.get_by_id(id);
      return res.data.isHealthCheckCompleted;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const markAttendance = async (hasAttended, userId) => {
    let payload = {
      appointmentId,
      userId,
      hasAttended,
    };

    try {
      const res = await CLASS.mark_attendance(payload);
      console.log(res);
      setResStatus(true);
    } catch (error) {
      console.log(error);
    }
  };

  // SEND MANUAL REMINDER
  const handleManualReminder = async (userId) => {
    let payload = {
      userId,
      appointmentId,
    };
    try {
      const res = await APPOINTMENTS.send_reminder(payload);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.messsage);
    }
  };

  // CANCEL APPOINTMENT
  const cancelAppointment = async () => {
    let payload = {
      id: appointmentId,
      status: 3,
    };
    try {
      const res = await APPOINTMENTS.cancel_appointment(payload);
      setResStatus(true);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.messsage);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <table className={styles.customTable}>
          <thead>
            <tr>
              <th></th>
              <th>רשומים לשיעור</th>
              <th>נוכחות</th>
              <th>סטטוס נוכחות</th>
              <th>הצהרת בריאות</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {members.map((i, idx) => (
              <Fragment key={idx}>
                <tr
                  className={`${styles.accordionHeader} ${
                    activeRow === idx ? styles.active : ""
                  }`}
                >
                  <td>
                    <input type="checkbox" name="" id="" />
                  </td>
                  <td>
                    <div className={styles.customer}>
                      <GenerateFirstLetterBox name={i.userName} />
                      <div className={styles.text}>
                        <h5>{i.userName}</h5>
                        {/* <p>מס’ לקוח - 02239</p> */}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.actions}>
                      <div onClick={() => markAttendance(false, i.userId)}>
                        <RemoveIcon1Svg fill="#FF0000" />
                      </div>
                      <div onClick={() => markAttendance(true, i.userId)}>
                        <CheckIcon1Svg fill="#06A816" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      className={`${styles.status} ${
                        i.hasAttended === undefined
                          ? styles.pending
                          : i.hasAttended === false
                          ? styles.error
                          : i.hasAttended === true
                          ? styles.success
                          : ""
                      }`}
                    >
                      <p>
                        {i.hasAttended === undefined
                          ? "ממתין לאישור"
                          : i.hasAttended === false
                          ? "לא מגיע לתור"
                          : i.hasAttended === true
                          ? "אישר/ה הגעה"
                          : ""}
                      </p>
                    </div>
                  </td>
                  <td onClick={() => getCustomerDetails(i.userId)}>
                    <div className={`${styles.health} ${styles.success}`}>
                      {getCustomerDetails(i.userId) && (
                        <CheckIcon1Svg fill="#06A816" />
                      )}
                      <p>
                        {getCustomerDetails(i.userId) ? "מאושר" : "לא קיים"}
                      </p>
                    </div>
                  </td>
                  <td onClick={() => handleAccordionClick(1)}>
                    <img src="/assets/chevron-down.svg" alt="down" />
                  </td>
                </tr>
                {activeRow === 1 && (
                  <tr className={styles.accordionContent}>
                    <td colSpan="6">
                      <ul>
                        {/* PHONE */}
                        {i.userPhone && (
                          <a href={`tel:+972${i.userPhone}`}>
                            <li>
                              <img src="/assets/phone-icon-1.svg" alt="phone" />
                              <p>חיוג ללקוח/ה</p>
                            </li>
                          </a>
                        )}
                        {/* WHATSAPP */}
                        {i.userPhone && (
                          <li onClick={() => redirectToWhatsapp(i.userPhone)}>
                            <img
                              src="/assets/whatsapp-icon-1.svg"
                              alt="whatsapp"
                            />
                            <p>שלח הודעת וואטסאפ</p>
                          </li>
                        )}
                        {/* COMMENT */}
                        <li onClick={() => setIsCommentModalOpen(true)}>
                          <img src="/assets/write-icon-1.svg" alt="write" />
                          <p>הוספת הערה לתור</p>
                        </li>

                        {/* SEND MANUAL REMINDER */}
                        <li onClick={() => handleManualReminder(i.userId)}>
                          <img
                            src="/assets/paper-plane-icon-1.svg"
                            alt="paper-plane"
                          />
                          <p>שליחת הודעה ללקוח עם פרטי התור</p>
                        </li>

                        <li>
                          <img src="/assets/card-icon-1.svg" alt="card" />
                          <p>כרטיסיות ומנויים</p>
                        </li>

                        <li>
                          <img src="/assets/tinder-icon-1.svg" alt="tinder" />
                          <p>תיק לקוח</p>
                        </li>

                        {/* CANCEL APPOINTMENT */}
                        <li onClick={cancelAppointment}>
                          <img src="/assets/remove-icon-1.svg" alt="remove" />
                          <p>ביטול תור ללקוחה</p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* MODALS */}
      {isCommentModalOpen && (
        <CommentModal
          setIsOpen={setIsCommentModalOpen}
          appointmentId={appointmentId}
        />
      )}
    </div>
  );
};

export default Table;

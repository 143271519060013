import { useState, useEffect, useRef } from "react";
import SERVICES from "../../api/services/SERVICES";
import { ClockIcon1Svg } from "../../svgs/clockIcon1Svg";
import Loader from "../../components/loader";
import styles from "../../styles/_containers/_createAppointment/services.module.scss";

const Services = ({
  currentEmployee,
  currentService,
  setCurrentService,
  setStep,
}) => {
  const ref = useRef();

  const [services, setServices] = useState(null);
  const [loading, setLoading] = useState(false);

  // FETCH SERVICES
  useEffect(() => {
    if (currentEmployee) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const res = await SERVICES.get(currentEmployee.id);

          setServices(res.data);
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      };

      fetchData();
    }
  }, [currentEmployee]);

  // IF LOADING
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <div className={styles.container}>
      <h1>בחירת שירות</h1>

      <p className={styles.mainText}>ניתן לבחור שירות אחד בלבד</p>

      <div className={styles.services}>
        {services?.map((i) => (
          <ServiceCard
            key={i._id}
            id={i._id}
            currentService={currentService}
            name={i.name}
            price={i.price}
            desc={i.description}
            isExactPrice={i.isExactPrice}
            maxQueue={i.maximumQueue}
            time={i.time}
            handleClick={() => {
              setCurrentService(i);
              setTimeout(() => {
                ref.current?.scrollIntoView({ behavior: "smooth" });
              }, 500);
            }}
          />
        ))}
      </div>

      <div className={styles.btns} ref={ref}>
        <button onClick={() => setStep(1)}>הקודם</button>
        {currentService && <button onClick={() => setStep(3)}>הבא</button>}
      </div>
    </div>
  );
};

export default Services;

// SERVICE CARD
export const ServiceCard = ({
  id,
  currentService,
  name,
  price,
  desc,
  isExactPrice,
  maxQueue,
  time,
  handleClick,
}) => {
  const [isHovered, setIsHovered] = useState(null);

  return (
    <div
      className={`${styles.card} ${
        id === currentService?._id ? styles.active : ""
      }`}
      onClick={handleClick}
      style={{
        border:
          currentService?._id === id
            ? `1px solid #DE1468`
            : isHovered === id
            ? `1px solid #DE1468`
            : "",
        backgroundColor:
          currentService?._id === id
            ? "rgba(255, 172, 205, 0.21)"
            : isHovered === id
            ? "rgba(255, 172, 205, 0.21)"
            : "",
      }}
      onMouseEnter={() => setIsHovered(id)}
      onMouseLeave={() => setIsHovered(null)}
    >
      <div className={styles.img} style={{ backgroundColor: "#DE1468" }}>
        <h3>{name?.slice(0, 1)}</h3>
      </div>

      <div className={styles.content}>
        <h5 className={styles.name}>{name}</h5>
        <p className={styles.desc}>{desc}</p>

        <div className={styles.row}>
          <div
            className={styles.block}
            style={{
              backgroundColor: "rgba(255, 172, 205, 0.21)",
              border: `1px solid #DE1468`,
            }}
          >
            <ClockIcon1Svg fill={"#DE1468"} />
            <p style={{ color: "#DE1468" }}>{time} דקות</p>
          </div>
          {price > 0 && (
            <div
              className={styles.block}
              style={{
                backgroundColor: "rgba(255, 172, 205, 0.21)",
                border: `1px solid #DE1468`,
                maxWidth: !isExactPrice ? "120px" : "60px",
                minWidth: !isExactPrice ? "100px" : "60px",
              }}
            >
              <p style={{ color: "#DE1468" }}>
                {isExactPrice ? " " : "החל מ-"}₪{price}
              </p>
            </div>
          )}
        </div>
      </div>

      <button
        style={{
          backgroundColor:
            currentService?._id === id
              ? "#DE1468"
              : isHovered === id
              ? "#DE1468"
              : "",
        }}
      >
        בחירת
        <br />
        טיפול
      </button>
    </div>
  );
};

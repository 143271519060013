import { useState } from "react";
import Layout from "../layouts/layout";
import SideMenu from "../components/sideMenu";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import { BooksIcon1Svg } from "../svgs/booksIcon1Svg";
import { DiscordIcon1Svg } from "../svgs/discordIcon1Svg";
import Nav from "../containers/_memberships/nav";
import Items from "../containers/_memberships/items";
import Create from "../containers/_memberships/create";
import styles from "../styles/_screens/memberships.module.scss";

const Memberships = () => {
  const sideMenuList = [
    {
      id: 1,
      svg: <DiscordIcon1Svg fill="#000000" />,
      text: "הלקוחות שלי",
      active: false,
    },
    {
      id: 2,
      svg: <AddPlusSvg fill="#000000" />,
      text: "הוספת לקוח חדש",
      active: false,
    },
    {
      id: 3,
      svg: <BooksIcon1Svg fill="#DE1468" />,
      text: "מינויים",
      active: true,
    },
  ];

  const [step, setStep] = useState(0);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.main}>
            <Nav step={step} setStep={setStep} />

            {step === 0 ? <Items /> : <Create />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Memberships;

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { paths } from "../routes/paths";
import { toast } from "react-toastify";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import { CalendarIcon1Svg } from "../svgs/calendarIcon1Svg";
import { ClockIcon1Svg } from "../svgs/clockIcon1Svg";
import { SettingsIcon1Svg } from "../svgs/settingsIcon1Svg";
import SideMenu from "../components/sideMenu";
import Layout from "../layouts/layout";
import SectionOne from "../containers/_settings/sectionOne";
import SectionTwo from "../containers/_settings/sectionTwo";
import SectionThree from "../containers/_settings/sectionThree";
import PROFILE from "../api/services/PROFILE";
import Loader from "../components/loader";
import styles from "../styles/_screens/settings.module.scss";

const Settings = () => {
  const navigate = useNavigate();

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  const sideMenuList = [
    {
      id: 1,
      svg: <AddPlusSvg fill="#000000" />,
      text: "הוספת תור חדש",
      active: false,
      onClick: () => navigate(paths.createAppointment),
    },
    {
      id: 2,
      svg: <CalendarIcon1Svg fill="#000000" />,
      text: "יומן שבועי",
      active: false,
      onClick: () => navigate(paths.appointments),
    },
    {
      id: 3,
      svg: <SettingsIcon1Svg fill="#DE1468" />,
      text: "הגדרות יומן ותורים",
      active: true,
    },
    {
      id: 4,
      svg: <ClockIcon1Svg fill="#000000" />,
      text: "הגדרות קבלת קהל",
      active: false,
      onClick: () => navigate(paths.workingHours(businessId)),
    },
  ];

  const [formdata, setFormdata] = useState({
    id: businessId,
    waitingListMode: "",
    isConfirmEnabled: "",
    businesssScheduleAllowedFor: "",
    is24HoursReminderDisabled: "",
    is3HoursReminderDisabled: "",
    businessCancellationPeriod: "",
    isMaxCapacityPerDayEnabled: "",
    maxCapacityPerDay: "",
    isSmartAlgorithmActive: "",
    businessServiceSpan: "",
    canBookMoreInServices: "",
    cancellationText: "",
  });

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [resStatus, setResStatus] = useState(null);

  // FETCH PROFILE DETAILS
  useEffect(() => {
    setResStatus(true);
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await PROFILE.get();

        setFormdata({
          waitingListMode: res.data.waiting_list_mode,
          isConfirmEnabled: res.data.isConfirmEnabled,
          businesssScheduleAllowedFor: res.data.businesss_schedule_allowed_for,
          is24HoursReminderDisabled: res.data.is24HoursReminderDisabled,
          is3HoursReminderDisabled: res.data.is3HoursReminderDisabled,
          businessCancellationPeriod: res.data.business_cancellation_period,
          isMaxCapacityPerDayEnabled: res.data.isMaxCapacityPerDayEnabled,
          maxCapacityPerDay: "",
          isSmartAlgorithmActive: res.data.isSmartAlgorithmActive,
          businessServiceSpan: res.data.businessServiceSpan,
          canBookMoreInServices: res.data.canBookMoreInServices,
          cancellationText: res.data.cancellationText,
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [resStatus]);

  // UPDATE PROFILE
  const handleSubmit = async () => {
    setLoading2(true);
    try {
      let payload = {
        id: businessId,
        waiting_list_mode: formdata.waitingListMode,
        isConfirmEnabled: formdata.isConfirmEnabled,
        businesss_schedule_allowed_for: formdata.businesssScheduleAllowedFor,
        is24HoursReminderDisabled: formdata.is24HoursReminderDisabled,
        is3HoursReminderDisabled: formdata.is3HoursReminderDisabled,
        business_cancellation_period: formdata.businessCancellationPeriod,
        isMaxCapacityPerDayEnabled: formdata.isMaxCapacityPerDayEnabled,
        maxCapacityPerDay: formdata.maxCapacityPerDay,
        isSmartAlgorithmActive: formdata.isSmartAlgorithmActive,
        businessServiceSpan: formdata.businessServiceSpan,
        canBookMoreInServices: formdata.canBookMoreInServices,
        cancellationText: formdata.cancellationText,
      };

      const res = await PROFILE.update(payload);

      if (res.status === 200) {
        toast.success("Updated successfully!");
        setResStatus(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading2(false);
  };

  // HANDLE INPUT CHANGE
  const handleChange = (e) => {
    setFormdata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  if (loading || !formdata.waitingListMode) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <Loader />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.sections}>
            {/* SECTION ONE */}
            <SectionOne data={formdata} handleChange={handleChange} />

            {/* SECTION TWO */}
            <SectionTwo data={formdata} handleChange={handleChange} />

            {/* SECTION THREE */}
            <SectionThree data={formdata} handleChange={handleChange} />
          </div>
        </div>
        <button onClick={handleSubmit} className={styles.saveBtn}>
          שמירת שינויים
        </button>
      </div>
    </Layout>
  );
};

export default Settings;

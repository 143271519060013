import { useNavigate } from "react-router-dom";
import styles from "../../styles/_containers/_subscription/smallCard.module.scss";

const SmallCard = ({ heading, text, link }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h4>{heading}</h4>
      <button onClick={() => navigate(link)}>{text}</button>
    </div>
  );
};

export default SmallCard;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CUSTOMERS from "../api/services/CUSTOMERS";
import Layout from "../layouts/layout";
import SideMenu from "../components/sideMenu";
import { DiscordIcon1Svg } from "../svgs/discordIcon1Svg";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import { BooksIcon1Svg } from "../svgs/booksIcon1Svg";
import ClientCard from "../containers/_clientMembership/clientCard";
import MembershipCard from "../containers/_clientMembership/membershipCard";
import Loader from "../components/loader";
import PLANS from "../api/services/PLANS";
import styles from "../styles/_screens/clientMembership.module.scss";

const ClientMembership = () => {
  const { id } = useParams();

  const [clientDetails, setClientDetails] = useState(null);
  const [allPlans, setAllPlans] = useState([]);
  const [userPlans, setUserPlans] = useState([]);

  // RESPONSE STATUES
  const [resSuccess, setResSuccess] = useState(null);

  const sideMenuList = [
    {
      id: 1,
      svg: <DiscordIcon1Svg fill="#000000" />,
      text: "הלקוחות שלי",
      active: false,
    },
    {
      id: 2,
      svg: <AddPlusSvg fill="#000000" />,
      text: "הוספת לקוח חדש",
      active: false,
    },
    {
      id: 3,
      svg: <BooksIcon1Svg fill="#DE1468" />,
      text: "מינויים",
      active: true,
    },
  ];

  // FETCH CLIENT
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await CUSTOMERS.get_by_id(id);
        setClientDetails({
          id: res.data.userId._id,
          profile: res.data.userId.profile,
          name: res.data.userId.name,
          email: res.data.userId.email,
          phone: res.data.userId.phone,
          isHealthCheckCompleted: res.data.isHealthCheckCompleted,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // FETCH PLANS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await PLANS.get_all_plans();
        setAllPlans(res.data.plans);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // FETCH USER PLANS
  useEffect(() => {
    setResSuccess(null);
    const fetchData = async () => {
      try {
        const res = await PLANS.get_user_plans({ userId: id });
        setUserPlans(res.data.data.attachedPlans);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [resSuccess]);

  if (!clientDetails) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.main}>
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.main}>
            <ClientCard
              data={clientDetails}
              allPlans={allPlans}
              setResSuccess={setResSuccess}
            />

            <span className={styles.line}></span>

            <div className={styles.userPlans}>
              <h3 className={styles.heading}>מנויים משוייכים ללקוח זה</h3>
              {userPlans?.length > 0 ? (
                <div className={styles.items}>
                  {userPlans.map((i) => (
                    <MembershipCard
                      key={i._id}
                      id={i._id}
                      userId={id}
                      title={i.name}
                      price={i.price}
                      isFrozen={i.isFrozen}
                      disabled={i.isFrozen}
                      createdAt={i.createdAt}
                      expiresAt={i.expiresAt}
                      numberOfTurns={i.numberOfTurns}
                      isSubscription={i.isSubscription}
                      consumedOneTimeTurns={i.consumedOneTimeTurns}
                      setResSuccess={setResSuccess}
                    />
                  ))}
                </div>
              ) : (
                <div className={styles.noItems}>
                  <p>לא נמצאו מנויים פעילים ללקוח/ה</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ClientMembership;

export const RemindersIconSvg = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_484_2279)">
        <mask
          id="mask0_484_2279"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="22"
          height="22"
        >
          <path d="M0 1.90735e-06H22V22H0V1.90735e-06Z" fill="white" />
        </mask>
        <g mask="url(#mask0_484_2279)">
          <path
            d="M11.6875 21.1406H5.15625C2.78313 21.1406 0.859375 19.2169 0.859375 16.8438V5.15625C0.859375 2.78313 2.78313 0.859377 5.15625 0.859377H16.8437C19.2169 0.859377 21.1406 2.78313 21.1406 5.15625V7.69141"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.37891 5.92969H17.2734"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.37891 11H9.32422"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.9725 14.7046L19.6399 12.5798C19.4987 11.5721 18.9427 10.6632 18.0948 10.1006C17.5148 9.71575 16.7838 9.49609 16.0511 9.49609C15.3185 9.49609 14.5875 9.71575 14.0075 10.1006C13.1596 10.6632 12.6037 11.5721 12.4624 12.5798L12.1316 14.7128C11.4835 14.8615 11 15.4413 11 16.1345C11 16.9402 11.6532 17.6172 12.459 17.6172H19.6816C20.4874 17.6172 21.1406 16.9402 21.1406 16.1345C21.1406 15.4283 20.6389 14.8394 19.9725 14.7046Z"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.4258 14.4375H16.5859"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.8257 20.0229C14.9007 20.6579 15.4491 21.1401 16.1147 21.1401C16.7804 21.1401 17.3288 20.6579 17.4038 20.0229"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.94141 4.89844C4.34809 4.89844 3.86719 5.37939 3.86719 5.97266C3.86719 6.56593 4.34809 7.04688 4.94141 7.04688C5.53472 7.04688 6.01562 6.56593 6.01562 5.97266C6.01562 5.37939 5.53472 4.89844 4.94141 4.89844Z"
            fill={fill}
          />
          <path
            d="M4.94141 9.92578C4.34809 9.92578 3.86719 10.4067 3.86719 11C3.86719 11.5933 4.34809 12.0742 4.94141 12.0742C5.53472 12.0742 6.01562 11.5933 6.01562 11C6.01562 10.4067 5.53472 9.92578 4.94141 9.92578Z"
            fill={fill}
          />
          <path
            d="M4.94141 14.9961C4.34809 14.9961 3.86719 15.477 3.86719 16.0703C3.86719 16.6636 4.34809 17.1445 4.94141 17.1445C5.53472 17.1445 6.01562 16.6636 6.01562 16.0703C6.01562 15.477 5.53472 14.9961 4.94141 14.9961Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_484_2279">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LocationIcon2Svg = ({ fill }) => {
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_611_8272"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="67"
        height="67"
      >
        <path d="M0 7.62939e-06H67V67H0V7.62939e-06Z" fill={fill} />
      </mask>
      <g mask="url(#mask0_611_8272)">
        <path
          d="M33.5 65.0371C25.6484 53.2598 11.9082 37.2949 11.9082 23.5547C11.9082 11.649 21.5943 1.9629 33.5 1.9629C45.4057 1.9629 55.0918 11.649 55.0918 23.5547C55.0918 37.2949 41.3516 53.2598 33.5 65.0371Z"
          stroke={fill}
          strokeWidth="3.83418"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5 33.3691C28.0886 33.3691 23.6855 28.9661 23.6855 23.5547C23.6855 18.1433 28.0886 13.7402 33.5 13.7402C38.9114 13.7402 43.3145 18.1433 43.3145 23.5547C43.3145 28.9661 38.9114 33.3691 33.5 33.3691Z"
          stroke={fill}
          strokeWidth="3.83418"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

import { useState, useEffect } from "react";
import { AddPlusSvg } from "../../svgs/addPlusSvg";
import { RemoveIcon1Svg } from "../../svgs/removeIcon1Svg";
import PLANS from "../../api/services/PLANS";
import styles from "../../styles/_containers/_memberships/items.module.scss";
import Loader from "../../components/loader";
import { toast } from "react-toastify";

const Items = () => {
  const [allPlans, setAllPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resSuccess, setResSuccess] = useState(null);

  // FETCH PLANS
  useEffect(() => {
    setResSuccess(null);
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await PLANS.get_all_plans();
        setAllPlans(res.data.plans);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [resSuccess]);

  if (loading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {allPlans?.map((i) => (
        <Item
          key={i._id}
          id={i._id}
          title={i.name}
          isSubscription={i.isSubscription}
          numberOfTurns={i.numberOfTurns}
          price={i.price}
          expiresIn={i.expiresIn}
          setResSuccess={setResSuccess}
        />
      ))}
    </div>
  );
};

export default Items;

const Item = ({
  id,
  title,
  isSubscription,
  numberOfTurns,
  price,
  expiresIn,
  setResSuccess,
}) => {
  // DELETE PLAN
  const handleDelete = async () => {
    try {
      const res = await PLANS.deletePlan(id);
      toast.success(res.data.message);
      setResSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // EXTRACT THE LAST CHARACTER OF STRING
  let lastChar = expiresIn.slice(expiresIn.length - 1);

  return (
    <div className={styles.item}>
      <div className={styles.top}>
        <div className={styles.title}>
          <h3>
            {title} | <span>{isSubscription ? "מנוי" : "כרטיסיה"}</span>
          </h3>
        </div>
        <hr />
        <ul>
          <li>
            <AddPlusSvg fill="#06A816" />
            <p>כמות כניסות - {numberOfTurns}</p>
          </li>
          <li>
            <AddPlusSvg fill="#06A816" />
            <p>
              תוקף המינוי - 3{" "}
              {lastChar === "d"
                ? "ימים"
                : lastChar === "w"
                ? "שבועות"
                : lastChar === "m"
                ? "חודשים"
                : lastChar === "y"
                ? "שנים"
                : ""}
            </p>
          </li>
          <li>
            <AddPlusSvg fill="#06A816" />
            <p>מחיר - ₪{price}</p>
          </li>
        </ul>
      </div>
      <div className={styles.bottom} onClick={handleDelete}>
        <RemoveIcon1Svg fill="#ffffff" />
        <p>מחיקת מינוי</p>
      </div>
    </div>
  );
};

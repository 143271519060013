import { useState, useEffect } from "react";
import GeneralDetails from "../containers/_management/generalDetails";
import Layout from "../layouts/layout";
import STATISTICS from "../api/services/STATISTICS";
import Loader from "../components/loader";
import { ShareModal } from "../components/shareModal";
import { AppointmentSvg } from "../svgs/appointmentSvg";
import { CancellationSvg } from "../svgs/cancellationSvg";
import { NotificationSvg } from "../svgs/notificationSvg";
import { RevenueSvg } from "../svgs/revenueSvg";
import { TimeslotSvg } from "../svgs/timeslotSvg";
import { UserSvg } from "../svgs/userSvg";
import { ActivePlanSvg } from "../svgs/activePlanSvg";
import DatePicker from "react-datepicker";
import EXPORT from "../api/services/EXPORT";
import { format } from "date-fns";
import { toast } from "react-toastify";
import styles from "../styles/_screens/management.module.scss";
import { he } from "date-fns/locale";
import axios from "axios";
import { BASE_URL } from "../config";

const Statistics = () => {
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);
  const token = localStorage.getItem("token");

  // FETCH STATISTICS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await STATISTICS.get_statistics();
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const [revealPicker, setRevealPicker] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleExport = async (type, url, dataFormat) => {
    let payload;
    if (type === 1) {
      payload = {
        employeeId: businessId,
        startDate: format(new Date(startDate), "yyyy-MM-dd"),
        endDate: format(new Date(endDate), "yyyy-MM-dd"),
      };
    } else if (type === 3) {
      payload = {
        employeeId: businessId,
      };
    } else if (type === 4) {
      payload = {
        businessId: businessId,
        startDate: format(new Date(startDate), "yyyy-MM-dd"),
        endDate: format(new Date(endDate), "yyyy-MM-dd"),
      };
    } else {
      payload = {
        businessId: businessId,
      };
    }
    try {
      if (dataFormat === "csv") {
        const res = await EXPORT.export(url, payload);
        if (res.status === 200) {
          const csvString = res.data;
          const blob = new Blob([csvString], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "exported_data.csv";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      } else {
        const response = await axios.post(BASE_URL + url, payload, {
          responseType: "arraybuffer",
          headers: {
            Authorization: token && JSON.parse(token),
          },
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        const urll = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urll;
        a.download = "exported.pdf";
        a.click();
        window.URL.revokeObjectURL(urll);
      }

      toast.success("הדו״ח הופק בהצלחה");
      setStartDate(new Date());
      setEndDate(new Date());
      setRevealPicker(null);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  if (!data) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.main}>
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.main}>
            <div className={styles.column}>
              <GeneralDetails
                title="סטטיסטיקות בעסק"
                revenueToday={data.revenueToday}
                revenueThisWeek={data.revenueThisWeek}
                revenueThisMonth={data.revenueThisMonth}
                completedAppointmentsThisMonth={
                  data.completedAppointmentsThisMonth
                }
                futureAppointmentsThisMonth={data.futureAppointmentsThisMonth}
                cancelledAppointmentsThisMonth={
                  data.cancelledAppointmentsThisMonth
                }
                totalAppointmentThisMonth={data.totalAppointmentThisMonth}
                setIsOpen={setIsOpen}
              />
            </div>
            <div className={styles.column}>
              <h2 className={styles.heading}>הורדת דוחות ונתונים על העסק</h2>
              <div className={styles.cards}>
                <div className={styles.card}>
                  <AppointmentSvg fill="#0a0a0a" />
                  <p className={styles.title}>הורדת דו״ח תורים / שיעורים</p>
                  <p
                    className={styles.revealText}
                    onClick={() => setRevealPicker("one")}
                  >
                    בחירת טווח תאריכים לייצוא
                  </p>
                  {revealPicker === "one" && (
                    <>
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        locale={he} // Set locale to Hebrew
                      />
                      <button
                        className={styles.exportBtn}
                        onClick={() =>
                          handleExport(
                            1,
                            "/appointment/appointmentsReport",
                            "csv"
                          )
                        }
                      >
                        הפקת דו״ח
                      </button>
                    </>
                  )}
                </div>
                <div className={styles.card}>
                  <RevenueSvg fill="#0a0a0a" />
                  <p>הורדת דו״ח הכנסות</p>
                  <p
                    className={styles.revealText}
                    onClick={() => setRevealPicker("two")}
                  >
                    בחירת טווח תאריכים לייצוא
                  </p>
                  {revealPicker === "two" && (
                    <>
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        locale={he} // Set locale to Hebrew
                      />
                      <button
                        className={styles.exportBtn}
                        onClick={() =>
                          handleExport(1, "/appointment/revenueReport", "csv")
                        }
                      >
                        הפקת דו״ח
                      </button>
                    </>
                  )}
                </div>
                <div
                  className={styles.card}
                  onClick={() =>
                    handleExport(3, "/appointment/clientReport", "csv")
                  }
                >
                  <UserSvg fill="#0a0a0a" />
                  <p>הורדת דו״ח לקוחות</p>
                </div>
                <div
                  className={styles.card}
                  onClick={() =>
                    handleExport(2, "/appointment/employeeReport", "csv")
                  }
                >
                  <UserSvg fill="#0a0a0a" />
                  <p>הורדת דו״ח עובדים בעסק</p>
                </div>
                <div
                  className={styles.card}
                  onClick={() =>
                    handleExport(2, "/appointment/cancellationReport", "csv")
                  }
                >
                  <CancellationSvg fill="#0a0a0a" />
                  <p>הורדת דו״ח ביטולים לפי לקוח/ה</p>
                </div>
                <div
                  className={styles.card}
                  onClick={() =>
                    handleExport(2, "/appointment/notificationReport", "csv")
                  }
                >
                  <NotificationSvg fill="#0a0a0a" />
                  <p>הורדת דו״ח התראות מערכת</p>
                </div>
                <div
                  className={styles.card}
                  onClick={() =>
                    handleExport(2, "/appointment/timeSlotReport", "pdf")
                  }
                >
                  <TimeslotSvg fill="#0a0a0a" />
                  <p>גרף הכנסות לפי שירות / שיעור</p>
                </div>

                <div
                  className={styles.card}
                  onClick={() =>
                    handleExport(2, "/appointment/activePlanReport", "csv")
                  }
                >
                  <ActivePlanSvg fill="#0a0a0a" />
                  <p>הפקת דו״ח לקוחות עם מנויים</p>
                </div>

                <div
                  className={styles.card}
                  onClick={() =>
                    handleExport(
                      2,
                      "/appointment/clientAppointmentReport",
                      "pdf"
                    )
                  }
                >
                  <AppointmentSvg fill="#0a0a0a" />
                  <p>הפקת דו״ח תורים ולקוחות בעסק</p>
                </div>

                <div className={styles.card}>
                  <RevenueSvg fill="#0a0a0a" />
                  <p className={styles.title}>
                    הפקת דו״ח הכנסה לפי לקוחות בתקופה
                  </p>
                  <p
                    className={styles.revealText}
                    onClick={() => setRevealPicker("three")}
                  >
                    בחירת טווח תאריכים לייצוא
                  </p>
                  {revealPicker === "three" && (
                    <>
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        locale={he}
                      />
                      <button
                        className={styles.exportBtn}
                        onClick={() =>
                          handleExport(
                            4,
                            "/appointment/clientRevenueReport",
                            "csv"
                          )
                        }
                      >
                        הפקת דו״ח
                      </button>
                    </>
                  )}
                </div>

                <div className={styles.card}>
                  <ActivePlanSvg fill="#0a0a0a" />
                  <p className={styles.title}>
                    הפקת דו״ח הכנסות תקופתי לפי עובד
                  </p>
                  <p
                    className={styles.revealText}
                    onClick={() => setRevealPicker("four")}
                  >
                    בחירת טווח תאריכים לייצוא
                  </p>
                  {revealPicker === "four" && (
                    <>
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        locale={he}
                      />
                      <button
                        className={styles.exportBtn}
                        onClick={() =>
                          handleExport(
                            4,
                            "/appointment/employeeRangeReport",
                            "csv"
                          )
                        }
                      >
                        הפקת דו״ח
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && <ShareModal setIsOpen={setIsOpen} />}
      </div>
    </Layout>
  );
};

export default Statistics;

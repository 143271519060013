import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";
import styles from "../../styles/_containers/_management/quickActions.module.scss";

const QuickActions = () => {
  const navigate = useNavigate();
  let user_id = localStorage.getItem("user_id");
  user_id = JSON.parse(user_id);

  let data = [
    // {
    //   icon: "/assets/google-calendar-icon-1.svg",
    //   text: "סנכרון יומן גוגל",
    // },
    {
      icon: "/assets/settings-icon-1.svg",
      text: "הגדרות יומן",
      link: paths.settings,
    },
    {
      icon: "/assets/calendar-icon-2.svg",
      text: "יומן חודשי",
      link: paths.appointments,
    },
    {
      icon: "/assets/add-icon-2.svg",
      text: "קבע תור ללקוח",
      link: paths.createAppointment,
    },
    {
      icon: "/assets/clock-icon-1.svg",
      text: "הגדרות שעות עבודה",
      link: paths.workingHours(user_id),
    },
    {
      icon: "/assets/ship-icon-1.svg",
      text: "העובדים שלי",
      link: paths.employees,
    },
    {
      icon: "/assets/star-icon-3.svg",
      text: "עריכת שירותים",
      link: paths.services,
    },
    // {
    //   icon: "/assets/gadget-icon-1.svg",
    //   text: "חופשה/חסימת פעילות",
    //   link: "",
    // },
    // {
    //   icon: "/assets/add-icon-2.svg",
    //   text: "צור פעולה מהירה אישית",
    //   link: "",
    // },
    {
      icon: "/assets/reminders-icon-1.svg",
      text: "הודעות קופצות",
      link: paths.notifications,
    },
  ];
  return (
    <div className={styles.container}>
      <h2>פעולות מהירות</h2>

      <div className={styles.cards}>
        {data.map((i) => (
          <div className={styles.card} onClick={() => navigate(i.link)}>
            <img src={i.icon} alt={i.text} />
            <p>{i.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuickActions;

// auth
const LOGIN = `/business/login`;

const VERIFY_OTP = `/business/otp_verify`;

// profile
const GET_PROFILE = `/business/profile_data`;

const UPDATE_PROFILE = `/business/update_profile_info`;

// employees
const GET_EMPLOYEES = (id) => `/business/get_employees/${id}`;

const UPDATE_EMPLOYEE = `/business/update_employee`;

const CREATE_EMPLOYEE = "/business/create_employee";

const DELETE_EMPLOYEE = (id) => `/business/delete_employee/${id}`;

// services
const GET_ALL_SERVICES = `/services/get_all_business_services`;

const GET_SERVICES = (id) => `/services/get_services/${id}`;

const GET_SERVICE_BY_ID = (id) => `/services/get_details/${id}`;

const CREATE_SERVICE = "/services/create";

const UPDATE_SERVICE = "/services/update";

const DELETE_SERVICE = (id) => `/services/delete/${id}`;

// appointments
const GET_APPOINTMENTS = "/appointment/appointments";

const GET_EMPLOYEE_APPOINTMENTS = "/appointment/employee_appointments";

const CREATE_APPOINTMENT = "/appointment/create";

// customers
const GET_CLIENTS = (page, size, type, search) =>
  `/users/get_clients?page=${page}&size=${size}&type=${type}&search=${search}`;

const GET_CLIENT_HISTORY = "/appointment/user_appointments";

const DELETE_CLIENT = "/users/delete_business_client";

const GET_CLIENT_BY_ID = (id) => `/users/getClient/${id}`;

const CREATE_CLIENT = "/business/add_user_in_list";

// employee schedule
const GET_TIMESLOTS = "/schedule/get_timeslots";

const GET_SCHEDULE = (id) => `/schedule/get_schedule/${id}`;

const CREATE_SCHEDULE = "/schedule/create";

const SEND_REMINDER = "/appointment/send_reminder_to_client";

const ADD_COMMENT = "/appointment/update";

const CANCEL_APPOINTMENT = "/appointment/cancel";

// plans
const GET_ALL_PLANS = "/servicePlans/getAll";

const GET_USER_PLANS = "/servicePlans/getAllAssignedPlansOfClient";

const ASSIGN_PLANS = "/servicePlans/assignServicePlanToClient";

const FREEZE_UNFREEZE_PLAN = "/servicePlans/freezeUnfreezeUserServicePlan";

const CANCEL_MEMBERSHIP = "/servicePlans/deassignServicePlanFromClient";

const DELETE_PLAN = (id) => `/servicePlans/deleteServicePlan/${id}`;

const CREATE_PLAN = "/servicePlans/create";

// notifications
const SEND_POPUP = "/business/sendPopUp";

const RESET_POPUP = "/business/resetPopUp";

const SEND_NOTIFICATION = "/appointment/sendMessage";

const SEND_NOTIFICATION_TO_ALL = "/appointment/sendMessageToAll";

const GET_NOTIFICATIONS = "/notifications/getAllBusinessEmployeeNotifications";

const MARK_AS_READ_NOTIFICATIONS =
  "/notifications/markAllBusinessNotificationsAsRead";

// subscription
const GET_SUBSCRIPTION_DETAILS = "/business/planConsumptionInfo";

const GET_SUBSCRIPTION_PLANS = "/plan/getAll";

const GET_SUBSCRIPTION_PLAN_PRICE = "/plan/getPlanNormalizedPrice";

const SUBSCRIBE_TO_PLAN = "/plan/subscribe";

const CANCEL_SUBSCRIPTION = "/plan/cancelSubscription";

const CHECK_SUBSCRIPTION = "/business/getIsPaymentOption";

// invoices
const GET_INVOICES = "/plan/getPaymentHistory";

const SEND_INVOICES = "/plan/sendInvoiceToEmail";

// statistics
const GET_STATISTICS = "/business/statistics";

// class
const GET_EMPLOYEE_APPOINTMENT_HISTORY = "/appointment/employee_appointments";

const GET_PARTICIPANTS_OF_CLASS = (id) =>
  `/appointment/appointmentParticipants/${id}`;

const MARK_ATTENDANCE = "/appointment/markAttendance";

// export
const EXPORT = "/appointment/appointmentsReport";

// coupons
const GET_COUPONS = (page, size) => `/coupon?page=${page}&size=${size}`;

const CREATE_COUPON = "/coupon/create";

const COUPON_BY_ID = (id) => `/coupon/${id}`;

const GET_COUPONS_USAGE_HISTORY = (page, size) =>
  `/coupon/usage_history?page=${page}&size=${size}`;

// gift cards
const GET_GIFT_CARDS = (page, size) => `/gift-card?page=${page}&size=${size}`;

const GET_GIFT_CARD_PURCHASE_HISTORY = (page, size) =>
  `/gift-card/purchase-history?page=${page}&size=${size}`;

const GET_GIFT_CARD_CONSUMPTION_HISTORY = (page, size) =>
  `/gift-card/consumption-history?page=${page}&size=${size}`;

const GET_GIFT_CARD_CONSUMPTION_HISTORY_BY_CODE = (id) =>
  `/gift-card/consumption_history/${id}`;

const CREATE_GIFT_CARD = "/gift-card/create";

const GIFT_CARD_BY_ID = (id) => `/gift-card/${id}`;

export {
  LOGIN,
  VERIFY_OTP,
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_EMPLOYEES,
  UPDATE_EMPLOYEE,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_ALL_SERVICES,
  GET_SERVICES,
  GET_SERVICE_BY_ID,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  GET_APPOINTMENTS,
  GET_EMPLOYEE_APPOINTMENTS,
  CREATE_APPOINTMENT,
  GET_CLIENTS,
  GET_CLIENT_HISTORY,
  GET_CLIENT_BY_ID,
  CREATE_CLIENT,
  GET_TIMESLOTS,
  GET_SCHEDULE,
  CREATE_SCHEDULE,
  SEND_REMINDER,
  ADD_COMMENT,
  CANCEL_APPOINTMENT,
  DELETE_CLIENT,
  GET_ALL_PLANS,
  GET_USER_PLANS,
  ASSIGN_PLANS,
  FREEZE_UNFREEZE_PLAN,
  CANCEL_MEMBERSHIP,
  DELETE_PLAN,
  CREATE_PLAN,
  SEND_POPUP,
  RESET_POPUP,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_TO_ALL,
  GET_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATIONS,
  GET_SUBSCRIPTION_DETAILS,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLAN_PRICE,
  SUBSCRIBE_TO_PLAN,
  CANCEL_SUBSCRIPTION,
  GET_INVOICES,
  SEND_INVOICES,
  GET_STATISTICS,
  GET_EMPLOYEE_APPOINTMENT_HISTORY,
  GET_PARTICIPANTS_OF_CLASS,
  MARK_ATTENDANCE,
  EXPORT,
  GET_COUPONS,
  CREATE_COUPON,
  COUPON_BY_ID,
  GET_COUPONS_USAGE_HISTORY,
  CHECK_SUBSCRIPTION,
  GET_GIFT_CARDS,
  CREATE_GIFT_CARD,
  GIFT_CARD_BY_ID,
  GET_GIFT_CARD_PURCHASE_HISTORY,
  GET_GIFT_CARD_CONSUMPTION_HISTORY,
  GET_GIFT_CARD_CONSUMPTION_HISTORY_BY_CODE,
};

export const BooksIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_11709)">
        <path
          d="M14.1992 20.5H9.70703C8.67047 20.4587 8.67125 18.9784 9.70703 18.9375H14.1992C15.9223 18.9375 17.3242 17.5356 17.3242 15.8125V5.1875C17.3242 4.19539 16.8686 3.28402 16.074 2.68711C15.7291 2.42793 15.6595 1.9382 15.9187 1.59324C16.1778 1.24828 16.6676 1.17867 17.0126 1.43789C18.2036 2.3327 18.8867 3.69937 18.8867 5.1875V15.8125C18.8867 18.3972 16.7839 20.5 14.1992 20.5ZM7.36328 19.7188V1.28125C7.36328 0.849766 7.01352 0.5 6.58203 0.5H5.80078C3.21609 0.5 1.11328 2.60281 1.11328 5.1875V15.8125C1.11328 18.3972 3.21609 20.5 5.80078 20.5H6.58203C7.01352 20.5 7.36328 20.1502 7.36328 19.7188ZM5.80078 18.9375C4.07766 18.9375 2.67578 17.5356 2.67578 15.8125V5.1875C2.67578 3.46438 4.07766 2.0625 5.80078 2.0625V18.9375ZM13.0598 9.56156C12.7279 9.56156 12.401 9.43773 12.1488 9.20215L11.6602 8.7509L11.1715 9.20215C10.3291 9.99633 8.92043 9.3841 8.92578 8.22668V1.28125C8.92578 0.849766 9.27555 0.5 9.70703 0.5H13.6133C14.0448 0.5 14.3945 0.849766 14.3945 1.28125V8.22668C14.3945 8.75941 14.0786 9.24027 13.5896 9.45176C13.4188 9.52559 13.2386 9.56156 13.0598 9.56156ZM11.6602 6.90625C11.8504 6.90625 12.0405 6.97535 12.1902 7.11355L12.832 7.70625V2.0625H10.4883V7.70625L11.1302 7.11352C11.2798 6.97535 11.47 6.90625 11.6602 6.90625Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_61_11709">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import { useState, useEffect } from "react";
import Modal from "./modal";
import SUBSCRIPTION from "../api/services/SUBSCRIPTION";
import { CheckIcon1Svg } from "../svgs/checkIcon1Svg";
import styles from "../styles/_components/selectPlan.module.scss";

const SelectPlan = ({
  setIsOpen,
  setIsPaymentModalOpen,
  selectedPlan,
  setSelectedPlan,
}) => {
  const [data, setData] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  // FETCH PLANS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SUBSCRIPTION.get_sub_plans();
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Modal setIsOpen={setIsOpen} type={3}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h2>בחירת מסלול</h2>
          <p className={styles.mainText}>
            אל תדאגו! ניתן לשנות את המסלול בכל עטבמרכז הבקרה
          </p>

          <div className={styles.secondWrapper}>
            <div className={styles.plans}>
              {data?.map((i, idx) => (
                <Plan
                  key={i._id}
                  index={idx}
                  currentPlan={i}
                  data={data}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                />
              ))}
            </div>

            <div className={styles.check}>
              <span
                className={styles.outer}
                onClick={() => setIsChecked(!isChecked)}
              >
                {isChecked && <span className={styles.inner} />}
              </span>
              <p>
                אני מאשר את{" "}
                <span>
                  <a href="https://tor.digital/privacy-policy">תקנון המערכת</a>
                </span>
              </p>
            </div>

            <button
              disabled={!selectedPlan || !isChecked}
              onClick={() => {
                setIsOpen(false);
                setIsPaymentModalOpen(true);
              }}
            >
              המשך לתשלום
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectPlan;

const Plan = ({ index, currentPlan, data, selectedPlan, setSelectedPlan }) => {
  const [price, setPrice] = useState(null);

  // FETCH PLAN'S PRICE
  useEffect(() => {
    const fetchData = async () => {
      let payload = {
        planId: currentPlan._id,
      };
      try {
        const res = await SUBSCRIPTION.get_sub_plan_price(payload);
        setPrice(res.data.data.price);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // HANDLE ON CLICK PLAN
  const handleClickPlan = (plan) => {
    if (!plan.isDisabled && plan.planMode !== "FREE") {
      setSelectedPlan(plan._id);
    }
  };

  return (
    <div
      className={`
    ${styles.plan} 
    ${index === data?.length - 1 ? styles.active : ""} 
    ${selectedPlan === currentPlan._id ? styles.selected : ""}
    `}
      style={{
        cursor:
          !currentPlan.isDisabled && currentPlan.planMode !== "FREE"
            ? "pointer"
            : "not-allowed",
      }}
      onClick={() => handleClickPlan(currentPlan)}
    >
      {/* HEAD */}
      <div className={styles.head}>
        {/* for later */}
        {/* <div className={styles.tag}>
          <p>20% הנחה</p>
        </div> */}

        <h3>{currentPlan.title}</h3>
        <p>{currentPlan.displayTitle}</p>
      </div>

      {/* BODY */}
      <div className={styles.body}>
        {/* DISPLAY POINTS */}
        <ul>
          {currentPlan.displayPoints.map((i) => (
            <li>
              <CheckIcon1Svg fill="#06A816" />
              <p>{i.text}</p>
            </li>
          ))}
        </ul>

        {/* PRICE */}
        {price ? (
          <div className={styles.price}>
            <span>{price !== 0 && "₪"}</span>
            <h3>{price === 0 ? "חינמית" : price}</h3>
            <p>חודש</p>
          </div>
        ) : (
          <div className={styles.price}>
            <span></span>
            <h3>חינמית</h3>
          </div>
        )}

        {/* DESCRIPTION */}
        <p className={styles.text}>
          המחירים לא כוללים מע”מ, החבילה נחשבת להוצאה מוכרת
        </p>
      </div>

      {/* for later */}
      {/* <img
        src={
          index === data?.length - 1
            ? "/assets/eclipse-2.svg"
            : "/assets/eclipse-1.svg"
        }
        alt="eclipse"
        className={styles.eclipse}
      /> */}
    </div>
  );
};

import { get, post } from "../client";
import { CREATE_SCHEDULE, GET_SCHEDULE, GET_TIMESLOTS } from "../routes";

export default {
  get_timeslots: async (data) => await post(GET_TIMESLOTS, data),

  get_schedule: async (id) => await get(GET_SCHEDULE(id)),

  create_schedule: async (data) => await post(CREATE_SCHEDULE, data),
};

import styles from "../../styles/_containers/_management/cubes.module.scss";

const Cubes = ({
  numberOfClients = 0,
  appointmentsThisMonth = 0,
  appointmentsToday = 0,
}) => {
  return (
    <div className={styles.container}>
      <h2>פעילות היומן</h2>
      <div className={styles.cubes}>
        <div className={styles.cube}>
          <h3>{appointmentsToday}</h3>
          <hr />
          <p>תורים היום</p>
        </div>

        <div className={styles.cube}>
          <h3>{appointmentsThisMonth}</h3>
          <hr />
          <p>תורים החודש</p>
        </div>

        <div className={styles.cube}>
          <h3>{numberOfClients}</h3>
          <hr />
          <p>לקוחות</p>
        </div>
      </div>
    </div>
  );
};

export default Cubes;

import { format } from "date-fns";
import styles from "../../styles/_containers/_invoices/table.module.scss";

const Table = ({ data, setIsModalOpen }) => {

  return (
    <div className={styles.container}>
      <h2>חשבוניות</h2>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th style={{ width: "20%" }}>תאריך ושעה</th>
              {/* <th style={{ width: "20%" }}>כרטיס חיוב</th> */}
              <th style={{ width: "10%" }}>סה”כ</th>
              <th style={{ width: "30%" }}>מזהה עסקה</th>
              <th style={{ width: "20%" }}>סטטוס חיוב</th>
              <th style={{ width: "20%" }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((i) => (
              <tr key={i._id}>
                <td style={{ width: "20%" }}>
                  {format(new Date(i.createdAt), "dd/MM/yyyy")} -{" "}
                  {format(new Date(i.createdAt), "HH:mm")}
                </td>
                {/* <td style={{ width: "20%" }}>
                <div className={styles.row}>
                  *** 8570
                  <img src="/assets/mastercard.png" alt="mastercard" />
                  </div>
                </td> */}
                <td style={{ width: "10%" }}>₪{i.amount}</td>
                <td style={{ width: "30%" }}>{i.internalTransactionId}</td>
                <td style={{ width: "20%" }}>
                  <button
                    className={`${
                      i.status === "FAILED" ? styles.red : styles.green
                    }`}
                  >
                    {i.status === "FAILED" ? "נכשלה" : "הצליחה"}
                  </button>
                </td>
                <td style={{ width: "20%" }}>
                {i.status !== "FAILED" && ( // Conditionally render based on status
                    <div
                      className={styles.row}
                      onClick={() => setIsModalOpen(i._id)}
                    >
                      <img src="/assets/download-icon-1.svg" alt="download" />
                      שליחת חשבונית במייל
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;

import { del, get, post } from "../client";
import {
  ASSIGN_PLANS,
  CANCEL_MEMBERSHIP,
  CREATE_PLAN,
  DELETE_PLAN,
  FREEZE_UNFREEZE_PLAN,
  GET_ALL_PLANS,
  GET_USER_PLANS,
} from "../routes";

export default {
  get_all_plans: async () => await get(GET_ALL_PLANS),

  get_user_plans: async (data) => await post(GET_USER_PLANS, data),

  assign_plans: async (data) => await post(ASSIGN_PLANS, data),

  freezeUnFreezePlan: async (data) => await post(FREEZE_UNFREEZE_PLAN, data),

  cancelMembership: async (data) => await post(CANCEL_MEMBERSHIP, data),

  deletePlan: async (id) => await del(DELETE_PLAN(id)),

  create_plan: async (data) => await post(CREATE_PLAN, data),
};

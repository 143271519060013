export const hebrewDayNameGenerator = (name) => {
  if (name === "sunday") {
    return "יום ראשון";
  } else if (name === "monday") {
    return "יום שני";
  } else if (name === "tuesday") {
    return "יום שלישי";
  } else if (name === "wednesday") {
    return "יום רביעי";
  } else if (name === "thursday") {
    return "יום חמישי";
  } else if (name === "friday") {
    return "יום שישי";
  } else if (name === "saturday") {
    return "יום שבת";
  }
};

import { get, post, put, del } from "../client";
import {
  CREATE_SERVICE,
  DELETE_SERVICE,
  GET_ALL_SERVICES,
  GET_SERVICES,
  GET_SERVICE_BY_ID,
  UPDATE_SERVICE,
} from "../routes";

export default {
  get_all: async (data) => await post(GET_ALL_SERVICES, data),

  get: async (id) => await get(GET_SERVICES(id)),

  get_by_id: async (id) => await get(GET_SERVICE_BY_ID(id)),

  update: async (data) => await put(UPDATE_SERVICE, data),

  create: async (data) => await post(CREATE_SERVICE, data),

  del: async (id) => await del(DELETE_SERVICE(id)),
};

import React, { createContext, useState } from "react";
import AppRoutes from "./routes/routes";

export const LangContext = createContext();

const App = () => {
  const [lang, setLang] = useState("hebrew");

  return (
    <LangContext.Provider value={[lang, setLang]}>
      <AppRoutes />
    </LangContext.Provider>
  );
};

export default App;

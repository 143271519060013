export const SettingsIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_4862)">
        <mask
          id="mask0_61_4862"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="20"
        >
          <path
            d="M0.00146484 1.90735e-06H20.0015V20H0.00146484V1.90735e-06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_61_4862)">
          <path
            d="M17.4487 12.3947L17.4005 12.3673C16.8866 12.0755 16.5969 11.5012 16.6771 10.9157C16.7186 10.6131 16.74 10.3042 16.74 9.99024C16.74 9.65949 16.7161 9.3343 16.6701 9.01629C16.5852 8.42938 16.8772 7.85176 17.3928 7.55891L17.4487 7.52715C18.2015 7.10301 18.4665 6.15133 18.0407 5.40152C17.6149 4.65168 16.6596 4.3877 15.9069 4.8118L15.8383 4.8507C15.3167 5.14695 14.6705 5.08715 14.2021 4.71223C13.6864 4.2993 13.1087 3.96043 12.4853 3.71168C11.9306 3.49035 11.5642 2.95668 11.5642 2.35945V2.34375C11.5642 1.48082 10.8646 0.781252 10.0017 0.781252C9.13881 0.781252 8.4392 1.48082 8.4392 2.34375C8.4392 2.94371 8.06948 3.47922 7.51112 3.69871C6.88065 3.94656 6.29651 4.28645 5.7751 4.70184C5.30694 5.07481 4.6619 5.13285 4.14139 4.83723L4.09659 4.8118C3.34385 4.3877 2.3885 4.65168 1.96276 5.40152C1.53694 6.15133 1.80198 7.10301 2.55475 7.52715L2.56729 7.5343C3.08795 7.83 3.38248 8.41352 3.2958 9.00594C3.24877 9.32723 3.22436 9.6559 3.22436 9.99024C3.22436 10.3105 3.24674 10.6256 3.28987 10.934C3.3719 11.52 3.08135 12.0957 2.56678 12.3879L2.55475 12.3947C1.80201 12.8189 1.53694 13.7705 1.96276 14.5204C2.3885 15.2702 3.34385 15.5342 4.09659 15.1101L4.09881 15.1088C4.62147 14.812 5.27026 14.8707 5.73772 15.2486C6.2692 15.6782 6.86709 16.0289 7.51366 16.2828C8.07116 16.5017 8.4392 17.0377 8.4392 17.6367V17.6563C8.4392 18.5192 9.13881 19.2188 10.0017 19.2188C10.8646 19.2188 11.5642 18.5192 11.5642 17.6563V17.621C11.5642 17.0248 11.929 16.4905 12.4828 16.2698C13.1214 16.0153 13.712 15.6662 14.2374 15.2399C14.706 14.8596 15.3568 14.7977 15.8817 15.0958L15.9069 15.1101C16.6596 15.5342 17.6149 15.2702 18.0407 14.5204C18.4665 13.7705 18.2015 12.8189 17.4487 12.3947Z"
            stroke={fill}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.6814 10C6.6814 8.16625 8.16796 6.67969 10.0017 6.67969C11.8355 6.67969 13.322 8.16625 13.322 10C13.322 11.8338 11.8355 13.3203 10.0017 13.3203C8.16796 13.3203 6.6814 11.8338 6.6814 10Z"
            stroke={fill}
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_61_4862">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

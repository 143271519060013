import { useState, useEffect } from "react";
import Cubes from "../containers/_management/cubes";
import GeneralDetails from "../containers/_management/generalDetails";
import QuickActions from "../containers/_management/quickActions";
import Search from "../containers/_management/search";
import TodaysAppointments from "../containers/_management/todaysAppointments";
import Layout from "../layouts/layout";
import STATISTICS from "../api/services/STATISTICS";
import APPOINTMENTS from "../api/services/APPOINTMENTS";
import Loader from "../components/loader";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { ConfirmModal } from "../components/confirmModal";
import { toast } from "react-toastify";
import { ShareModal } from "../components/shareModal";
import CommentModal from "../components/commentModal";
import styles from "../styles/_screens/management.module.scss";

const Management = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);
  const [todayAppointments, setTodayAppointments] = useState(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  // FETCH STATISTICS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await STATISTICS.get_statistics();
        setData(res.data);
      } catch (error) {
        console.log(error);
        if (
          error.response.data.message === "Invalid Token" ||
          error.response.data.message ===
            "A token is required for authentication"
        ) {
          localStorage.clear();
          navigate(paths.login);
        }
      }
    };

    fetchData();
  }, []);

  // FETCH TODAY'S APPOINTMENTS
  useEffect(() => {
    setSuccess(null);
    const fetchData = async () => {
      let payload = {
        employeeId: businessId,
        date: format(new Date(), "yyyy-MM-dd"),
      };
      try {
        const res = await APPOINTMENTS.get_employees_appointments(payload);

        if (res.data) {
          let ownerAppointments = res.data.data;
          setTodayAppointments(ownerAppointments);
        } else {
          setTodayAppointments([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [success]);

  if (!data) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.main}>
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
  // CANCEL APPOINTMENT
  const cancelAppointment = async () => {
    let payload = {
      id: isConfirmModalOpen,
      status: 3,
    };
    setConfirmModalLoading(true);
    try {
      const res = await APPOINTMENTS.cancel_appointment(payload);
      setSuccess(true);
      setIsConfirmModalOpen(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.messsage);
    }
    setConfirmModalLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* <Search search={search} setSearch={setSearch} /> */}
          <div className={styles.main}>
            {/* COLUMN ONE */}
            <div className={`${styles.columnOne} ${styles.column}`}>
              <Cubes
                numberOfClients={data.totalUsers}
                appointmentsThisMonth={data.totalAppointmentThisMonth}
                appointmentsToday={todayAppointments?.length}
              />
              {/* {todayAppointments &&
                (todayAppointments.length > 0 ? (
                  <TodaysAppointments
                    data={todayAppointments}
                    setIsCommentModalOpen={setIsCommentModalOpen}
                    setIsConfirmModalOpen={setIsConfirmModalOpen}
                  />
                ) : (
                  <h3>לא נקבעו תורים היום</h3>
                ))} */}
            </div>

            {/* COLUMN TWO */}
            <div className={`${styles.columnTwo} ${styles.column}`}>
              <GeneralDetails
                title="מידע כללי"
                revenueToday={data.revenueToday}
                revenueThisWeek={data.revenueThisWeek}
                revenueThisMonth={data.revenueThisMonth}
                completedAppointmentsThisMonth={
                  data.completedAppointmentsThisMonth
                }
                futureAppointmentsThisMonth={data.futureAppointmentsThisMonth}
                cancelledAppointmentsThisMonth={
                  data.cancelledAppointmentsThisMonth
                }
                totalAppointmentThisMonth={data.totalAppointmentThisMonth}
                setIsOpen={setIsShareModalOpen}
              />
            </div>

            {/* COLUMN THREE */}
            <div className={`${styles.columnThree} ${styles.column}`}>
              <QuickActions />
            </div>
          </div>
        </div>

        {isCommentModalOpen && (
          <CommentModal
            setIsOpen={setIsCommentModalOpen}
            appointmentId={isCommentModalOpen}
          />
        )}

        {isConfirmModalOpen && (
          <ConfirmModal
            setIsOpen={setIsConfirmModalOpen}
            handleClick={cancelAppointment}
            loading={confirmModalLoading}
          />
        )}

        {isShareModalOpen && <ShareModal setIsOpen={setIsShareModalOpen} />}
      </div>
    </Layout>
  );
};

export default Management;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../layouts/layout";
import SideMenu from "../components/sideMenu";
import { toast } from "react-toastify";
import { paths } from "../routes/paths";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import { CalendarIcon1Svg } from "../svgs/calendarIcon1Svg";
import { ClockIcon1Svg } from "../svgs/clockIcon1Svg";
import { SettingsIcon1Svg } from "../svgs/settingsIcon1Svg";
import Card from "../containers/_workingHours/card";
import SCHEDULE from "../api/services/SCHEDULE";
import SERVICES from "../api/services/SERVICES";
import Spinner from "../components/spinner";
import Loader from "../components/loader";
import styles from "../styles/_screens/workingHours.module.scss";

const WorkingHours = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const sideMenuList = [
    {
      id: 1,
      svg: <AddPlusSvg fill="#000000" />,
      text: "הוספת תור חדש",
      active: false,
      onClick: () => navigate(paths.createAppointment),
    },
    {
      id: 2,
      svg: <CalendarIcon1Svg fill="#000000" />,
      text: "יומן שבועי",
      active: false,
      onClick: () => navigate(paths.appointments),
    },
    {
      id: 3,
      svg: <SettingsIcon1Svg fill="#000000" />,
      text: "הגדרות יומן ותורים",
      active: false,
      onClick: () => navigate(paths.settings),
    },
    {
      id: 4,
      svg: <ClockIcon1Svg fill="#DE1468" />,
      text: "הגדרות קבלת קהל",
      active: true,
    },
  ];

  const [days, setDays] = useState(null);

  const [daysInOrder, setDaysInOrder] = useState(null);

  const [allServices, setAllServices] = useState(null);

  const [daysLoading, setDaysLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  // FETCH SCHEDULE
  useEffect(() => {
    const fetchData = async () => {
      setDaysLoading(true);
      try {
        const res = await SCHEDULE.get_schedule(id);
        setDays(res.data.days);
        if (res.code === 200) {
          let formattedDays = res.data.days.map((i) => i.name);

          let a1 = [
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday",
          ];
          let a2 = formattedDays;

          let missingDays = a1.filter((e) => !a2.includes(e));

          let payload = {
            employeeId: id,
            days: [],
          };
          if (missingDays.length > 0) {
            missingDays.map((day) => {
              payload.days.push({
                name: day,
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: [],
                  },
                ],
              });
            });

            if (res.data.days.length > 0) {
              payload.days = [...payload.days, ...res.data.days];
            }

            const res2 = await SCHEDULE.create_schedule(payload);
            if (res2.status === 201) {
              window.location.reload();
            }
          }
        }
      } catch (error) {
        console.log(error);
        if (error.response.data.result.statusCode === 404) {
          const payload = {
            employeeId: id,
            days: [
              {
                name: "monday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: [],
                  },
                ],
              },
              {
                name: "tuesday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: [],
                  },
                ],
              },
              {
                name: "wednesday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: [],
                  },
                ],
              },
              {
                name: "thursday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: [],
                  },
                ],
              },
              {
                name: "friday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: [],
                  },
                ],
              },
              {
                name: "saturday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: [],
                  },
                ],
              },
              {
                name: "sunday",
                status: false,
                timing: [
                  {
                    start_time: "",
                    end_time: "",
                    services: [],
                  },
                ],
              },
            ],
          };

          const res = await SCHEDULE.create_schedule(payload);
          if (res.status === 201) {
            window.location.reload();
          }
          console.log(res, "ERROR CREATE");
        }
      }
      setDaysLoading(false);
    };

    fetchData();
  }, []);

  // RE-ARRANGE DAYS
  useEffect(() => {
    if (days && days.length === 7) {
      const dayOrder = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      function compareDays(a, b) {
        return dayOrder.indexOf(a.name) - dayOrder.indexOf(b.name);
      }
      let newArr = days.sort(compareDays);

      // ADD LEADING ZERO
      newArr.forEach(function (innerArray) {
        innerArray.timing.forEach(function (obj) {
          if (obj.start_time.length === 4) {
            obj.start_time = "0" + obj.start_time;
          }

          if (obj.end_time.length === 4) {
            obj.end_time = "0" + obj.end_time;
          }
        });
      });

      setDaysInOrder(newArr);
    }
  }, [days]);

  // FETCH ALL SERVICES
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SERVICES.get(id);
        setAllServices(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // SAVE SCHEDULE
  const saveSchedule = async () => {
    const payload = {
      employeeId: id,
      days: daysInOrder,
    };
    setLoading(true);
    try {
      const res = await SCHEDULE.create_schedule(payload);
      if (res.status === 201) {
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          {businessId === id && <SideMenu list={sideMenuList} />}

          <div className={styles.main}>
            <div className={styles.top}>
              <div className={styles.text}>
                <h1>הגדרות קבלת קהל</h1>
                <p>בחירת זמני קבלת קהל</p>
              </div>
              <button onClick={saveSchedule}>
                {loading ? <Spinner /> : "שמירת שינויים"}
              </button>
            </div>

            {daysLoading || !daysInOrder ? (
              <Loader />
            ) : (
              <Card
                data={daysInOrder}
                setData={setDaysInOrder}
                allServices={allServices}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WorkingHours;

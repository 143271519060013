export const HelpIconSvg = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_484_2177)">
        <mask
          id="mask0_484_2177"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="23"
          height="22"
        >
          <path
            d="M0.000976562 1.90735e-06H22.001V22H0.000976562V1.90735e-06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_484_2177)">
          <path
            d="M16.9307 21.1406C14.5996 21.1406 12.6975 19.2235 12.7199 16.8883L12.7197 12.7188L12.7896 12.7886C13.3797 13.3787 14.2829 13.5343 15.029 13.1603C15.5938 12.8773 16.2328 12.7184 16.9119 12.7188C19.2298 12.7177 21.1192 14.5705 21.1414 16.8883C21.1639 19.2235 19.2993 21.1406 16.9307 21.1406Z"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.860352 16.9297C0.860352 14.5986 2.77749 12.6965 5.11267 12.719L9.28223 12.7188L9.2124 12.7886C8.62227 13.3787 8.46672 14.2819 8.84068 15.0281C9.12371 15.5928 9.28253 16.2318 9.28223 16.9109C9.28326 19.2288 7.43044 21.1182 5.11267 21.1404C2.77749 21.1629 0.860352 19.2984 0.860352 16.9297Z"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.07127 0.859375C7.40233 0.859375 9.30443 2.77651 9.282 5.11169L9.28221 9.28125L9.21239 9.21143C8.62225 8.62129 7.71905 8.46575 6.9729 8.8397C6.40816 9.12274 5.76913 9.28159 5.09005 9.28125C2.77215 9.28232 0.882767 7.42947 0.860553 5.11169C0.83808 2.77651 2.70262 0.859375 5.07127 0.859375Z"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.1416 5.07031C21.1416 7.40137 19.2245 9.30351 16.8893 9.28104L12.7197 9.28125L12.7895 9.21143C13.3797 8.62129 13.5352 7.71809 13.1613 6.97194C12.8782 6.4072 12.7194 5.76817 12.7197 5.08909C12.7187 2.77118 14.5715 0.881806 16.8893 0.859592C19.2245 0.837119 21.1416 2.70166 21.1416 5.07031Z"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.0713 5.07031H17.79"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.93066 5.07031H4.21191"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.21191 16.9727H5.93066"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.0713 16.9727H17.79"
            stroke={fill}
            strokeWidth="1.32"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_484_2177">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import Modal from "./modal";
import { Caution } from "../svgs/caution";
import Spinner from "./spinner";
import styles from "../styles/_components/confirmModal.module.scss";

export const ConfirmModal = ({ setIsOpen, handleClick, loading }) => {
  return (
    <Modal setIsOpen={setIsOpen} onClick={() => null}>
      <div className={styles.container}>
        <Caution fill="#de1468" />
        <h2>האם אתם בטוחים שתרצו לבצע פעולה זו?</h2>
        <div className={styles.btns}>
          <button onClick={handleClick} style={{ backgroundColor: "#de1468" }}>
            {loading ? <Spinner /> : "כן"}
          </button>
          <button
            onClick={() => setIsOpen(false)}
            style={{
              border: `1px solid #de1468`,
              color: "#de1468",
              background: "#fff",
            }}
          >
            לא
          </button>
        </div>
      </div>
    </Modal>
  );
};

export function calculateEndTimeOfAppointment(start, duration) {
  // Parse hours and minutes from start time
  let [hour, minute] = start.split(":").map(Number);

  // Add duration to start time
  hour += Math.floor((minute + duration) / 60);
  minute = (minute + duration) % 60;

  // Format time as HH:mm
  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`;
}

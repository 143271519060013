import styles from "../../styles/_containers/_subscription/customizedCard.module.scss";

const CustomizedCard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.right}>
        <img src="/assets/customization-img-1.png" alt="customization" />
      </div>
      <div className={styles.left}>
        <h4>התחל עם מוצר בהתאמה אישית שנבנה לצרכים שלך!</h4>
        <p>
          זקוקים למערכת בהתאמה אישית שתנהל לכם את סדר היום?
          <br />
          צוות תור דיגיטל קשובה לכם! צרו קשר עכשיו עם הצוות
        </p>
        <button>מעבר לצוות המכירות</button>
      </div>
    </div>
  );
};

export default CustomizedCard;

import styles from "../styles/_layouts/storeBar.module.scss";

const StoreBar = ({ handleClose }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.right}>
          <p>התחברו דרך האפליקצייה {">>"}</p>
          <div className={styles.imgs}>
            <a href="http://itunes.apple.com/app/id1602074493" target="_blank">
              <img src="/assets/appstore-img.svg" alt="appstore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.adminappointmentapp" target="_blank">
              <img src="/assets/google-play-img.svg" alt="google-play" />
            </a>
          </div>
        </div>
        <div className={styles.left}>
          <div className={styles.close} onClick={handleClose}>
            &times;
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreBar;

export const BellIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_2284)">
        <path
          d="M1.4903 33.9427L9.61273 33.9568C13.3391 33.9396 16.9089 32.5357 19.5452 30.0475L33.4813 16.8941C35.3185 15.1601 35.3185 12.3486 33.4813 10.6147C33.3687 10.5084 33.2516 10.4088 33.131 10.3155H51.0038C53.5956 10.3155 55.6966 8.33241 55.6966 5.88626C55.6966 3.43997 53.5956 1.45703 51.0038 1.45703H26.141C23.3501 1.45703 20.6713 2.49368 18.6836 4.34281L1.4903 20.338"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.1996 19.1699C59.8398 20.515 63.2594 22.4309 66.3476 24.8719"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.1169 27.0391C56.4072 27.9561 58.579 29.1658 60.5856 30.6488"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.2115 32.2114V26.8594"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.2116 50.2188V58.1352"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.3167 50.0117C51.3167 54.4958 54.9518 58.1308 59.4358 58.1308H62.6377"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.166 50.0117C35.166 54.4958 31.5309 58.1308 27.047 58.1308H23.8451"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.808 66.3806H22.6148C20.8436 66.3806 19.6174 64.612 20.2386 62.9532L21.9893 58.1445H64.4335L66.1842 62.9532C66.8055 64.612 65.5793 66.3806 63.808 66.3806Z"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.9942 49.9482C62.001 40.2967 53.4535 33.041 43.2116 33.041C32.9696 33.041 24.4221 40.2965 22.429 49.9481H63.9942V49.9482Z"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.2551 26.0258H39.1677V22.4247C39.1677 20.1913 40.9781 18.3809 43.2115 18.3809C45.4448 18.3809 47.2553 20.1913 47.2553 22.4247L47.2551 26.0258Z"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.1308 10.3156C31.2829 8.88742 28.5525 8.98732 26.828 10.6149L16.4641 20.3969"
          stroke={fill}
          strokeWidth="2.91492"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_2284">
          <rect width="67.8382" height="67.8382" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

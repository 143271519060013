import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import PROFILE from "../../api/services/PROFILE";
import styles from "../../styles/_containers/_management/generalDetails.module.scss";

const GeneralDetails = ({
  title,
  revenueToday,
  revenueThisWeek,
  revenueThisMonth,
  completedAppointmentsThisMonth,
  futureAppointmentsThisMonth,
  cancelledAppointmentsThisMonth,
  totalAppointmentThisMonth,
  setIsOpen,
}) => {
  const [chartData, setChartData] = useState({
    options: {
      colors: ["#0263FF", "#FF7723", "#FE0000"],
      chart: {
        width: "152px",
        height: "152px",
        type: "pie",
      },
      dataLabels: {
        enabled: false,
      },
      // labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],

      labels: [
        `${completedAppointmentsThisMonth} הושלמו`,
        `${futureAppointmentsThisMonth} עתידיים`,
        `${cancelledAppointmentsThisMonth} מבוטלים`,
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              // width: 300,
            },
            legend: {
              position: "right",
            },
          },
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 300,
              position: "right",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  const [businessCode, setBusinessCode] = useState(null);

  // FETCH PROFILE DETAILS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await PROFILE.get();
        setBusinessCode(res.data.businessId);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(`http://app.tor.digital/${businessCode}`)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000); // Reset the copied state after 2 seconds
      })
      .catch((error) => console.error("Error copying text:", error));
  };

  return (
    <div className={styles.container}>
      <h2>{title}</h2>

      <div className={styles.card}>
        <h3>הכנסות בעסק</h3>

        <div className={styles.smallCards}>
          <div className={styles.smallCard}>
            <h4>₪{revenueToday}</h4>
            <p>היום</p>
          </div>

          <span />

          <div className={styles.smallCard}>
            <h4>₪{revenueThisWeek}</h4>
            <p>השבוע</p>
          </div>

          <span />

          <div className={styles.smallCard}>
            <h4>₪{revenueThisMonth}</h4>
            <p>החודש</p>
          </div>
        </div>

        <h3>תורים</h3>

        <div className={styles.subCard}>
          <p className={styles.text}>גלו את נתוני העסק שלכם:</p>

          <div className={styles.chartContainer}>
            <div className={styles.right}>
              <small>
                סה”כ תורים: <span>{totalAppointmentThisMonth}</span>
              </small>
            </div>

            <div className={styles.left}>
              {/* <img src="/assets/chart.png" alt="chart" /> */}
              <div id="chart" className={styles.chart}>
                <ReactApexChart
                  options={chartData.options}
                  series={[
                    completedAppointmentsThisMonth,
                    futureAppointmentsThisMonth,
                    cancelledAppointmentsThisMonth,
                  ]}
                  type="pie"
                  width={318}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.copyCard}>
          {/* <div className={styles.code}>
            <p>מס’ משתמש: 245889</p>
          </div> */}

          <p className={styles.text}>
            שמור את הלינק לעסק שלך, הקישור ישמש את לקוחותיך בעת ההרשמה לקביעת
            תורים אצלך
          </p>

          <div className={styles.copy}>
            <a target="_blank" href={`http://app.tor.digital/${businessCode}`}>
              <p>http://app.tor.digital/{businessCode}</p>
            </a>
            <img
              src="/assets/copy-icon.svg"
              alt="copy"
              onClick={handleCopyClick}
            />
          </div>

          {isCopied && (
            <p className={styles.copied}>Text copied to clipboard</p>
          )}

          <button onClick={() => setIsOpen(true)}>
            <img src="/assets/upload-icon-1.svg" alt="share" />
            הזמנת לקוחות
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneralDetails;

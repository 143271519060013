import { useState } from "react";
import ImageUpload from "../../components/imageUpload";
import Modal from "../../components/modal";
import CUSTOMERS from "../../api/services/CUSTOMERS";
import styles from "../../styles/_containers/_clients/createClient.module.scss";
import { toast } from "react-toastify";
import Spinner from "../../components/spinner";

const CreateClient = ({ setIsOpen }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  // CREATE CLIENT
  const handleSubmit = async () => {
    let payload = {
      name,
      phone,
    };
    setLoading(true);
    try {
      const res = await CUSTOMERS.create_client(payload);
      toast.success(res.data.message);
      setIsOpen(false)
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsOpen(false)
    }
    setLoading(false);
  };

  return (
    <Modal setIsOpen={setIsOpen}>
      <div className={styles.container}>
        <h2>הוספת לקוח חדש</h2>
        <p className={styles.text}>
          לאחר העלאת הלקוח יהיה ניתן לשלוח הודעות וליצור תורים עדשים עבור לקוח
          זה
        </p>

        {/* <div className={styles.imgInputContainer}>
          <h4>תמונה הלקוח (אוציונלי)</h4>
          <ImageUpload />
        </div> */}

        <div className={styles.form}>
          <div className={styles.field}>
            <label>
              שם הלקוח <span>*</span>
            </label>
            <input
              type="text"
              placeholder="רשום את שם העובד"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>
              מספר טלפון <span>*</span>
            </label>
            <input
              type="text"
              placeholder="הזן טלפון העובד"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          {/* 
          <div className={styles.field}>
            <label>תאריך לידה</label>
            <input type="text" placeholder="הזן טלפון העובד" />
          </div>

          <div className={styles.field}>
            <label>עיר מגורים</label>
            <input type="text" placeholder="הזן טלפון העובד" />
          </div>

          <div className={styles.field}>
            <label>דוא”ל</label>
            <input type="text" placeholder="הזן כתובת מייל של הלקוח" />
          </div> */}

          <button onClick={handleSubmit} disabled={!name || !phone}>
            {loading ? <Spinner /> : "צור לקוח חדש"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateClient;

import { useState } from "react";
import Layout from "../layouts/layout";
import SideMenu from "../components/sideMenu";
import { BubbleIconSvg } from "../svgs/bubbleIconSvg";
import { MailIcon1Svg } from "../svgs/mailIcon1Svg";
import PopupMessage from "../containers/_createNotifications/popupMessage";
import BroadcastMessage from "../containers/_createNotifications/broadcastMessage";
import styles from "../styles/_screens/createNotifications.module.scss";

const CreateNotifications = () => {
  const [step, setStep] = useState(0);

  const sideMenuList = [
    {
      id: 1,
      svg: <BubbleIconSvg fill={step === 0 ? "#DE1468" : "#000000"} />,
      text: "הודעות פופ-אפ",
      active: step === 0,
      onClick: () => setStep(0),
    },
    {
      id: 2,
      svg: <MailIcon1Svg fill={step === 1 ? "#DE1468" : "#000000"} />,
      text: "הודעות SMS",
      active: step === 1,
      onClick: () => setStep(1),
    },
  ];

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SIDE MENU */}
          <SideMenu list={sideMenuList} />

          <div className={styles.main}>
            {step === 0 ? <PopupMessage /> : <BroadcastMessage />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateNotifications;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { hebrewDayNameGenerator } from "../../utils/hebrewDayNameGenerator";
import { calculateEndTimeOfAppointment } from "../../utils/calculateEndTimeOfAppointment";
import { CalendarIcon1Svg } from "../../svgs/calendarIcon1Svg";
import { ClockIcon1Svg } from "../../svgs/clockIcon1Svg";
import { LocationIcon2Svg } from "../../svgs/locationIcon2Svg";
import { PadLockIconSvg } from "../../svgs/padLockIconSvg";
import { UserGroupIconSvg } from "../../svgs/userGroupIconSvg";
import APPOINTMENTS from "../../api/services/APPOINTMENTS";
import styles from "../../styles/_containers/_class/cards.module.scss";

const Cards = () => {
  const { appointmentId } = useParams();

  const [appointmentData, setAppointmentData] = useState(null);

  let user_id = localStorage.getItem("user_id");
  user_id = JSON.parse(user_id);

  // ========= FETCH APPOINTMENTS =========
  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          id: user_id,
        };
        const res = await APPOINTMENTS.get_appointments(payload);

        let myAppointments;
        if (res.data.data.length > 0) {
          myAppointments = res.data.data.find((i) => i.id === user_id);
          if (myAppointments) {
            let currAppo = myAppointments?.appoitnemts.find(
              (i) => i._id === appointmentId
            );
            setAppointmentData(currAppo);
          } else {
            setAppointmentData(null);
          }
        } else {
          setAppointmentData(null);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (!appointmentData) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <CalendarIcon1Svg fill="#ffffff" />
        <h3>תאריך השיעור</h3>
        <hr />
        <p>
          {hebrewDayNameGenerator(
            format(new Date(appointmentData?.date), "EEEE").toLowerCase()
          )}
          , {format(new Date(appointmentData?.date), "dd/MM/yyyy")}
        </p>
      </div>
      <div className={styles.card}>
        <ClockIcon1Svg fill="#ffffff" />
        <h3>שעת השיעור</h3>
        <hr />
        <p>
          {appointmentData.timeSlot}-
          {calculateEndTimeOfAppointment(
            appointmentData.timeSlot,
            appointmentData.serviceId.time
          )}
        </p>
      </div>
      {appointmentData.serviceId.location && (
        <div className={styles.card}>
          <LocationIcon2Svg fill="#ffffff" />
          <h3>מיקום השיעור</h3>
          <hr />
          <p>{appointmentData.serviceId.location}</p>
        </div>
      )}
      <div className={styles.card}>
        <UserGroupIconSvg fill="#ffffff" />
        <h3>משתתפים</h3>
        <hr />
        <p>
          {appointmentData.numberOfAttendants}/
          {appointmentData.serviceId.maximumQueue}
        </p>
      </div>
      <div className={styles.card}>
        <PadLockIconSvg fill="#ffffff" />
        <h3>סוג השיעור</h3>
        <hr />
        <p>
          {appointmentData.serviceId.forSubscriptionOnly
            ? "למנויים בלבד"
            : "פתוח לכולם"}
        </p>
      </div>
    </div>
  );
};

export default Cards;

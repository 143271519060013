import styles from "../styles/_components/stepper.module.scss";

const Stepper = ({ step }) => {
  return (
    <div className={styles.container}>
      {/* USERS STEP */}
      <div
        className={`${styles.step} ${step === 0 ? styles.active : ""} ${
          step > 0 ? styles.checked : ""
        }`}
      >
        <div className={styles.circle}>
          <span>1</span>
        </div>
        <p>
          בחירת
          <br />
          לקוח
        </p>
      </div>
      <hr />

      {/* EMPLOYEES STEP */}
      <div
        className={`${styles.step} ${step === 1 ? styles.active : ""} ${
          step > 1 ? styles.checked : ""
        }`}
      >
        <div className={styles.circle}>
          <span>2</span>
        </div>
        <p>
          בחירת
          <br />
          עובד/ת
        </p>
      </div>
      <hr />

      {/* SERVICES STEP */}
      <div
        className={`${styles.step} ${step === 2 ? styles.active : ""} ${
          step > 2 ? styles.checked : ""
        }`}
      >
        <div className={styles.circle}>
          <span>3</span>
        </div>
        <p>
          בחירת
          <br />
          שירות
        </p>
      </div>

      <hr />

      {/* DATE */}
      <div
        className={`${styles.step} ${step === 3 ? styles.active : ""} ${
          step > 3 ? styles.checked : ""
        }`}
      >
        <div className={styles.circle}>
          <span>4</span>
        </div>
        <p>
          בחירת יום
          <br />
          ושעה
        </p>
      </div>
    </div>
  );
};

export default Stepper;

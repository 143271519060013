import styles from "../styles/_components/modal.module.scss";

const Modal = ({ children, setIsOpen, onClick, type }) => {
  return (
    <div className={styles.mainContainer} onClick={onClick}>
      <div
        className={styles.backdrop}
        onClick={() => setIsOpen && setIsOpen(false)}
      ></div>
      <div
        className={`${styles.container} 
        ${type === 2 ? styles.secondaryContainer : ""} 
        ${type === 3 ? styles.tertiaryContainer : ""}`}
      >
        <span
          className={styles.close}
          onClick={() => setIsOpen && setIsOpen(false)}
        >
          &times;
        </span>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;

import { useState } from "react";
import { toast } from "react-toastify";
import Modal from "../../components/modal";
import Spinner from "../../components/spinner";
import SUBSCRIPTION from "../../api/services/SUBSCRIPTION";
import styles from "../../styles/_containers/_subscription/deleteConfirmation.module.scss";

const DeleteConfirmation = ({ setIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await SUBSCRIPTION.cancel_sub();
      toast.success(res.data.message);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsOpen(false);
    }
    setLoading(false);
  };

  return (
    <Modal setIsOpen={setIsOpen}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h2>ביטול חשבון</h2>
          <p className={styles.mainText}>
            אנו מתנצלים לשמוע שאתה מעוניין לבטל את החשבון שלך
          </p>

          {/* FOR LATER  */}
          {/* <div className={styles.form}>
            <div className={styles.field}>
              <label>
                סיבת הביטול
                <span>*</span>
              </label>
              <input type="text" placeholder="עזרו לנו לשפר את המערכת" />
            </div>

            <div className={styles.field}>
              <label>
                מס’ חשבון
                <span>*</span>
              </label>
              <input
                type="text"
                placeholder="הזן את מספר המשתמש של מנהל החשבון"
              />
            </div>

            <div className={styles.field}>
              <label>דוא”ל</label>
              <input type="text" placeholder="הזן כתובת מייל של הלקוח" />
            </div>
          </div>

          <div className={styles.discount}>
            <h4>הטבה מיוחדת!</h4>
            <p>לפני שאתם עוזבים קבלו מאיתנו 50% הנחה ל3 חודשים</p>

            <div className={styles.code}>
              <h3>ECC24R</h3>
            </div>
          </div> */}

          {/* <button>צור לקוח חדש</button> */}

          <button onClick={handleSubmit}>
            {loading ? <Spinner /> : "ביטול חבילה וחשבון"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;

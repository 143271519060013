export const CheckIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_631)">
        <path
          d="M9 0C4.02528 0 0 4.02571 0 9C0 13.9747 4.02571 18 9 18C13.9747 18 18 13.9743 18 9C18 4.02528 13.9743 0 9 0ZM9 16.8C4.69909 16.8 1.19999 13.3009 1.19999 9C1.19999 4.69905 4.69909 1.19999 9 1.19999C13.3009 1.19999 16.8 4.69909 16.8 9C16.8 13.3009 13.3009 16.8 9 16.8Z"
          fill={fill}
        />
        <path
          d="M13.7642 5.69283C13.5299 5.45851 13.15 5.45851 12.9157 5.69283L7.57415 11.0344L5.08439 8.54463C4.85007 8.31031 4.47014 8.31031 4.23586 8.54463C4.00154 8.77895 4.00154 9.15885 4.23586 9.39316L7.14985 12.3072C7.38413 12.5414 7.76417 12.5414 7.99838 12.3072L13.7642 6.54132C13.9985 6.30701 13.9985 5.92714 13.7642 5.69283Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5_631">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { RemoveIcon1Svg } from "../../svgs/removeIcon1Svg";
import styles from "../../styles/_containers/_subscription/subDetails.module.scss";
import { format } from "date-fns";

const SubDetails = ({ setIsDeleteModalOpen, setIsPlanModalOpen, currentPlan, subscription }) => {
  
  return (
    <div className={styles.container}>
      <h2>חבילה נוכחית במנוי</h2>

      <div className={styles.items}>
        <div className={styles.block}>
          <h4>סוג חבילה</h4>
          <h3>{currentPlan.displayTitle}</h3>
        </div>
        <span className={styles.line} />
        <div className={styles.block}>
          <h4>שיטת תשלום</h4>
          <h3>חודשי</h3>
        </div>
        <span className={styles.line} />
        <div className={styles.block}>
          <h4>תאריך חיוב</h4>
          <h3>{format(new Date(subscription.endDate), "dd/MM/yyyy")}</h3>
        </div>
        <span className={styles.line} />
        <div className={styles.block}>
          <h4>סה”כ לחיוב</h4>
          {currentPlan.planMode === "FREE" ? <h3>חינם</h3> : <h3>₪{currentPlan.price}</h3>}
        </div>
      </div>

      <div className={styles.btns}>
        <button onClick={() => setIsPlanModalOpen(true)}>
          <p>שדרוג תוכנית</p>
        </button>
        <button onClick={() => setIsDeleteModalOpen(true)}>
          <RemoveIcon1Svg fill="#ffffff" />
          <p>ביטול חשבון</p>
        </button>
      </div>
    </div>
  );
};

export default SubDetails;

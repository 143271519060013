import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddPlusSvg } from "../svgs/addPlusSvg";
import Search from "../containers/_services/search";
import Items from "../containers/_services/items";
import Create from "../containers/_services/create";
import Loader from "../components/loader";
import Layout from "../layouts/layout";
import SERVICES from "../api/services/SERVICES";
import styles from "../styles/_screens/services.module.scss";
import { ConfirmModal } from "../components/confirmModal";

const Services = () => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);

  const [responseStatus, setResponseStatus] = useState(null);

  let businessId = localStorage.getItem("user_id");
  businessId = JSON.parse(businessId);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);

  // FETCH SERVICES
  useEffect(() => {
    setResponseStatus(null);
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await SERVICES.get_all({ business_id: businessId });
        setServices(res.data.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [responseStatus]);

  // DELETE SERVICE
  const deleteService = async (id) => {
    setConfirmModalLoading(true);
    try {
      const res = await SERVICES.del(id);
      if (res.status === 200) {
        toast.success(res.data.message);
        setResponseStatus(true);
        setIsConfirmModalOpen(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setConfirmModalLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* <Search search={search} setSearch={setSearch} /> */}

          <div
            className={`${styles.add} ${isOpen ? styles.active : ""}`}
            onClick={() => {
              setIsOpen("create");
            }}
          >
            <AddPlusSvg fill={isOpen ? "#de1468" : "#000000"} />
            <p>הוספת שירות</p>
          </div>

          <div className={styles.main}>
            {loading ? (
              <Loader />
            ) : (
              <Items
                services={services}
                deleteService={deleteService}
                setIsOpen={setIsOpen}
                setIsConfirmModalOpen={setIsConfirmModalOpen}
              />
            )}
            {isOpen && (
              <Create
                isOpen={isOpen}
                businessId={businessId}
                setResponseStatus={setResponseStatus}
              />
            )}
          </div>
        </div>

        {isConfirmModalOpen && (
          <ConfirmModal
            setIsOpen={setIsConfirmModalOpen}
            handleClick={() => deleteService(isConfirmModalOpen)}
            loading={confirmModalLoading}
          />
        )}
      </div>
    </Layout>
  );
};

export default Services;

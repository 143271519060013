export const PadLockIconSvg = ({ fill }) => {
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5001 1.9707C23.6471 1.9707 15.7648 9.85306 15.7648 19.706V27.5884C12.4148 27.5884 9.85303 30.1501 9.85303 33.5001V59.1178C9.85303 62.4678 12.4148 65.0295 15.7648 65.0295H51.2354C54.5854 65.0295 57.1471 62.4678 57.1471 59.1178V33.5001C57.1471 30.1501 54.5854 27.5884 51.2354 27.5884V19.706C51.2354 9.85306 43.353 1.9707 33.5001 1.9707ZM53.206 33.5001V59.1178C53.206 60.3001 52.4177 61.0883 51.2354 61.0883H15.7648C14.5824 61.0883 13.7942 60.3001 13.7942 59.1178V33.5001C13.7942 32.3178 14.5824 31.5295 15.7648 31.5295H17.7354H49.2648H51.2354C52.4177 31.5295 53.206 32.3178 53.206 33.5001ZM19.706 27.5884V19.706C19.706 12.0207 25.8148 5.91188 33.5001 5.91188C41.1854 5.91188 47.2942 12.0207 47.2942 19.706V27.5884H19.706Z"
        fill={fill}
      />
      <path
        d="M33.5 37.4414C30.15 37.4414 27.5883 40.0032 27.5883 43.3532C27.5883 45.9149 29.1647 48.0826 31.5294 48.8708V53.2061C31.5294 54.3885 32.3177 55.1767 33.5 55.1767C34.6824 55.1767 35.4706 54.3885 35.4706 53.2061V48.8708C37.8353 48.0826 39.4118 45.9149 39.4118 43.3532C39.4118 40.0032 36.85 37.4414 33.5 37.4414ZM33.5 45.3238C32.3177 45.3238 31.5294 44.5355 31.5294 43.3532C31.5294 42.1708 32.3177 41.3826 33.5 41.3826C34.6824 41.3826 35.4706 42.1708 35.4706 43.3532C35.4706 44.5355 34.6824 45.3238 33.5 45.3238Z"
        fill={fill}
      />
    </svg>
  );
};

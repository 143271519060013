import { useEffect, useState } from "react";
import ClientCard from "./clientCard";
import CUSTOMERS from "../../api/services/CUSTOMERS";
import Loader from "../../components/loader";
import styles from "../../styles/_containers/_clients/clientsList.module.scss";

const ClientsList = ({ search, success, setSuccess, setIsOpenModal }) => {
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20); // Set size to 20 as per requirement
  const [type, setType] = useState("all");
  const [totalPages, setTotalPages] = useState(1);

  // FETCH CLIENTS
  useEffect(() => {
    setSuccess(null);
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await CUSTOMERS.get(page, size, type, search);
        if (res && res.code === 200) {
          setClientsList(res.data); // Assuming res.data is the array of clients
          setTotalPages(res.totalPages); // Set totalPages
        } else {
          // Handle cases where the response is not as expected
          setClientsList([]);
          setTotalPages(1);
        }
      } catch (error) {
        console.log(error);
        // In case of an error, clear the clients list and reset totalPages
        setClientsList([]);
        setTotalPages(1);
      }
      setLoading(false);
    };

    fetchData();
  }, [success, search, page, size, type]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {clientsList && clientsList.length === 0 ? (
        <p>לא נמצאו לקוחות</p>
      ) : (
        <>
          {clientsList.map((client) => (
            <ClientCard
              key={client._id}
              id={client._id}
              userId={client.userId}
              businessId={client.businessId}
              phone={client.user.phone}
              name={client.user.name}
              img={client.user.profile}
              setIsOpenModal={setIsOpenModal}
            />
          ))}
          <div className={styles.pagination}>
            {/* Simple Pagination Controls */}
            <button
              className={styles.btn}
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              {"<"} אחורה
            </button>
            <span>{`דף ${page} מתוך ${totalPages}`}</span>
            <button
              className={styles.btn}
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            >
              הבא {">"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientsList;

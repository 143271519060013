import DayName from "./dayName";
import Hours from "./hours";
import Services from "./services";
import Actions from "./actions";
import styles from "../../../styles/_containers/_workingHours/day/day.module.scss";
import { useState } from "react";

const Day = ({ id, dayName, status, timing, setData, allServices }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.container}>
      {/* DAY NAME */}
      <DayName
        dayId={id}
        dayName={dayName}
        status={status}
        setData={setData}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />

      <div className={`${styles.column} ${isOpen ? styles.open : styles.hide}`}>
        {timing &&
          timing.length > 0 &&
          timing.map((i, idx) => (
            <div key={i._id} className={styles.row}>
              <Hours
                dayId={id}
                timingIndex={idx}
                setData={setData}
                startTime={i.start_time}
                endTime={i.end_time}
              />
              <Services
                dayId={id}
                timingIndex={idx}
                services={i.services}
                allServices={allServices}
                setData={setData}
              />

              {idx + 1 === timing.length && (
                <Actions dayId={id} timingIndex={idx} setData={setData} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Day;

export const hebrewMonthNameGenerator = (month) => {
  const monthNames = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר",
  ];

  if (month >= 1 && month <= 12) {
    return monthNames[month - 1];
  } else {
    return "Invalid Month";
  }
};

export const EmployeesIconSvg = ({ fill }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_484_2319)">
        <mask
          id="mask0_484_2319"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="19"
          height="18"
        >
          <path
            d="M-0.000976562 1.90735e-06H17.999V18H-0.000976562V1.90735e-06Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_484_2319)">
          <path
            d="M17.2954 10.6875V10.9688C17.2954 12.9104 15.7214 14.4844 13.7798 14.4844H4.21729C2.27564 14.4844 0.70166 12.9104 0.70166 10.9688V4.21875C0.70166 2.27711 2.27564 0.703126 4.21729 0.703126H10.9673"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.2963 15.5772C16.6832 16.607 15.5589 17.2969 14.2734 17.2969H3.726C2.44055 17.2969 1.31621 16.607 0.703125 15.5772"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.99854 11.6719V3.51563"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.18603 4.88672H3.51416"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.18603 7.69922H3.51416"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.4829 10.5117H11.811"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.18603 10.5117H3.51416"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.2759 2.10938C16.2759 2.88601 15.6463 3.51562 14.8696 3.51562C14.093 3.51562 13.4634 2.88601 13.4634 2.10938C13.4634 1.33274 14.093 0.703125 14.8696 0.703125C15.6463 0.703125 16.2759 1.33274 16.2759 2.10938Z"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.4438 7.875C12.4438 6.6774 13.4379 5.69531 14.664 5.69531H15.0752C16.3014 5.69531 17.2954 6.6774 17.2954 7.875"
            stroke={fill}
            strokeWidth="1.08"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_484_2319">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import Card from "./card";
import styles from "../../styles/_containers/_settings/sections.module.scss";

const SectionThree = ({ data, handleChange }) => {

  const handleGoogleSync = () => {
    alert("נכון לרגע זה סנכרון גוגל ניתן לבצע דרך האפליקציה שלנו בלבד")
  }
  return (
    <div className={styles.container}>
      {/* CARD 1 */}
      <Card>
        <div className={styles.cardContainer}>
          {/* BLOCK 1 */}
          <div className={`${styles.block} ${styles.lastBlock}`}>
            <div className={styles.title}>
              <h4>הודעות ללקוחות נעלמים</h4>
              <div className={styles.tag}>
                <p>חדש ומומלץ</p>
              </div>
            </div>

            <p className={styles.textOne}>
              המערכת תנהל עבורכם את התורים כך שתוכלו לקבל מספר מקסימלי של תורים
              ביום לפי סוג התור הנבחר
            </p>

            <div className={styles.field}>
              <label>סטטוס</label>
              <select name="" id="">
                <option value="" selected disabled>
                  פעיל
                </option>
                <option value="">one</option>
                <option value="">two</option>
              </select>
            </div>
          </div>
        </div>
      </Card>

      {/* CARD 2 */}
      <Card>
        <div className={styles.cardContainer}>
          {/* BLOCK 1 */}
          {/* DOUBLE BOOKING */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>חסימת “תור כפול”</h4>
            </div>

            <p className={styles.textOne}>
              בחרו האם לאפשר ללקוחות לקבוע יותר מתור אחד, במידה ולא פעיל: לקוח יוכל
              לקבוע תור אחד בלבד. עד אשר זמן התור יעבור או שהתור יבוטל
            </p>

            <div className={styles.field}>
              <label>סטטוס</label>
              <select
                name="canBookMoreInServices"
                value={data.canBookMoreInServices}
                onChange={handleChange}
              >
                <option value={true}>לא פעיל - ניתן לקבוע יותר מתור אחד</option>
                <option value={false}>פעיל - מוגבל לתור אחד ללקוח</option>
              </select>
            </div>
          </div>

          {/* BLOCK 2 */}
          {/* CANCELLATION MESSAGE */}
          <div className={styles.block}>
            <div className={styles.title}>
              <h4>הודעות ביטול ללקוחות</h4>
            </div>

            <p className={styles.textOne}>
              כאן תוכלו לרשום את מדיניות הביטולים שלכם, הלקוחות יראו את ההודעה
              כאשר זמן הביטול עבר
            </p>

            <div className={styles.field}>
              <textarea
                name="cancellationText"
                value={data.cancellationText}
                onChange={handleChange}
                placeholder="לקוחות יקרים, במידה וברצונכם לבטל את התור אנא בטלו אותו מראש ולא במועד סמוך לתור"
              ></textarea>
              <div className={styles.row}>
                <p>ניתן לרשום עד 80 תווים</p>
                <p>תווים שנותרו: 13</p>
              </div>
            </div>
          </div>

          {/* SYNC CALENDAR */}
          {/* <div className={styles.sync}>
            <div className={styles.title}>
       
              <h3>סנכרון התורים ליומן בטלפון</h3>
              <div className={styles.tag}>
                <p>מומלץ מאוד</p>
              </div>
            </div>

            <button onClick={handleGoogleSync}>
              <img src="/assets/google-icon-1.svg" alt="google" />
              <p>התחברות לחשבון הגוגל שלך</p>
            </button>
          </div> */}
        </div>
      </Card>
    </div>
  );
};

export default SectionThree;

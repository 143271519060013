import { Calendar } from "react-calendar";
import { format } from "date-fns";
import AppointmentCard from "../../components/appointmentCard";
import Loader from "../../components/loader";
import styles from "../../styles/_containers/_appointments/sectionTwo.module.scss";

const SectionTwo = ({
  dateValue,
  setDateValue,
  appointmentsByDate,
  appointmentsByDateLoading,
  setIsCommentModalOpen,
  setIsConfirmModalOpen,
}) => {

  return (
    <div className={styles.container}>
      {/* DATE FILTER */}
      <div className={styles.filter}>
        <h3>תורים לפי תאריך</h3>

        <div className={styles.calCard}>
          <Calendar
            value={dateValue}
            onChange={setDateValue}
            calendarType="Hebrew"
            formatDay={(locale, date) => format(date, "d")}
            next2Label={null}
            prev2Label={null}
            nextLabel="<"
            prevLabel=">"
            formatShortWeekday={(locale, date) => {
              if (format(date, "E").toLowerCase() === "sun") {
                return "א";
              } else if (format(date, "E").toLowerCase() === "mon") {
                return "ב";
              } else if (format(date, "E").toLowerCase() === "tue") {
                return "ג";
              } else if (format(date, "E").toLowerCase() === "wed") {
                return "ד";
              } else if (format(date, "E").toLowerCase() === "thu") {
                return "ה";
              } else if (format(date, "E").toLowerCase() === "fri") {
                return "ו";
              } else if (format(date, "E").toLowerCase() === "sat") {
                return "ש";
              }
            }}
            formatMonthYear={(locale, date) => {
              if (
                format(date, "MMMM yyyy") === `January ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} ינואר`;
              } else if (
                format(date, "MMMM yyyy") === `February ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} פברואר`;
              } else if (
                format(date, "MMMM yyyy") === `March ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} מרץ`;
              } else if (
                format(date, "MMMM yyyy") === `April ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} אפריל`;
              } else if (
                format(date, "MMMM yyyy") === `May ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} מאי`;
              } else if (
                format(date, "MMMM yyyy") === `June ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} יוני`;
              } else if (
                format(date, "MMMM yyyy") === `July ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} יולי`;
              } else if (
                format(date, "MMMM yyyy") === `August ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} אוגוסט`;
              } else if (
                format(date, "MMMM yyyy") ===
                `September ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} ספטמבר`;
              } else if (
                format(date, "MMMM yyyy") === `October ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} אוקטובר`;
              } else if (
                format(date, "MMMM yyyy") === `November ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} נובמבר`;
              } else if (
                format(date, "MMMM yyyy") === `December ${format(date, "yyyy")}`
              ) {
                return `${format(date, "yyyy")} דצמבר`;
              }
            }}
            minDate={new Date()}
          />
        </div>
      </div>

      {/* ALL APPOINTMENTS */}
      <div className={styles.appointments}>
        <h3>התורים שלי ליום ה{format(new Date(dateValue), "dd/MM/yyyy")}</h3>

        {/* loading */}
        {appointmentsByDateLoading && <Loader />}
        
        {/* records */}
        <div className={styles.list}>
          {!appointmentsByDateLoading &&
            appointmentsByDate &&
            appointmentsByDate.map((i) => (
              <AppointmentCard
                key={i._id}
                id={i._id}
                serviceName={i.serviceId?.name}
                customerName={i.userId?.name}
                date={i.date}
                startTime={i.timeSlot}
                type={i.serviceId?.type}
                employeeId={i.employeeId}
                time={i.serviceId?.time}
                isConfirmed={i.isConfirmed}
                phoneNumber={i.userId?.phone}
                userId={i.userId?._id}
                adminComment={i.adminComment}
                setIsCommentModalOpen={setIsCommentModalOpen}
                setIsConfirmModalOpen={setIsConfirmModalOpen}
              />
            ))}
        </div>

        {/* if no record */}
        {!appointmentsByDateLoading && appointmentsByDate === false && (
          <div className={styles.noRecord}>
            <p>לא נמצאו תורים בתאריך זה</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionTwo;

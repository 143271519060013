import { useState, useEffect, useRef } from "react";
import { generateProfileImgUrl } from "../../utils/generateProfileImgUrl";
import EMPLOYEES from "../../api/services/EMPLOYEES";
import styles from "../../styles/_containers/_createAppointment/clients.module.scss";
import Loader from "../../components/loader";

const Employees = ({
  currentEmployee,
  setCurrentEmployee,
  setBusinessId,
  setStep,
}) => {
  const ref = useRef();

  const [employees, setEmployees] = useState(null);
  const [loading, setLoading] = useState(false);

  let user_id = localStorage.getItem("user_id");
  user_id = JSON.parse(user_id);

  // FETCH EMPLOYEES
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const res = await EMPLOYEES.get(user_id);

        setEmployees(res.data.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false)
    };

    fetchData();
  }, []);

  // IF LOADING
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <div className={styles.container}>
      <h1>בחירת עובד/ת</h1>

      <p className={styles.mainText}>ניתן לבחור עובד אחד בלבד</p>

      <div className={styles.employees}>
        {employees?.map((i) => (
          <EmployeeCard
            key={i.id}
            id={i.id}
            currentEmployee={currentEmployee}
            img={generateProfileImgUrl(i.profile, i.name)}
            name={i.name}
            handleClick={() => {
              setCurrentEmployee(i);
              setBusinessId(i.businessId);
              setTimeout(() => {
                ref.current?.scrollIntoView({ behavior: "smooth" });
              }, 500);
            }}
          />
        ))}
      </div>

      {currentEmployee && (
        <button ref={ref} onClick={() => setStep(2)}>
          הבא
        </button>
      )}
    </div>
  );
};

export default Employees;

// EMPLOYEES CARD
export const EmployeeCard = ({
  id,
  currentEmployee,
  img,
  name,
  handleClick,
}) => {
  const [isHovered, setIsHovered] = useState(null);

  return (
    <div
      className={styles.card}
      onClick={handleClick}
      style={{
        border:
          currentEmployee?.id === id
            ? `1px solid #DE1468`
            : isHovered === id
            ? `1px solid #DE1468`
            : "",
      }}
      onMouseEnter={() => setIsHovered(id)}
      onMouseLeave={() => setIsHovered(null)}
    >
      {img}
      <p
        style={{
          color:
            currentEmployee?.id === id
              ? "#DE1468"
              : isHovered === id
              ? "#DE1468"
              : "",
        }}
      >
        {name}
      </p>
    </div>
  );
};

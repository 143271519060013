import { useEffect, useState } from "react";
import Header from "./header";
import MobileNav from "./mobileNav";
import StoreBar from "./storeBar";
import styles from "../styles/_layouts/layout.module.scss";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBarOpen, setIsBarOpen] = useState(false);

  let topBarStatus = localStorage.getItem("topBarStatus");
  topBarStatus = JSON.parse(topBarStatus);

  useEffect(() => {
    if (!topBarStatus) {
      localStorage.setItem("topBarStatus", JSON.stringify("open"));
      setIsBarOpen(true);
    } else if (topBarStatus) {
      if (topBarStatus !== "open") {
        let dateFromStorage = new Date(topBarStatus);
        let currentDate = new Date();
        if (currentDate > dateFromStorage) {
          localStorage.setItem("topBarStatus", JSON.stringify("open"));
          setIsBarOpen(true);
        }
      }
    }
  }, []);

  const handleClose = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    const dateString = new Date(currentDate);

    localStorage.setItem("topBarStatus", JSON.stringify(dateString));
    setIsBarOpen(false);
  };

  return (
    <div className={styles.container}>
      <Header setIsOpen={setIsOpen} />

      {isBarOpen && <StoreBar handleClose={handleClose} />}

      <main className={styles.main}>{children}</main>

      {isOpen && <MobileNav setIsOpen={setIsOpen} />}
    </div>
  );
};

export default Layout;

import styles from "../styles/_components/generateFirstLetterBox.module.scss";

const GenerateFirstLetterBox = ({ name }) => {
  // Function to get the first uppercase letter or a fallback character
  const getFirstLetter = (name) => {
    if (name && /^[A-Za-z]/.test(name)) {
      return name.charAt(0).toUpperCase();
    }
    return '?'; // Fallback character
  };

  const firstLetter = getFirstLetter(name);

  return <div className={styles.container}>{firstLetter}</div>;
};

export default GenerateFirstLetterBox;

export const GraduationHatIconSvg = ({ fill }) => {
  return (
    <svg
      width="74"
      height="73"
      viewBox="0 0 74 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.1697 34.4703L51.5776 25.1856C52.5813 23.0869 53.1744 20.76 53.1744 18.2734C53.1744 9.46781 46.0113 2.30469 37.2057 2.30469C28.4001 2.30469 21.2369 9.46781 21.2369 18.2734C21.2369 20.76 21.8301 23.0869 22.8338 25.1856L4.24163 34.4703C3.466 34.8581 2.98694 35.6566 2.98694 36.5006C2.98694 37.3447 3.466 38.1431 4.24163 38.5309L9.83069 41.3141V57.0091C9.83069 57.8988 10.3554 58.72 11.1766 59.085L36.2704 70.4913C36.5669 70.6281 36.8863 70.6966 37.2057 70.6966C37.5251 70.6966 37.8444 70.6281 38.141 70.4913L63.2347 59.085C64.056 58.72 64.5807 57.8988 64.5807 57.0091V41.3141L66.8619 40.1734V54.7278C66.8619 55.9825 67.8885 57.0091 69.1432 57.0091C70.3979 57.0091 71.4244 55.9825 71.4244 54.7278V36.4778C71.4244 35.6109 70.9454 34.8353 70.1697 34.4475V34.4703ZM37.2057 6.84438C43.5019 6.84438 48.6119 11.9544 48.6119 18.2506C48.6119 24.5469 43.5019 29.6569 37.2057 29.6569C30.9094 29.6569 25.7994 24.5469 25.7994 18.2506C25.7994 11.9544 30.9094 6.84438 37.2057 6.84438ZM60.0182 55.5719L37.2057 65.9288L14.3932 55.5719V43.6181L36.1791 54.5225C36.4985 54.6822 36.8407 54.7734 37.2057 54.7734C37.5707 54.7734 37.9129 54.6822 38.2323 54.5225L60.0182 43.6181V55.5719ZM37.2057 49.9372L10.3782 36.5234L25.4116 28.9953C28.3316 32.2119 32.5291 34.2422 37.2057 34.2422C41.8822 34.2422 46.0797 32.2119 48.9997 28.9953L64.0332 36.5234L37.2057 49.9372Z"
        fill={fill}
      />
      <path
        d="M34.012 23.2683C34.4455 23.6561 34.993 23.8614 35.5405 23.8614C36.088 23.8614 36.6355 23.6561 37.0689 23.2683L44.4373 16.607C45.3727 15.763 45.4411 14.3258 44.597 13.3905C43.753 12.4552 42.3158 12.3867 41.3805 13.2308L35.5405 18.5005L33.0311 16.242C32.0958 15.398 30.6586 15.4892 29.8145 16.4017C28.9705 17.337 29.0389 18.7742 29.9742 19.6183L34.012 23.2455V23.2683Z"
        fill={fill}
      />
    </svg>
  );
};

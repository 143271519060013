import Modal from "./modal";
import { hoursAndMinutes } from "../utils";
import styles from "../styles/_components/customSlotsModal.module.scss";

export const CustomSlotsModal = ({ setIsOpen, customSlotValue, handleChange }) => {
  return (
    <Modal setIsOpen={setIsOpen} onClick={() => null}>
      <div className={styles.container}>
        <select name="slot" value={customSlotValue} onChange={handleChange}>
          {hoursAndMinutes.map((i) => (
            <option value={i}>{i}</option>
          ))}
        </select>
      </div>
    </Modal>
  );
};

export const WalletIcon1Svg = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_5424)">
        <mask
          id="mask0_61_5424"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white" />
        </mask>
        <g mask="url(#mask0_61_5424)">
          <path
            d="M3.95066 0.781252C2.1598 0.781252 0.701133 2.26563 0.784687 4.05449C0.862109 5.71156 2.23004 7.03125 3.90625 7.03125H16.0908C17.8183 7.03125 19.2187 8.43168 19.2187 10.1592V16.0908C19.2187 17.8183 17.8183 19.2188 16.0908 19.2188H4.69047C2.53145 19.2188 0.78125 17.4685 0.78125 15.3095V4.10156"
            stroke={fill}
            strokeWidth="1.04402"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.90625 0.778281H16.0908C17.8183 0.778281 19.2187 2.17875 19.2187 3.90625V4.72656"
            stroke={fill}
            strokeWidth="1.04402"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.8755 13.125C16.8755 13.6643 16.4383 14.1016 15.8989 14.1016C15.3596 14.1016 14.9224 13.6643 14.9224 13.125C14.9224 12.5857 15.3596 12.1484 15.8989 12.1484C16.4383 12.1484 16.8755 12.5857 16.8755 13.125Z"
            fill={fill}
          />
          <path
            d="M3.90625 3.90625H16.0937"
            stroke={fill}
            strokeWidth="1.04402"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_61_5424">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
